import React, {
    ChangeEvent,
    FunctionComponent,
    KeyboardEvent,
    useCallback,
    useMemo,
    useState,
} from 'react';
import Styles from './Styles.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TooltipContent } from '@/components/form-controls/input-v2/tooltip-content';
import { TranslationService } from '@/services/translation';
import './tooltip.css';

export type InputBoxUIOptions = {
    containerHeight?: number | string;
    containerWidth?: number | string;
};

type Props = {
    label: string;
    placeholder?: string;
    type: string;
    value: string | undefined;
    onChange: (value: string) => void;
    onEnter?: () => void;
    onRightIconClick?: () => void;
    isValid: boolean;
    showLeftIcon?: boolean;
    showRightIcon?: boolean;
    leftIcon?: string;
    rightIcon?: string;
    uiOptions?: InputBoxUIOptions;
    showTooltip?: boolean;
    tooltipLabel?: string;
    disabled?: boolean;
};

export const InputBox: FunctionComponent<Props> = ({
    label,
    placeholder,
    type,
    value,
    onChange,
    onEnter = () => null,
    onRightIconClick = () => null,
    isValid,
    showLeftIcon = false,
    showRightIcon = false,
    leftIcon,
    rightIcon,
    uiOptions = {},
    showTooltip = false,
    tooltipLabel = '',
    disabled = false,
}) => {
    const [focused, setFocused] = useState(false);
    const [isTooltipDisplayed, displayTooltip] = useState(false);

    const inputClassName: string = useMemo(
        () => `w-100  ${!isValid && Styles.InputInvalid} h-100 ${showLeftIcon && 'ps-2'}`,
        [isValid, showLeftIcon]
    );

    const changeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { value: newValue },
            } = event;

            onChange(newValue);
        },
        [onChange]
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key?.toLowerCase() === 'enter') {
                onEnter();
                setFocused(false);
            }
        },
        [onEnter]
    );

    const onFocus = useCallback(() => {
        setFocused(true);
        displayTooltip(true);
    }, []);

    const onBlur = useCallback(() => {
        setFocused(false);
        displayTooltip(false);
    }, []);

    const onTooltipClose = useCallback(() => {
        displayTooltip(false);
    }, [displayTooltip]);

    const renderTooltip = (props: any) => {
        return (
            <Tooltip {...props} id="tooltip-container">
                <TooltipContent label={tooltipLabel} onClose={onTooltipClose} />
            </Tooltip>
        );
    };

    return (
        <div
            className={`${Styles.Input} d-flex flex-column align-items-start`}
            style={{
                height: uiOptions.containerHeight || 68,
                width: uiOptions.containerWidth || '100%',
            }}
        >
            {label && (
                <label htmlFor="first_name" className={`${!isValid && Styles.InvalidLabel} mb-1`}>
                    {label}
                </label>
            )}

            <div
                className={`${Styles.InputContainer} ${
                    focused && Styles.Focused
                } ${disabled && Styles.InputDisabled} w-100 px-3 py-2 d-flex align-items-center`}
            >
                {showLeftIcon && <img alt="search-events-box-icon" src={leftIcon} />}

                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                    show={showTooltip && !showRightIcon && isTooltipDisplayed}
                >
                    <input
                        type={type}
                        className={inputClassName}
                        placeholder={placeholder}
                        onChange={changeHandler}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={value}
                        disabled={disabled}
                    />
                </OverlayTrigger>

                {showRightIcon && value && (
                    <img alt="search-events-box-icon" src={rightIcon} onClick={onRightIconClick} />
                )}
            </div>
        </div>
    );
};
