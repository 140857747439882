import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Coordinates } from '@/services/geolocation/types';
import { IMAGES } from '@/assets/images';
import { Marker } from '@react-google-maps/api';

type Props = {
    location: Coordinates;
};

export const MapMarker: FunctionComponent<Props> = ({ location }) => {
    return (
        <Marker
            position={{
                lat: location.latitude,
                lng: location.longitude,
            }}
            icon={IMAGES.MyLocationIcon}
            animation={google.maps.Animation.DROP}
        />
    );
};
