import { EventLanguage, EventModel, EventSubtype, EventType } from '@/events/models';
import { EventApi } from '@/events/api/types';
import { CLIENTS } from '@/constants/clients';
import { IMAGES } from '@/assets/images';
import { VenueType } from '@/venues/models';
import { EVENT_LANGUAGES } from '@/constants/languages';

const getEventImageName = (clients: string[], index: number): string => {
    const mainClient: string = clients.length > 0 ? clients[0] : 'default';

    const imageNamePrefix: string = !CLIENTS[mainClient]
        ? CLIENTS.default.imageNamePrefix
        : CLIENTS[mainClient].imageNamePrefix;

    const numberOfImages: number = !CLIENTS[mainClient]
        ? CLIENTS.default.numberOfImages
        : CLIENTS[mainClient].numberOfImages;

    return `${imageNamePrefix}_${index % numberOfImages}`;
};

const getEventType = (eventType: string): EventType => {
    switch (eventType) {
        case 'Kickoff':
            return EventType.KICKOFF;
        case 'Onboarding':
            return EventType.ONBOARDING;
        case 'Chapter':
            return EventType.CHAPTER;
        default:
            return EventType.SPECIAL;
    }
};

const getEventSubtype = (subtype: string): EventSubtype => {
    switch (subtype) {
        case 'Ambassador':
            return EventSubtype.AMBASSADOR;
        case 'Town Hall':
            return EventSubtype.TOWN_HALL;
        default:
            return EventSubtype.SPECIAL;
    }
};

const formatPhoneNumber = (phone?: string): string => {
    if (!phone) {
        return '';
    }

    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
};

const getLanguage = (languageFromEventApi?: string) => {
    if (languageFromEventApi?.toLowerCase() === EVENT_LANGUAGES[0].value.toLowerCase()) {
        return EventLanguage.EN;
    }

    if (languageFromEventApi?.toLowerCase() === EVENT_LANGUAGES[1].value.toLowerCase()) {
        return EventLanguage.ES;
    }

    return undefined;
};

export const eventApiToEventModel = (eventApi: EventApi, index: number): EventModel => {
    const clients: string[] = eventApi.client
        ? eventApi.client.map((clientItem) => clientItem.toLowerCase())
        : [];

    const clientsToGetEventImage =
        eventApi.client && eventApi.client.length > 0 ? eventApi.client : [];

    return {
        id: eventApi.event_id,
        name: eventApi.friendly_name,
        description: eventApi.description,
        type: getEventType(eventApi.event_type),
        startDateInMilliseconds: eventApi.event_start,
        endDateInMilliseconds: eventApi.event_end,
        language: getLanguage(eventApi.language),
        clients,
        campaigns: eventApi.topic ? eventApi.topic.map((topic) => topic.toLowerCase()) : [],
        imageUrl: IMAGES[getEventImageName(clientsToGetEventImage, index)],
        venue: {
            id: eventApi.venue_id || '',
            name: eventApi.venue_name || '',
            addressLine1: eventApi.address1 || '',
            addressLine2: eventApi.address2 || '',
            city: eventApi.venue_city || '',
            state: eventApi.venue_state || '',
            zipcode: eventApi.venue_zip || '',
            phoneNumber: eventApi.venue_phone || '',
            type: eventApi.venue_type.toLowerCase().includes('virtual')
                ? VenueType.VIRTUAL
                : VenueType.IN_PERSON,
            timezone: eventApi.venue_time_zone,
            latitude: eventApi.venue_latitude,
            longitude: eventApi.venue_longitude,
        },
        facilitator: {
            email: eventApi.facilitator_email || '',
            firstName: eventApi.facilitator_first_name || '',
            lastName: eventApi.facilitator_last_name || '',
            photo: eventApi.facilitator_photo || '',
            phone: formatPhoneNumber(eventApi.facilitator_wider_circle_phone_number),
        },
        member: {
            email: eventApi.member_lead_email || '',
            firstName: eventApi.member_lead_first_name || '',
            lastName: eventApi.member_lead_last_name || '',
            mobile: '',
            phone: '',
            photo: eventApi.member_lead_photo || '',
        },
        isAuthenticatedUserRegistered: eventApi.is_contact_invited,
        timezoneToDisplay: eventApi.event_timezone_display,
        numberOfAttendees: eventApi.number_of_attendees,
        upcomingWeeklyEvents: eventApi.upcoming_weekly_events.map((e, i) =>
            eventApiToEventModel(e, i)
        ),
        distance: eventApi.distance,
        subtype: getEventSubtype(eventApi.subtype),
    };
};
