import * as GeneralContentEnglish from './general-en.json';
import * as GeneralContentSpanish from './general-es_US.json';
import * as PaContentEnglish from './pa-en.json';
import * as PaContentSpanish from './pa-es_US.json';
import { ISO_LANGUAGE } from '@/constants/languages';

const PA_LANGUAGE_FILE_PREFIX: string = 'pa';
const GENERAL_LANGUAGE_FILE_PREFIX: string = 'general';
const SELF_SERVE_CONTENT_PREFIX: string = 'self_serve';

type TranslationContentType = { [key: string]: any };

export class TranslationService {
    private static _instance: TranslationService;
    private _language: ISO_LANGUAGE;
    private _client: string;

    public constructor() {
        this._language = ISO_LANGUAGE.English;
        this._client = GENERAL_LANGUAGE_FILE_PREFIX;
    }

    public setLanguage(language: ISO_LANGUAGE) {
        this._language = language;
    }

    public setClient(clients: string[]) {
        this._client =
            clients.includes(PA_LANGUAGE_FILE_PREFIX) ||
            window.location.host.includes('group-meetings')
                ? PA_LANGUAGE_FILE_PREFIX
                : GENERAL_LANGUAGE_FILE_PREFIX;
    }

    public getPhrase(key: string): string {
        let translationContent = this.getTranslationContent();
        let phrase = 'Not-loaded';

        key.split('.').forEach((k) => {
            if (typeof translationContent[k] === 'object') {
                translationContent = translationContent[k];
            } else if (typeof translationContent[k] === 'string') {
                phrase = translationContent[k];
            }
        });

        return phrase;
    }

    private getTranslationContent(): TranslationContentType {
        if (this._client === PA_LANGUAGE_FILE_PREFIX && this._language === ISO_LANGUAGE.English) {
            // @ts-ignore
            return PaContentEnglish[SELF_SERVE_CONTENT_PREFIX][PA_LANGUAGE_FILE_PREFIX];
        } else if (
            this._client === PA_LANGUAGE_FILE_PREFIX &&
            this._language === ISO_LANGUAGE.Spanish
        ) {
            // @ts-ignore
            return PaContentSpanish[SELF_SERVE_CONTENT_PREFIX][PA_LANGUAGE_FILE_PREFIX];
        } else if (
            this._client === GENERAL_LANGUAGE_FILE_PREFIX &&
            this._language === ISO_LANGUAGE.Spanish
        ) {
            // @ts-ignore
            return GeneralContentSpanish[SELF_SERVE_CONTENT_PREFIX][GENERAL_LANGUAGE_FILE_PREFIX];
        }

        // @ts-ignore
        return GeneralContentEnglish[SELF_SERVE_CONTENT_PREFIX][GENERAL_LANGUAGE_FILE_PREFIX];
    }

    public static getInstance(): TranslationService {
        if (this._instance === undefined) {
            this._instance = new TranslationService();
        }

        return this._instance;
    }
}
