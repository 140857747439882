import React, { FunctionComponent, useCallback } from 'react';
import { Carousel as CarouselLibraryComponent } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';

const CAROUSEL_GLOBAL_CLASS = 'WC_Carouse';
const INTERVAL = 3100;

const CAROUSEL_IMAGES = [
    {
        src: IMAGES.StepOneImageOne,
        alt: 'step-one-image-one',
    },
    {
        src: IMAGES.StepOneImageTwo,
        alt: 'step-one-image-two',
    },
    {
        src: IMAGES.StepOneImageThree,
        alt: 'step-one-image-three',
    },
    {
        src: IMAGES.StepOneImageFour,
        alt: 'step-one-image-four',
    },
    {
        src: IMAGES.StepOneImageFive,
        alt: 'step-one-image-five',
    },
];

export const Carousel: FunctionComponent = () => {
    const renderArrowPrev = useCallback(
        (clickHandler: () => void) => (
            <div
                className={`${Styles.CarouselLeftControlContainer} d-flex align-items-center justify-content-center`}
            >
                <div
                    onClick={clickHandler}
                    className={`${Styles.CarouselControl} d-flex align-items-center justify-content-center`}
                >
                    <img alt="arrow-left-icon" src={IMAGES.ArrowLeftIcon} />
                </div>
            </div>
        ),
        []
    );

    const renderArrowNext = useCallback(
        (clickHandler: () => void) => (
            <div
                className={`${Styles.CarouselRightControlContainer} d-flex align-items-center justify-content-center`}
            >
                <div
                    onClick={clickHandler}
                    className={`${Styles.CarouselControl} d-flex align-items-center justify-content-center`}
                >
                    <img alt="arrow-left-icon" src={IMAGES.ArrowRightIcon} />
                </div>
            </div>
        ),
        []
    );

    return (
        <div className={Styles.Carousel}>
            <CarouselLibraryComponent
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={INTERVAL}
                className={CAROUSEL_GLOBAL_CLASS}
                renderArrowPrev={renderArrowPrev}
                renderArrowNext={renderArrowNext}
            >
                {CAROUSEL_IMAGES.map((item) => (
                    <div className="carousel_item" key={item.src}>
                        <img
                            key={item.alt}
                            src={item.src}
                            alt={item.alt}
                            className="w-100 d-none d-md-block"
                        />
                    </div>
                ))}
            </CarouselLibraryComponent>
        </div>
    );
};
