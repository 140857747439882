import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Styles.module.scss';
import { TranslationService } from '@/services/translation';

export const StepFive: FunctionComponent = () => {
    return (
        <>
            <Row className={'pb-4 mx-0'}>
                <Col>
                    <span className={Styles.RegistrationModalTitle}>
                        {TranslationService.getInstance().getPhrase('sign_in.step_five.title')}
                    </span>
                </Col>
            </Row>

            <Row className={'pb-4 pt-2 mx-0'}>
                <Col>
                    <span className={Styles.RegistrationModalParagraph}>
                        {TranslationService.getInstance().getPhrase(
                            'sign_in.step_five.paragraph_1'
                        )}
                    </span>
                </Col>
            </Row>

            <Row className={'pb-4 pt-2 mx-0'}>
                <Col>
                    <span className={Styles.RegistrationModalParagraph}>
                        {TranslationService.getInstance().getPhrase(
                            'sign_in.step_five.paragraph_2'
                        )}
                    </span>
                </Col>
            </Row>

            <div className={'py-4'} />
        </>
    );
};
