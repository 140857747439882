import { MemberSearchResultApi } from '@/contacts/api/types';
import { MemberSearchResultModel } from '@/contacts/models';

export const memberSearchResultApiToMemberSearchResultModel = (
    memberApi: MemberSearchResultApi
): MemberSearchResultModel => ({
    firstName: memberApi.first_name,
    lastName: memberApi.last_name,
    id: memberApi.id,
    clientName: memberApi.client_name,
    clientParameter: memberApi.client_parameter,
    zipCode: memberApi.zip,
});
