import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useRouterRoot } from '@/use-router-root';
import { Provider } from 'react-redux';
import { store } from '@/store';
import { TranslationService } from '@/services/translation';

const PAGE_TITLE: string = 'Welcome | Wider Circle';

function App() {
    const router = useRouterRoot();

    useEffect(() => {
        TranslationService.getInstance();
    }, []);

    return (
        <Provider store={store}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{PAGE_TITLE}</title>
                <link rel="icon" type="image/x-icon" href="assets/images/favicon.ico" />
            </Helmet>

            <RouterProvider router={router} />
        </Provider>
    );
}

export default App;
