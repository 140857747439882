import React, { FunctionComponent } from 'react';
import { EventModel, EventType } from '@/events/models';
import { Badge } from '@/components/badge-v2';
import { getEventTypeLabel, getVenueTypeLabel } from '@/events/pages/all-events/utils';
import Styles from './Styles.module.scss';
import { useEventClientLogo } from '@/events/components/use-event-client-logo';

type Props = {
    event: EventModel;
};

export const EventDetailsImage: FunctionComponent<Props> = ({ event }) => {
    const { hasClientLogo, getEventLogoComponent } = useEventClientLogo({ event });

    return (
        <div
            className={`${Styles.EventDetailsImage} px-0`}
            style={{ backgroundImage: `url(${event?.imageUrl})` }}
        >
            <div
                className={`d-xl-flex d-lg-flex d-md-flex d-sm-none d-none align-item-center ${Styles.EventTypesCon}`}
            >
                {hasClientLogo && getEventLogoComponent()}

                <Badge
                    label={getVenueTypeLabel(event)}
                    backgroundColor={'#FFFFFF'}
                    color={'#262626'}
                />

                {event?.type === EventType.KICKOFF && (
                    <Badge
                        label={getEventTypeLabel(event)}
                        backgroundColor={'#FFFFFF'}
                        color={'#262626'}
                    />
                )}
            </div>

            <div
                className={`d-xl-none d-lg-none d-md-none d-sm-flex d-flex flex-column align-item-center ${Styles.EventTypesCon}`}
            >
                <div className={'d-flex'}>
                    {hasClientLogo && getEventLogoComponent()}

                    <span className="px-1" />

                    <Badge
                        label={getVenueTypeLabel(event)}
                        backgroundColor={'#FFFFFF'}
                        color={'#262626'}
                    />
                </div>

                {event?.type === EventType.KICKOFF && (
                    <div className={'d-flex'}>
                        <Badge
                            label={getEventTypeLabel(event)}
                            backgroundColor={'#FFFFFF'}
                            color={'#262626'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
