import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Styles.module.scss';
import { InputBox } from '@/components/form-controls/input';
import { RegistrationModalData } from '@/contacts/components/registration-modal/use-registration-modal-data';
import { PhoneInputBox } from '@/components/form-controls/phone-input';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import { TranslationService } from '@/services/translation';

type Props = {
    formData: RegistrationModalData;
};

export const StepOne: FunctionComponent<Props> = ({ formData }) => {
    const [hasUserMakeChanges, userHasMakeChanges] = useState(false);

    const onPhoneNumberChange = useCallback(
        (newPhoneNumber: string) => {
            formData.phoneNumber.setValue(newPhoneNumber);

            if (!hasUserMakeChanges) {
                userHasMakeChanges(!hasUserMakeChanges);
            }
        },
        [formData.phoneNumber, hasUserMakeChanges]
    );

    const onLastNameChange = useCallback(
        (newLastName: string) => {
            formData.lastName.setValue(newLastName);

            if (!hasUserMakeChanges) {
                userHasMakeChanges(!hasUserMakeChanges);
            }
        },
        [formData.lastName, hasUserMakeChanges]
    );

    useEffect(() => {
        if (hasUserMakeChanges) {
            Analytics.getInstance().fireEvent(
                AnalyticsEventName.SignInFormFocused,
                AnalyticsEventCategory.SignIn,
                'User has started inputting information into the Eligibility Check modal',
                {}
            );
        }
    }, [hasUserMakeChanges]);

    return (
        <>
            <Row className={'mx-0'}>
                <Col className={'pt-2 px-3 pb-4 mb-4'}>
                    <h1 className={`text-dark font-montserrat ${Styles.ModalTitleStepOne}`}>
                        {TranslationService.getInstance().getPhrase('sign_in.step_one.title')}
                    </h1>
                    <p className={`text-base text-center ${Styles.ModalSubTitleStepOne}`}>
                        {TranslationService.getInstance().getPhrase('sign_in.step_one.paragraph')}
                    </p>
                    <div className="mt-5 d-flex gap-3 flex-column">
                        <PhoneInputBox
                            label={TranslationService.getInstance().getPhrase(
                                'sign_in.step_one.form.phone.label'
                            )}
                            placeholder={TranslationService.getInstance().getPhrase(
                                'sign_in.step_one.form.phone.placeholder'
                            )}
                            value={formData.phoneNumber.value}
                            onChange={onPhoneNumberChange}
                            isValid={true}
                        />
                        <InputBox
                            label={TranslationService.getInstance().getPhrase(
                                'sign_in.step_one.form.last_name.label'
                            )}
                            type={'text'}
                            placeholder={TranslationService.getInstance().getPhrase(
                                'sign_in.step_one.form.last_name.placeholder'
                            )}
                            value={formData.lastName.value}
                            onChange={onLastNameChange}
                            isValid={true}
                        />
                    </div>
                    <p
                        className={`text-base text-start mt-4 d-none d-sm-block ${Styles.ModalSubTitleStepOne}`}
                    >
                        {TranslationService.getInstance().getPhrase(
                            'sign_in.step_one.form.suggestion'
                        )}
                        <a href="tel:(800) 656-0228" className="text-linkBlue d-inline fw-medium">
                            (800) 656-0228
                        </a>
                    </p>
                </Col>
            </Row>
        </>
    );
};
