export enum ISO_LANGUAGE {
    English = 'en',
    Spanish = 'es_US',
}

export enum API_LANGUAGE {
    English = 'en',
    Spanish = 'es',
}

export const EVENT_LANGUAGES = [
    {
        value: 'English',
        apiValue: API_LANGUAGE.English,
        iso: ISO_LANGUAGE.English,
        label: '',
    },
    {
        value: 'Spanish',
        apiValue: API_LANGUAGE.Spanish,
        iso: ISO_LANGUAGE.Spanish,
        label: '',
    },
];
