import { configureStore } from '@reduxjs/toolkit';
import { eventsReducer } from '@/events/reducer';
import { contactsReducer } from '@/contacts/reducer';
import { authenticationReducer } from '@/authentication/reducer';
import { venuesReducer } from '@/venues/reducer';
import { clientsReducer } from '@/clients/reducer';

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        clients: clientsReducer,
        contacts: contactsReducer,
        events: eventsReducer,
        venues: venuesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
