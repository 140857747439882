import { EventModel, EventType } from '@/events/models';
import { TranslationService } from '@/services/translation';
import { VenueType } from '@/venues/models';

export const getEventTypeLabel = (event: EventModel) => {
    if (event.type === EventType.KICKOFF) {
        return TranslationService.getInstance().getPhrase('events_list.badges.event_types.kickoff');
    } else if (event.type === EventType.ONBOARDING) {
        return TranslationService.getInstance().getPhrase(
            'events_list.badges.event_types.onboarding'
        );
    } else if (event.type === EventType.CHAPTER) {
        return TranslationService.getInstance().getPhrase('events_list.badges.event_types.chapter');
    } else {
        return TranslationService.getInstance().getPhrase('events_list.badges.event_types.special');
    }
};

export const getVenueTypeLabel = (event: EventModel) =>
    event.venue.type === VenueType.IN_PERSON
        ? TranslationService.getInstance().getPhrase('events_list.badges.venue_types.in_person')
        : TranslationService.getInstance().getPhrase('events_list.badges.venue_types.virtual');

export const getRegistrationButtonLabel = (event: EventModel) =>
    event.isAuthenticatedUserRegistered
        ? TranslationService.getInstance().getPhrase('events_list.buttons.unregister')
        : TranslationService.getInstance().getPhrase('events_list.buttons.register');
