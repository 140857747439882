import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    return {
        getQueryParameter: (key: string): string | undefined => query.get(key) || undefined,
    };
};
