import React, { FunctionComponent } from 'react';
import Styles from './Styles.module.scss';

type Props = {
    label: string;
    onClose: () => void;
};

export const TooltipContent: FunctionComponent<Props> = ({ label, onClose }, ref) => {
    return (
        <div className={`${Styles.TooltipContent} d-flex align-items-center px-1 py-2`}>
            <span className={'text-start'}>{label}</span>
        </div>
    );
};
