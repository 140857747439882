import React, { FunctionComponent, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Styles.module.scss';
import { MemberSearchResultModel } from '@/contacts/models';
import { Badge } from '@/components/badge';
import { TranslationService } from '@/services/translation';
import SiteLogo from '@/assets/icons/SiteLogo';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { ClientModel } from '@/clients/models';

type Props = {
    member: MemberSearchResultModel;
};

export const StepThree: FunctionComponent<Props> = ({ member }) => {
    const fetchedClients = useSelector<RootState, ClientModel[]>(
        (state) => state.clients.fetchedClients
    );

    const clientFromMember = useMemo(
        () =>
            fetchedClients.find(
                (client) =>
                    client.parameter.toLowerCase() === member?.clientParameter?.toLowerCase()
            ),
        [member?.clientParameter, fetchedClients]
    );

    return (
        <>
            <Row className={'mx-0'}>
                <Col xs={12} className="d-flex justify-content-center my-4">
                    <SiteLogo />
                </Col>
                <Col xs={12}>
                    <span
                        className={`text-dark font-montserrat text-center d-block ${Styles.ModalTitleStepOne}`}
                    >
                        Welcome {member?.firstName || ''}!
                    </span>
                </Col>
            </Row>

            <Row className={'mx-0 pb-4 pt-2'}>
                <Col>
                    <span
                        className={`text-base text-center d-block ${Styles.RegistrationModalParagraph}`}
                    >
                        {TranslationService.getInstance().getPhrase('sign_in.step_three.paragraph')}
                    </span>
                </Col>
            </Row>

            <Row className={'mx-0 pb-4 pt-2'}>
                <Col className="d-flex justify-content-center flex-wrap">
                    <Badge
                        label={clientFromMember?.label as string}
                        color={'#262626'}
                        bgColor="#fff"
                        border={'1px solid #E0E0E0'}
                    />
                </Col>
            </Row>
        </>
    );
};
