import { MemberSearchResultApi } from '@/contacts/api/types';
import { MemberSearchResultModel } from '@/contacts/models';
import { httpClient } from '@/services/http-client';
import { memberSearchResultApiToMemberSearchResultModel } from '@/contacts/mappers/member-search-result-mapper';

interface MemberSearchResponse {
    results: MemberSearchResultApi[];
}

export const searchMembers = async (
    lastName: string,
    phoneNumber: string
): Promise<MemberSearchResultModel[]> => {
    const response = await httpClient.requestFull<MemberSearchResponse>({
        method: 'GET',
        url: `/selfserve/search?last_name=${lastName}&phone=${phoneNumber}`,
    });

    return response.data.results.map(memberSearchResultApiToMemberSearchResultModel);
};
