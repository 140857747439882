const ArrowLeftIcon = () => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.29936 4.9878C9.76322 4.51752 10.5221 4.51752 10.986 4.9878C11.4409 5.44904 11.4409 6.19021 10.9859 6.65142L6.75548 10.9399L21.0592 10.94C21.7179 10.94 22.252 11.4741 22.252 12.1328C22.252 12.7916 21.7179 13.3256 21.0592 13.3256L6.75548 13.3254L10.986 17.6142C11.4409 18.0754 11.4409 18.8166 10.986 19.2778C10.5221 19.7481 9.76321 19.7481 9.29935 19.2778L2.95155 12.8421C2.56363 12.4488 2.56363 11.8168 2.95155 11.4235L9.29936 4.9878Z"
                fill="#004C97"
            />
        </svg>
    );
};

export default ArrowLeftIcon;
