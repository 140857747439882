import React, { FunctionComponent, ReactNode } from 'react';
import Styles from './Styles.module.scss';

type Props = {
    label?: string;
    backgroundColor?: string;
    color?: string;
    children?: ReactNode;
    customClassName?: string;
};

export const Badge: FunctionComponent<Props> = ({
    label,
    backgroundColor,
    color,
    children,
    customClassName,
}) => {
    return (
        <span
            className={`${Styles.Badge} py-0 px-3 ${customClassName} h-100`}
            style={{ backgroundColor, color }}
        >
            {label || children}
        </span>
    );
};
