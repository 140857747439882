import { httpClient } from '@/services/http-client';
import { AuthenticatedUserApi } from '@/authentication/api/types';
import { authenticatedUserApiToAuthenticatedUser } from '@/authentication/mappers/authentated-user-mapper';
import { AuthenticatedUserModel } from '@/authentication/models';

export const isTokenValid = async (): Promise<AuthenticatedUserModel> => {
    const response = await httpClient.requestFull<AuthenticatedUserApi>({
        method: 'POST',
        url: `/selfserve/auth`,
    });

    return authenticatedUserApiToAuthenticatedUser(response.data);
};

export const getNewToken = async (contactId: string, sessionId: string): Promise<string> => {
    const response = await httpClient.requestFull<string>({
        method: 'GET',
        url: `/selfserve/auth?contact_id=${contactId}&device_id=${sessionId}`,
    });

    return response.data;
};
