import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { OverlayView } from '@react-google-maps/api';
import { Coordinates } from '@/services/geolocation/types';
import { IMAGES } from '@/assets/images';
import { EventModel } from '@/events/models';
import Styles from './Styles.module.scss';

type Props = {
    events: EventModel[];
    location: Coordinates;
    onSelect: (events: EventModel[], coordinates: Coordinates) => void;
    isSelected: boolean;
    zoom: number;
};

export const EventMarker: FunctionComponent<Props> = ({
    location,
    events,
    onSelect,
    isSelected,
    zoom,
}) => {
    const iconSize = useMemo(() => {
        if (zoom >= 10) {
            return { width: 64, height: 72 };
        }

        if (zoom >= 8 && zoom < 10) {
            return { width: 48, height: 54 };
        }

        return { width: 34, height: 40 };
    }, [zoom]);

    const markNumberSizeAndPosition = useMemo(() => {
        if (zoom >= 10) {
            return { width: 22, height: 22, right: -60 };
        }

        if (zoom >= 8 && zoom < 10) {
            return { width: 18, height: 18, right: -50 };
        }

        return { width: 12, height: 12, right: -30 };
    }, [zoom]);

    const markNumberFontSize = useMemo(() => {
        if (zoom >= 10) {
            return '0.9rem';
        }

        if (zoom >= 8 && zoom < 10) {
            return '0.75rem';
        }

        return '0.6rem';
    }, [zoom]);

    const clickHandler = useCallback(() => {
        onSelect(events, location);
    }, [onSelect, events, location]);

    if (location.longitude === 0 && location.latitude === 0) {
        return null;
    }

    return (
        <OverlayView
            position={{
                lat: location.latitude,
                lng: location.longitude,
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div onClick={clickHandler} className={`${Styles.EventMarker}`}>
                {events.length > 1 && (
                    <div
                        className={`${Styles.MarkerNumber} d-flex justify-content-center align-items-center m-0`}
                        style={markNumberSizeAndPosition}
                    >
                        <span style={{ fontSize: markNumberFontSize }}>{events.length}</span>
                    </div>
                )}
                <img
                    alt="map-pin-selected-or-map-pin"
                    src={isSelected ? IMAGES.MapPinSelected : IMAGES.MapPin}
                    style={iconSize}
                />
            </div>
        </OverlayView>
    );
};
