import { ContactModel, MemberSearchResultModel } from '@/contacts/models';
import { initialContactsState, SignInBaseForm } from '@/contacts/reducer/state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const contactsSlice = createSlice({
    name: 'contacts',
    initialState: initialContactsState,
    reducers: {
        storeSearchResults: (state, action: PayloadAction<MemberSearchResultModel[]>) => {
            state.searchResults = action.payload;
        },
        selectContact: (state, action: PayloadAction<MemberSearchResultModel>) => {
            state.selectedContact = action.payload;
        },
        storeMatchedContact: (state, action: PayloadAction<ContactModel>) => {
            state.matchedContact = action.payload;
        },
        setSignInForm: (state, action: PayloadAction<SignInBaseForm>) => {
            state.signInForm = action.payload;
        },
    },
});

export const contactsReducer = contactsSlice.reducer;
export const contactsActions = contactsSlice.actions;
