import { GetEventsRequest } from '@/events/api/types';
import { EventFilters } from '@/events/reducer/types';

export const eventFiltersToGetEventsRequest = (
    filters: EventFilters,
    showUpcomingEvents: boolean
): GetEventsRequest => ({
    event_ids: filters.eventIds,
    latitude: filters.latitude,
    longitude: filters.longitude,
    show_upcoming_events: showUpcomingEvents,
});
