import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { EventModel, EventSubtype, EventType } from '@/events/models';
import { Badge } from '@/components/badge-v2';
import { getEventTypeLabel, getVenueTypeLabel } from '@/events/pages/all-events/utils';
import { useEventClientLogo } from '@/events/components/use-event-client-logo';
import { VenueType } from '@/venues/models';
import { TranslationService } from '@/services/translation';
import Styles from './Styles.module.scss';

type Props = {
    event: EventModel;
    containerCustomClassName?: string;
    rowAlignment?: string;
};

export const EventBadges: FunctionComponent<Props> = ({
    event,
    containerCustomClassName,
    rowAlignment = 'justify-content-end',
}) => {
    const { hasClientLogo, getEventLogoComponent } = useEventClientLogo({ event });

    return (
        <Row className={`mx-0 ${Styles.EventBadges} ${containerCustomClassName} w-100`}>
            <Col className={'d-flex align-item-center justify-content-start px-0'}>
                <Row className={`mx-0 d-flex ${rowAlignment} w-100`}>
                    {hasClientLogo && (
                        <Col xs={'auto'} className={'ps-0 pb-2 d-flex align-items-center'}>
                            {getEventLogoComponent()}
                        </Col>
                    )}

                    {event.venue.type === VenueType.VIRTUAL && (
                        <Col xs={'auto'} className={'ps-0 pb-2 d-flex align-items-center'}>
                            <Badge
                                label={getVenueTypeLabel(event)}
                                backgroundColor={'#E57200'}
                                color={'#FFFFFF'}
                                customClassName={`${Styles.VenueTypeLabel} text-center`}
                            />
                        </Col>
                    )}

                    {event?.type === EventType.KICKOFF && (
                        <Col xs={'auto'} className={'ps-0 pb-2 d-flex align-items-center'}>
                            <Badge
                                label={getEventTypeLabel(event)}
                                backgroundColor={'#FFFFFF'}
                                color={'#262626'}
                                customClassName={`${Styles.SeriesBadge} text-center`}
                            />
                        </Col>
                    )}

                    {event.subtype === EventSubtype.TOWN_HALL && (
                        <Col xs={'auto'} className={'ps-0 pb-2 d-flex align-items-center'}>
                            <Badge
                                label={TranslationService.getInstance().getPhrase(
                                    'events_list.badges.town_hall'
                                )}
                                backgroundColor={'#FFFFFF'}
                                color={'#262626'}
                                customClassName={`${Styles.NationwideBadge} text-center`}
                            />
                        </Col>
                    )}

                    {event.subtype === EventSubtype.AMBASSADOR && (
                        <Col xs={'auto'} className={'ps-0 pb-2 d-flex align-items-center'}>
                            <Badge
                                label={TranslationService.getInstance().getPhrase(
                                    'events_list.badges.ambassador'
                                )}
                                backgroundColor={'#FFFFFF'}
                                color={'#262626'}
                                customClassName={`${Styles.NationwideBadge} text-center`}
                            />
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};
