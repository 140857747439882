import { Coordinates } from '@/services/geolocation/types';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PREVIOUS_PAGE_NUMBER = -1;
export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_MILE_RADIUS = 5;
export const DEFAULT_LOCATION: Coordinates = {
    latitude: 37.4578538,
    longitude: -122.229004,
};

export const DEFAULT_ZOOM = 11;
