import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/esm/Card';
import Placeholder from 'react-bootstrap/Placeholder';

export const StepOneLoading: FunctionComponent = () => {
    return (
        <>
            <Row className={'mx-0 pb-4'}>
                <Col>
                    <Placeholder animation={'glow'} as={Card.Title}>
                        <Placeholder xs={8} bg="secondary" />
                    </Placeholder>
                </Col>
            </Row>

            <Row className={'mx-0 pb-4 pt-2'}>
                <Col>
                    <Placeholder animation={'glow'} as={Card.Text}>
                        <Placeholder xs={12} bg="secondary" size="sm" />
                        <Placeholder xs={12} bg="secondary" size="sm" />
                        <Placeholder xs={12} bg="secondary" size="sm" />
                        <Placeholder xs={6} bg="secondary" size="sm" />
                    </Placeholder>
                </Col>
            </Row>

            <Row className={'mx-0 pb-3 pt-1'}>
                <Col>
                    <Placeholder animation={'glow'} as={Card.Text}>
                        <Placeholder xs={4} bg="secondary" size="sm" />
                        <Placeholder xs={12} bg="secondary" size="lg" />
                    </Placeholder>
                </Col>
            </Row>

            <Row className={'mx-0 pb-4'}>
                <Col>
                    <Placeholder animation={'glow'} as={Card.Text}>
                        <Placeholder xs={4} bg="secondary" size="sm" />
                        <Placeholder xs={12} bg="secondary" size="lg" />
                    </Placeholder>
                </Col>
            </Row>

            <div className={'pb-2'} />
        </>
    );
};
