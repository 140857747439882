import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { EventModel } from '@/events/models';
import { useCurrentLocation } from '@/events/components/map-view/use-current-location';
import { AuthenticatedUserModel } from '@/authentication/models';
import { Boundaries, Coordinates } from '@/services/geolocation/types';
import { Geolocation } from '@/services/geolocation';
import { MapMarker } from '@/events/components/map-view/map-marker';
import { EventMarker } from '@/events/components/map-view/components/event-marker';
import groupBy from 'lodash.groupby';
import { EventDetailsBox } from '@/events/components/map-view/components/event-marker/event-details-box';
import intersection from 'lodash/intersection';
import { EventsListModal } from '@/events/components/map-view/components/events-list-modal';
import Styles from './Styles.module.scss';
import { EventsListMobileModal } from '@/events/components/map-view/components/events-list-mobile-modal';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';
import { ZipcodeBoundaries } from '@/events/components/map-view/components/locator';

import {
    buildAddressFromEvent,
    eventMarkerClicked,
    onDrag,
    onZoomed,
} from '@/events/components/map-view/helpers';

import { VenueType } from '@/venues/models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { UI_FRAMEWORK_SM_WIDTH } from '@/constants/layout';
import lodash from 'lodash';
import isEqual from 'lodash/isEqual';
import { eventsActions } from '@/events/reducer';
import { DEFAULT_LOCATION, DEFAULT_ZOOM } from '@/constants/defaults';
import { ZipcodeChangeModal } from '@/events/components/map-view/components/zipcode-question-modal';
import { useZipcodeBoundaries } from './use-zipcode-boundaries';
import { DEFAULT_OPTION, EventFilters } from '@/events/reducer/types';

interface Dictionary<T> {
    [index: string]: T;
}

type Props = {
    mapId?: string;
    events: EventModel[];
    authenticatedUser: AuthenticatedUserModel | undefined;
    onViewDetails: (event: EventModel) => void;
    onSelectedEvents: (events: EventModel[]) => void;
    areEventsClickable?: boolean;
    customClassname?: string;
    showLocator?: boolean;
    customHeight?: string;
    loadingEvents: boolean;
    recordBoundaries?: boolean;
    showFirstEventLocationOnly?: boolean;
};

type EventWithCoordinates = {
    events: EventModel[];
    coordinates: Coordinates;
};

export const MapView: FunctionComponent<Props> = ({
    mapId,
    events,
    authenticatedUser,
    onViewDetails,
    onSelectedEvents,
    areEventsClickable = true,
    customClassname,
    customHeight,
    loadingEvents,
    recordBoundaries = true,
    showFirstEventLocationOnly = false,
}) => {
    const [loadingEventCoordinates, setLoadingEventCoordinates] = useState<boolean>(true);
    const [eventCoordinates, setEventCoordinates] = useState<EventWithCoordinates[]>([]);
    const [selectedEvents, selectEvents] = useState<EventModel[]>([]);
    const [selectedEventLocation, setSelectedEventLocation] = useState<Coordinates | null>(null);
    const [customCoordinates, setCustomCoordinates] = useState<Coordinates | null>(null);
    const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
    const [zoom, setZoom] = useState<number | undefined>(DEFAULT_ZOOM);

    const mapRef = useRef(null);

    const mapBoundaries = useSelector<RootState, Boundaries | undefined>(
        (state) => state.events.mapBoundaries
    );

    const hasZipcodeBeingInput = useSelector<RootState, boolean>(
        (state) => state.events.hasZipcodeBeingInput
    );

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const dispatch = useDispatch();

    const { loading: loadingMyLocation, coordinates: center } = useCurrentLocation({
        authenticatedUser,
    });

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: Geolocation.getInstance().getApiKey(),
        id: 'google-map-script',
    });

    const { width } = useWindowDimensions();

    const getCoordinates = useCallback(
        async (eventsList: EventModel[]): Promise<EventWithCoordinates> => {
            if (eventsList[0].venue.longitude && eventsList[0].venue.latitude) {
                return {
                    events: eventsList,
                    coordinates: {
                        longitude: eventsList[0].venue.longitude,
                        latitude: eventsList[0].venue.latitude,
                    },
                };
            }

            const address = buildAddressFromEvent(eventsList[0]);
            let coordinates: Coordinates = {
                latitude: 0,
                longitude: 0,
            };

            if (address) {
                coordinates = await Geolocation.getInstance().getCoordinates(address);
            }

            return {
                events: eventsList,
                coordinates,
            };
        },
        []
    );

    const loadEventCoordinates = useCallback(async (): Promise<void> => {
        const inPersonEvents: EventModel[] = events.filter(
            (event) => event.venue.type === VenueType.IN_PERSON
        );

        const eventsByVenue: Dictionary<EventModel[]> = groupBy(
            inPersonEvents,
            (item) => item.venue.id
        );
        const promises: Promise<any>[] = Object.keys(eventsByVenue).map((venueId: string) =>
            getCoordinates(eventsByVenue[venueId])
        );

        const newCoordinates = await Promise.all(promises);

        setEventCoordinates(newCoordinates);
        setLoadingEventCoordinates(false);
    }, [events, getCoordinates]);

    const clickHandler = useCallback(() => {
        selectEvents([]);
    }, [selectEvents]);

    const selectEventHandler = useCallback(
        (newEvents: EventModel[], location: Coordinates) => {
            if (!areEventsClickable) {
                return;
            }

            if (selectedEvents.length === 0) {
                selectEvents(newEvents);
                setSelectedEventLocation(location);
                eventMarkerClicked(newEvents);
                return;
            }

            const sameEvents = intersection(newEvents, selectedEvents);

            if (sameEvents.length === 0) {
                selectEvents(newEvents);
                setSelectedEventLocation(location);
                eventMarkerClicked(newEvents);
                return;
            }

            selectEvents([]);
        },
        [areEventsClickable, selectedEvents]
    );

    const clearSelectionHandler = useCallback(() => {
        selectEvents([]);
    }, [selectEvents]);

    const mapCenterPosition = useMemo(() => {
        let position = center;

        if (selectedEventLocation) {
            position = selectedEventLocation;
        } else if (customCoordinates) {
            position = customCoordinates;
        }

        return {
            lat: position.latitude,
            lng: position.longitude,
        };
    }, [center, customCoordinates, selectedEventLocation]);

    const isLocationProvided = useMemo(() => {
        return mapCenterPosition.lat !== DEFAULT_LOCATION.latitude;
    }, [center, mapCenterPosition]);

    const updateSelectedEvents = useCallback(() => {
        if (selectedEvents.length === 0) {
            return;
        }

        const eventsSelected = events.filter((event) =>
            selectedEvents.find((item) => item.id === event.id)
        );

        if (!lodash.isEqual(eventsSelected, selectedEvents)) {
            selectEvents(eventsSelected);
        }
    }, [selectedEvents, selectEvents, events]);

    const getMapBoundaries = useCallback((mapInstance: google.maps.Map | undefined) => {
        const northEastCoordinates = mapInstance?.getBounds()?.getNorthEast();
        const southWestCoordinates = mapInstance?.getBounds()?.getSouthWest();

        const maxLat = northEastCoordinates?.lat() as number;
        const minLat = southWestCoordinates?.lat() as number;

        const maxLng = northEastCoordinates?.lng() as number;
        const minLng = southWestCoordinates?.lng() as number;

        if (!maxLat || !minLat || !maxLng || !minLng) {
            return undefined;
        }

        const newBoundaries: Boundaries = {
            northeast: {
                latitude: maxLat,
                longitude: maxLng,
            },
            northwest: {
                latitude: maxLat,
                longitude: minLng,
            },
            southeast: {
                latitude: minLat,
                longitude: maxLng,
            },
            southwest: {
                latitude: minLat,
                longitude: minLng,
            },
            center: {
                latitude: (maxLat + minLat) / 2,
                longitude: (maxLng + minLng) / 2,
            },
            state: 'unknown',
        };

        return newBoundaries;
    }, []);

    const onDragEnd = useCallback(() => {
        if (map?.getBounds() && recordBoundaries) {
            const newMapBoundaries = getMapBoundaries(map);
            if (newMapBoundaries && !isEqual(mapBoundaries, newMapBoundaries)) {
                dispatch(eventsActions.updateMapBoundaries(newMapBoundaries));
            }
        }
    }, [map, mapBoundaries, recordBoundaries]);

    const onZoomChanged = useCallback(
        (newZoom: number | undefined) => {
            setZoom(newZoom);
            onDragEnd();
        },
        [onDragEnd, zoom]
    );

    const onCustomCoordinates = useCallback((coordinates: Coordinates, zoomToValue: number) => {
        setCustomCoordinates({
            latitude: coordinates.latitude as number,
            longitude: coordinates.longitude as number,
        });

        map?.setZoom(zoomToValue);
    }, []);

    const onViewEventFromListModal = useCallback(
        (event: EventModel) => {
            onViewDetails(event);
            selectEvents([]);
        },
        [onViewDetails]
    );

    const boundaries = useZipcodeBoundaries({
        onCustomCoordinates,
        afterZipcodeCoordinatesSet: onDragEnd,
    });

    useEffect(() => {
        if (
            center.longitude !== DEFAULT_LOCATION.longitude &&
            center.latitude !== DEFAULT_LOCATION.latitude &&
            !hasZipcodeBeingInput
        ) {
            dispatch(eventsActions.updateHasZipcodeBeenInputted(true));
            dispatch(eventsActions.updateVenueTypesFilter([DEFAULT_OPTION.value]));
        }
    }, [center, hasZipcodeBeingInput, filters.zipcode]);

    useEffect(() => {
        loadEventCoordinates();
        updateSelectedEvents();
    }, [events, loadEventCoordinates, updateSelectedEvents]);

    useEffect(() => {
        onSelectedEvents(selectedEvents);
    }, [onSelectedEvents, selectedEvents]);

    useEffect(() => {
        if (!loadingEvents && isLoaded) {
            onDragEnd();
        }
    }, [loadingEvents, isLoaded, onDragEnd]);

    useEffect(() => {
        if (
            showFirstEventLocationOnly &&
            events.length > 0 &&
            events[0].venue.type === VenueType.IN_PERSON
        ) {
            setCustomCoordinates({
                latitude: events[0].venue.latitude as number,
                longitude: events[0].venue.longitude as number,
            });
        }
    }, [showFirstEventLocationOnly, events]);

    const mapOptions = {
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        scaleControl: false,
        rotateControl: false,
        panControl: false,
        clickableIcons: false,
        gestureHandling: 'greedy',
    };

    return (
        <div className={`${Styles.MapView} ${customClassname}`}>
            {!isLoaded && (
                <div className={`placeholder-glow`}>
                    <div
                        className={`bg-secondary placeholder w-100`}
                        style={{
                            height: 600,
                        }}
                    />
                </div>
            )}

            {!loadingMyLocation && !loadingEventCoordinates && isLoaded && (
                <>
                    {!isLocationProvided && <ZipcodeChangeModal />}

                    <GoogleMap
                        ref={mapRef}
                        mapContainerStyle={{
                            filter: isLocationProvided ? 'initial' : 'blur(6px)',
                            height: customHeight || '100%',
                            top: '0%',
                        }}
                        center={mapCenterPosition}
                        zoom={DEFAULT_ZOOM}
                        clickableIcons={false}
                        options={mapOptions}
                        onZoomChanged={() => onZoomed(map, onZoomChanged)}
                        onDrag={onDrag}
                        onLoad={(newMap) => setMap(newMap)}
                        onDragEnd={onDragEnd}
                    >
                        {center ? (
                            <MapMarker
                                location={{
                                    longitude: center.longitude,
                                    latitude: center.latitude,
                                }}
                            />
                        ) : null}
                        {!showFirstEventLocationOnly && boundaries ? (
                            <ZipcodeBoundaries boundaries={boundaries} />
                        ) : null}

                        {eventCoordinates.map((marker) => (
                            <EventMarker
                                key={marker.events[0].id}
                                location={marker.coordinates}
                                events={marker.events}
                                onSelect={selectEventHandler}
                                isSelected={
                                    selectedEvents.length !== 0
                                        ? intersection(selectedEvents, marker.events).length !== 0
                                        : false
                                }
                                zoom={zoom || 0}
                            />
                        ))}

                        {width > UI_FRAMEWORK_SM_WIDTH &&
                            selectedEvents.length === 1 &&
                            selectedEventLocation && (
                                <EventDetailsBox
                                    event={selectedEvents[0]}
                                    position={selectedEventLocation}
                                    onClose={clickHandler}
                                    onDisplayDetails={onViewDetails}
                                />
                            )}

                        {width > UI_FRAMEWORK_SM_WIDTH &&
                            selectedEvents.length > 1 &&
                            selectedEventLocation && (
                                <EventsListModal
                                    events={selectedEvents}
                                    onClose={clearSelectionHandler}
                                    onDisplayDetails={onViewEventFromListModal}
                                />
                            )}

                        {width <= UI_FRAMEWORK_SM_WIDTH &&
                            selectedEvents.length !== 0 &&
                            selectedEventLocation && (
                                <EventsListMobileModal
                                    events={selectedEvents}
                                    onClose={clearSelectionHandler}
                                    isOpen={selectedEvents.length !== 0}
                                    onDisplayDetails={onViewEventFromListModal}
                                />
                            )}
                    </GoogleMap>
                </>
            )}
        </div>
    );
};
