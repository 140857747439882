import { createAsyncThunk, createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { EventModel, EventSubtype } from '@/events/models';
import { initialEventsState } from '@/events/reducer/state';
import { getEvents, getEventsForMap } from '@/events/api';
import {
    EventFilters,
    FilteringEventIdsByType,
    RegistrationStatus,
    ViewMode,
} from '@/events/reducer/types';
import { eventFiltersToGetEventsRequest } from '@/events/mappers/requests-mapper';
import { getUSStatesForFiltering } from '@/events/reducer/helper';

import {
    DEFAULT_LOCATION,
    DEFAULT_PAGE_NUMBER,
    DEFAULT_PAGE_SIZE,
    DEFAULT_PREVIOUS_PAGE_NUMBER,
} from '@/constants/defaults';

import { Boundaries } from '@/services/geolocation/types';
import isEqual from 'lodash/isEqual';

export const fetchEvents = createAsyncThunk('events/fetchEvents', async (request: EventFilters) => {
    return await getEvents(eventFiltersToGetEventsRequest(request, false));
});

export const fetchEventsForMap = createAsyncThunk('events/fetchEventsForMap', async () => {
    return await getEventsForMap();
});

export const fetchUpcomingWeeklyEvents = createAsyncThunk<
    EventModel[],
    {
        eventId: string;
    }
>('events/fetchUpcomingWeeklyEvents', async ({ eventId }) => {
    const searchResponse = await getEvents(
        eventFiltersToGetEventsRequest(
            {
                previousPage: DEFAULT_PREVIOUS_PAGE_NUMBER,
                page: DEFAULT_PAGE_NUMBER,
                pageSize: DEFAULT_PAGE_SIZE,
                eventIds: [eventId],
                searchText: '',
                client: '',
                states: [],
                venueTypes: [],
                languages: [],
                onlyRegisteredEvents: false,
                campaigns: [],
                latitude: DEFAULT_LOCATION.latitude,
                longitude: DEFAULT_LOCATION.longitude,
            },
            true
        )
    );

    let eventFound = searchResponse.virtualEvents.find((event) => event.id === eventId);

    if (!eventFound) {
        eventFound = searchResponse.inPersonEvents.find((event) => event.id === eventId);
    }

    return eventFound ? eventFound.upcomingWeeklyEvents : [];
});

export const eventsSlice = createSlice({
    name: 'events',
    initialState: initialEventsState,
    reducers: {
        storeInitialFetchedEventsForMap: (state, action: PayloadAction<EventModel[]>) => {
            state.fetchedEventsForMap = [...action.payload];
        },
        storeFetchedEventsForMap: (state, action: PayloadAction<EventModel[]>) => {
            state.fetchedEventsForMap = [...state.fetchedEvents, ...action.payload];
        },
        storeFilteredEventsForMap: (state, action: PayloadAction<EventModel[]>) => {
            if (!isEqual(action.payload, state.filteredEventsForMap)) {
                state.filteredEventsForMap = action.payload;
            }
        },
        storeFilteredEventsForList: (state, action: PayloadAction<EventModel[]>) => {
            state.filteredEventsForList = action.payload;
        },
        storeFilteredEventsByUser: (state, action: PayloadAction<EventModel[]>) => {
            state.filteredEventsByUser = action.payload;
        },
        selectEvent: (state, action: PayloadAction<EventModel | undefined>) => {
            state.selectedEvent = action.payload;
        },
        updateStatesFilter: (state, action: PayloadAction<string[]>) => {
            state.filters = {
                ...state.filters,
                states: action.payload,
                page: 0,
                previousPage: -1,
            };
        },
        updateLanguagesFilter: (state, action: PayloadAction<string[]>) => {
            state.filters = {
                ...state.filters,
                languages: action.payload,
                page: 0,
                previousPage: -1,
            };
        },
        updateVenueTypesFilter: (state, action: PayloadAction<string[]>) => {
            state.filters = {
                ...state.filters,
                venueTypes: action.payload,
                page: 0,
                previousPage: -1,
            };
        },
        updateClientFilter: (state, action: PayloadAction<string>) => {
            state.filters = {
                ...state.filters,
                client: action.payload,
                page: 0,
                previousPage: -1,
            };
        },
        updateSearchTextFilter: (state, action: PayloadAction<string>) => {
            state.filters = {
                ...state.filters,
                searchText: action.payload,
                page: 0,
                previousPage: -1,
            };
        },
        updateZipcodeFilter: (state, action: PayloadAction<string>) => {
            state.filters = {
                ...state.filters,
                zipcode: action.payload,
            };
        },
        updateHasZipcodeBeenInputted: (state, action: PayloadAction<boolean>) => {
            state.hasZipcodeBeingInput = true;
        },
        updateHasFirstCorrectZipcodeBeenInputted: (state, action: PayloadAction<boolean>) => {
            state.hasFirstCorrectZipcodeBeenInputted = true;
        },
        goToNextPage: (state) => {
            const newPage = state.filters.page + 1;
            const fromIndex = newPage * DEFAULT_PAGE_SIZE;

            state.filters = {
                ...state.filters,
                page: newPage,
                previousPage: state.filters.page,
                eventIds: state.allEventIds.slice(fromIndex, fromIndex + DEFAULT_PAGE_SIZE),
            };
        },
        updateOnlyRegisteredEventsFilter: (state, action: PayloadAction<boolean>) => {
            state.filters = {
                ...state.filters,
                onlyRegisteredEvents: action.payload,
            };
        },
        updateCampaignsFilter: (state, action: PayloadAction<string[]>) => {
            state.filters = {
                ...state.filters,
                campaigns: action.payload,
            };
        },
        resetFilters: (state) => {
            state.filters = { ...initialEventsState.filters };
        },
        updateViewMode: (state, action: PayloadAction<ViewMode>) => {
            state.viewMode = action.payload;
        },
        updateRegistrationStatus: (state, action: PayloadAction<RegistrationStatus>) => {
            state.registrationStatus = action.payload;
        },

        updateEventIdsFilter: (state, action: PayloadAction<FilteringEventIdsByType>) => {
            const newEventIds = [
                ...action.payload.virtualEvents.map((event) => event.id),
                ...action.payload.inPersonEvents.map((event) => event.id),
            ];

            if (isEqual(newEventIds, state.allEventIds) && !state.filters.onlyRegisteredEvents) {
                return;
            }

            const nationwideEventIds = [
                ...action.payload.virtualEvents,
                ...action.payload.inPersonEvents,
            ].filter((event) =>
                [EventSubtype.TOWN_HALL, EventSubtype.AMBASSADOR].includes(event.subtype)
            );

            state.filters.page = 0;
            state.filters.previousPage = -1;
            state.filters.eventIds = newEventIds.slice(0, DEFAULT_PAGE_SIZE);
            state.allEventIds = newEventIds;
            state.numberOfShownNationwideEvents = 0;
            state.numberOfShownNonNationwideEvents = 0;
            state.totalNumberOfNonNationwideEvents = newEventIds.length - nationwideEventIds.length;
            state.totalNumberOfNationwideEvents = nationwideEventIds.length;
            state.isLoadingEvents = true;
        },
        updateLatitudeFilter: (state, action: PayloadAction<number>) => {
            state.filters = {
                ...state.filters,
                latitude: action.payload,
            };
        },
        updateLongitudeFilter: (state, action: PayloadAction<number>) => {
            state.filters = {
                ...state.filters,
                longitude: action.payload,
            };
        },
        updateMileRadiusFilter: (state, action: PayloadAction<number | undefined>) => {
            state.filters = {
                ...state.filters,
                mileRadius: action.payload,
            };
        },
        updateNumberOfAvailableEvents: (state, action: PayloadAction<number>) => {
            state.numberOfAvailableEvents = action.payload;
        },
        updateNumberOfRegisteredEvents: (state, action: PayloadAction<number>) => {
            state.numberOfRegisteredEvents = action.payload;
        },
        updateUsStates: (state, action: PayloadAction<string[]>) => {
            state.usStates = action.payload;
        },
        updateMapBoundaries: (state, action: PayloadAction<Boundaries>) => {
            state.mapBoundaries = action.payload;
        },
        displayFiltersModal: (state, action: PayloadAction<boolean>) => {
            state.isFiltersModalDisplayed = action.payload;
        },
        updateAllEventIds: (state, action: PayloadAction<string[]>) => {
            state.allEventIds = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchEvents.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        virtualEvents: EventModel[];
                        inPersonEvents: EventModel[];
                    }>
                ) => {
                    const allEvents = [
                        ...action.payload.virtualEvents,
                        ...action.payload.inPersonEvents,
                    ].sort((event1, event2) =>
                        event1.startDateInMilliseconds < event2.startDateInMilliseconds ? -1 : 1
                    );

                    if (state.filters.previousPage < 0) {
                        state.fetchedEvents = allEvents;
                    } else {
                        state.fetchedEvents = [...state.fetchedEvents, ...allEvents];
                    }

                    state.isLoadingEvents = false;

                    state.numberOfShownNationwideEvents = state.fetchedEvents.map((event) =>
                        [EventSubtype.AMBASSADOR, EventSubtype.TOWN_HALL].includes(event.subtype)
                    ).length;

                    state.numberOfShownNonNationwideEvents = state.fetchedEvents.map(
                        (event) =>
                            ![EventSubtype.AMBASSADOR, EventSubtype.TOWN_HALL].includes(
                                event.subtype
                            )
                    ).length;
                }
            )
            .addCase(fetchEvents.rejected, (state) => {
                state.isLoadingEvents = false;
            })
            .addCase(fetchEventsForMap.pending, (state) => {
                state.isLoadingEventsForMap = true;
            })
            .addCase(
                fetchEventsForMap.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        events: EventModel[];
                        total: number;
                    }>
                ) => {
                    state.fetchedEventsForMap = action.payload.events;
                    state.usStates = getUSStatesForFiltering(action.payload.events);
                    state.isLoadingEventsForMap = false;

                    if (
                        state.selectedEvent &&
                        !action.payload.events.find((event) => event.id === state.selectedEvent?.id)
                    ) {
                        state.selectedEvent = undefined;
                    }
                }
            )
            .addCase(fetchEventsForMap.rejected, (state) => {
                state.isLoadingEventsForMap = false;
            })
            .addCase(fetchUpcomingWeeklyEvents.pending, (state) => {
                state.isLoadingEvents = true;
            })
            .addCase(
                fetchUpcomingWeeklyEvents.fulfilled,
                (state, action: PayloadAction<EventModel[]>) => {
                    state.fetchedUpcomingWeeklyEvents = action.payload;
                    state.isLoadingEvents = false;
                }
            )
            .addCase(fetchUpcomingWeeklyEvents.rejected, (state) => {
                state.isLoadingEvents = false;
            });
    },
});

export const eventsReducer = eventsSlice.reducer;
export const eventsActions = eventsSlice.actions;
