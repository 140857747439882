import React, { FunctionComponent, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Styles from '../Styles.module.scss';
import { InputBox } from '@/components/form-controls/input';
import { RegistrationFormData } from '@/events/pages/event-registration/components/use-registration-form-data';
import { PhoneInputBox } from '@/components/form-controls/phone-input';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import { TranslationService } from '@/services/translation';

type Props = {
    formData: RegistrationFormData;
};

export const StepFour: FunctionComponent<Props> = ({ formData }) => {
    useEffect(() => {
        Analytics.getInstance().fireEvent(
            AnalyticsEventName.UnmatchedSignInFormFocused,
            AnalyticsEventCategory.SignIn,
            'User has started inputting information into the Eligibility Check modal after unmatched',
            {}
        );
    }, []);

    return (
        <>
            <Row className={'mx-0'}>
                <Col>
                    <span
                        className={`text-dark text-montserrat text-center d-block ${Styles.ModalTitleStepOne}`}
                    >
                        Hmm ...
                    </span>
                </Col>
            </Row>

            <Row className={'mx-0 pb-4 pt-2'}>
                <Col>
                    <span
                        className={`text-base text-center d-block ${Styles.RegistrationModalParagraph}`}
                    >
                        {TranslationService.getInstance().getPhrase('sign_in.step_four.paragraph')}
                    </span>
                </Col>
            </Row>

            <Row className={'mx-0 pb-3 pt-1'}>
                <Col>
                    <PhoneInputBox
                        label={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.phone.label'
                        )}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.phone.placeholder'
                        )}
                        value={formData.homePhone.value}
                        onChange={formData.homePhone.setValue}
                        isValid={formData.homePhone.isValid}
                    />
                </Col>
            </Row>

            <Row className={'mx-0 pb-3 pt-1'}>
                <Col>
                    <PhoneInputBox
                        label={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.mobile_phone.label'
                        )}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.mobile_phone.placeholder'
                        )}
                        value={formData.mobilePhone.value}
                        onChange={formData.mobilePhone.setValue}
                        isValid={formData.mobilePhone.isValid}
                    />
                </Col>
            </Row>

            <Row className={'mx-0 pb-3'}>
                <Col>
                    <InputBox
                        type={'text'}
                        label={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.first_name.label'
                        )}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.first_name.placeholder'
                        )}
                        value={formData.firstName.value}
                        onChange={formData.firstName.setValue}
                        isValid={formData.firstName.isValid}
                    />
                </Col>
            </Row>

            <Row className={'mx-0 pb-3'}>
                <Col>
                    <InputBox
                        type={'text'}
                        label={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.last_name.label'
                        )}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.last_name.placeholder'
                        )}
                        value={formData.lastName.value}
                        onChange={formData.lastName.setValue}
                        isValid={formData.lastName.isValid}
                    />
                </Col>
            </Row>

            <Row className={'mx-0 pb-3'}>
                <Col>
                    <InputBox
                        type={'text'}
                        label={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.zipcode.label'
                        )}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.zipcode.placeholder'
                        )}
                        value={formData.zipCode.value}
                        onChange={formData.zipCode.setValue}
                        isValid={formData.zipCode.isValid}
                    />
                </Col>
            </Row>

            <Row className={'mx-0 pb-3'}>
                <Col>
                    <InputBox
                        type={'text'}
                        label={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.email.label'
                        )}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'sign_in.step_four.form.email.placeholder'
                        )}
                        value={formData.email.value}
                        onChange={formData.email.setValue}
                        isValid={formData.email.isValid}
                    />
                </Col>
            </Row>
        </>
    );
};
