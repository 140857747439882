import React, { FunctionComponent } from 'react';
import { IMAGES } from '@/assets/images';
import Styles from './Styles.module.scss';
import { TranslationService } from '@/services/translation';
import { ViewMode } from '@/events/reducer/types';
import { useViewModeOperations } from '@/events/components/view-mode/use-view-mode-operations';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

export const ViewModeMobileSwitch: FunctionComponent = () => {
    const mode: ViewMode = useSelector<RootState, ViewMode>((state) => state.events.viewMode);

    const { onViewMap, onHideMap } = useViewModeOperations();

    return mode === ViewMode.MAP ? (
        <div className={`${Styles.ViewModeSwitch} px-3 py-2 mx-3 mb-4`} onClick={onHideMap}>
            <img alt="hide-map-icon" className={'pe-2'} src={IMAGES.HideMapIcon} />
            <span>
                {TranslationService.getInstance().getPhrase('events_list.view_mode.hide_map')}
            </span>
        </div>
    ) : (
        <div className={`${Styles.ViewModeSwitch} px-3 py-2 mx-3 mb-4`} onClick={onViewMap}>
            <img alt="view-map-icon" className={'pe-2'} src={IMAGES.ViewMapIcon} />
            <span>
                {TranslationService.getInstance().getPhrase('events_list.view_mode.view_map')}
            </span>
        </div>
    );
};
