import React, { FunctionComponent, useMemo } from 'react';
import Styles from './Styles.module.scss';
import { TranslationService } from '@/services/translation';
import { ViewMode } from '@/events/reducer/types';
import { useViewModeOperations } from '@/events/components/view-mode/use-view-mode-operations';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';

export const ViewModeWebSwitch: FunctionComponent = () => {
    const mode: ViewMode = useSelector<RootState, ViewMode>((state) => state.events.viewMode);

    const { onViewMap, onHideMap } = useViewModeOperations();

    const mapButtonClassName = useMemo(
        () => (mode === ViewMode.MAP ? Styles.Active : Styles.Inactive),
        [mode]
    );

    const listButtonClassName = useMemo(
        () => (mode === ViewMode.EVENTS_LIST ? Styles.Active : Styles.Inactive),
        [mode]
    );

    return (
        <div className={`${Styles.ViewModeWebSwitch} d-flex flex-column align-items-start w-100`}>
            <span className={`${Styles.Title} mb-1`}>View by</span>

            <div
                className={`${Styles.ViewModeWebSwitchButtons} d-flex w-100 align-items-center justify-content-center h-100`}
            >
                <div
                    className={`${Styles.MapButton} ${mapButtonClassName} px-2 d-flex align-items-center justify-content-center w-50 h-100`}
                    onClick={onViewMap}
                >
                    <span className={'text-center'}>
                        {TranslationService.getInstance().getPhrase('events_list.view_mode.map')}
                    </span>
                </div>

                <div
                    className={`${Styles.ListButton} ${listButtonClassName} px-2 d-flex align-items-center justify-content-center w-50 h-100`}
                    onClick={onHideMap}
                >
                    <span className={'text-center'}>
                        {TranslationService.getInstance().getPhrase('events_list.view_mode.list')}
                    </span>
                </div>
            </div>
        </div>
    );
};
