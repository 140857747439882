import { QueryParameters } from '@/authentication/reducer/state';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useUrlUpdater = () => {
    const navigate = useNavigate();

    const [isFirstReaction, setIsFirstReaction] = useState(false);

    const queryParameters: QueryParameters = useSelector<RootState, QueryParameters>(
        (state) => state.authentication.queryParameters
    );

    useEffect(() => {
        if (queryParameters.clients.length === 0 && !isFirstReaction) {
            return;
        }

        setIsFirstReaction(true);

        const clientParameter: string | undefined =
            queryParameters.clients.length > 0 ? queryParameters.clients[0] : undefined;
        const campaignsParameter: string[] = queryParameters.campaigns;
        const eventIdParameter: string | undefined = queryParameters.eventId;
        const stateParameter: string | undefined = queryParameters.state;

        navigate(
            `/?${clientParameter ? `client=${clientParameter}` : ''}${
                campaignsParameter.length > 0 ? `&campaign=${campaignsParameter.join('%3B')}` : ''
            }${stateParameter ? `&state=${stateParameter}` : ''}${
                eventIdParameter ? `&event=${eventIdParameter}` : ''
            }`
        );
    }, [queryParameters, isFirstReaction, navigate]);
};
