import React, { FunctionComponent, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Styles from './Styles.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    message: string;
};

export const ShareModal: FunctionComponent<Props> = ({ isOpen, onClose, title, message }) => {
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                onClose();
            }, 2000);
        }
    }, [isOpen, onClose]);

    return (
        <Modal centered={true} show={isOpen} onHide={onClose}>
            <Modal.Body className={Styles.ChangeOfClientWarningModal}>
                <Row className={'pb-4'}>
                    <Col>
                        <span className={Styles.Title}>{title}</span>
                    </Col>
                </Row>

                <Row className={'pb-4 pt-2'}>
                    <Col>
                        <span className={Styles.Paragraph}>{message}</span>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
