import React, { ChangeEvent, FunctionComponent, MouseEventHandler, useCallback } from 'react';
import Styles from './Styles.module.scss';

type Props = {
    id: string;
    label: string;
    value: string;
    isChecked: boolean;
    onChange: (newValue: string) => void;
};

export const RadioButton: FunctionComponent<Props> = ({
    id,
    label,
    value,
    isChecked,
    onChange,
}) => {
    const changeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        },
        [onChange]
    );

    const clickHandler = useCallback(() => {
        onChange(value);
    }, [value, onChange]);

    return (
        <label
            htmlFor={id}
            className={`${Styles.RadioButton} ${
                isChecked && Styles.BorderPrimaryBlue
            } d-flex align-items-center`}
        >
            <input
                id={id}
                type="radio"
                value={value}
                checked={isChecked}
                onChange={changeHandler}
            />
            <div
                className={`checkmark-box ${Styles.CheckmarkBox} d-flex align-items-center`}
                onClick={clickHandler}
            >
                {isChecked && <div className={Styles.dot} />}
            </div>
            <span>{label}</span>
        </label>
    );
};
