import UsStatesBoundaries from '@/constants/us-states-boundaries-info.json';
import { Boundaries, Coordinates } from '@/services/geolocation/types';
import { US_STATES } from '@/constants/us-states';

const POLYGON_GEOMETRY_TYPE = 'polygon';
const MULTIPOLYGON_GEOMETRY_TYPE = 'multipolygon';

const isStateWithinNorthWestBoundaries = (coordinate: Coordinates, coordinates: number[][]) => {
    const coordinateFound = coordinates.find(
        ([longitude, latitude]) =>
            longitude >= coordinate.longitude && latitude <= coordinate.latitude
    );
    return !!coordinateFound;
};

const isStateWithinSouthWestBoundaries = (coordinate: Coordinates, coordinates: number[][]) => {
    const coordinateFound = coordinates.find(
        ([longitude, latitude]) =>
            longitude >= coordinate.longitude && latitude >= coordinate.latitude
    );
    return !!coordinateFound;
};

const isStateWithinNorthEastBoundaries = (coordinate: Coordinates, coordinates: number[][]) => {
    const coordinateFound = coordinates.find(
        ([longitude, latitude]) =>
            longitude <= coordinate.longitude && latitude <= coordinate.latitude
    );
    return !!coordinateFound;
};

const isStateWithinSouthEastBoundaries = (coordinate: Coordinates, coordinates: number[][]) => {
    const coordinateFound = coordinates.find(
        ([longitude, latitude]) =>
            longitude <= coordinate.longitude && latitude >= coordinate.latitude
    );
    return !!coordinateFound;
};

const isStateWithinBoundaries = (boundaries: Boundaries, coordinates: number[][]) => {
    return (
        isStateWithinNorthWestBoundaries(boundaries.northwest, coordinates) &&
        isStateWithinNorthEastBoundaries(boundaries.northeast, coordinates) &&
        isStateWithinSouthWestBoundaries(boundaries.southwest, coordinates) &&
        isStateWithinSouthEastBoundaries(boundaries.southeast, coordinates)
    );
};

export const getStatesWithinBoundaries = (boundaries: Boundaries) => {
    return UsStatesBoundaries.features
        .filter((feature) => {
            if (feature.geometry.type.toLowerCase() === POLYGON_GEOMETRY_TYPE) {
                const coordinates = feature.geometry.coordinates[0] as number[][];
                return isStateWithinBoundaries(boundaries, coordinates);
            }

            if (feature.geometry.type.toLowerCase() === MULTIPOLYGON_GEOMETRY_TYPE) {
                const coordinatesInPolygon = feature.geometry.coordinates as number[][][][];

                const coordinateFound = coordinatesInPolygon.find((coordinates) => {
                    return isStateWithinBoundaries(boundaries, coordinates[0]);
                });

                return !!coordinateFound;
            }

            return false;
        })
        .map((feature) => {
            return (
                US_STATES.find(
                    (usState) =>
                        feature.properties.name.toLowerCase() === usState.name.toLowerCase()
                )?.abbreviation.toLowerCase() || '----'
            );
        });
};
