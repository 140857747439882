import ReactGA from 'react-ga4';
import { Config } from '@/utilities/config';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';

export class Analytics {
    private static _instance: Analytics;

    public constructor() {
        ReactGA.initialize(Config.GoogleTagId);
    }

    public static getInstance(): Analytics {
        if (this._instance === undefined) {
            this._instance = new Analytics();
        }

        return this._instance;
    }

    public sendPageView(path: string, title: string): void {
        ReactGA.send({
            hitType: 'pageview',
            page: path,
            title,
        });
    }

    public fireEvent(
        eventName: AnalyticsEventName,
        eventCategory: AnalyticsEventCategory,
        eventLabel: string,
        parameters?: { [key: string]: any }
    ): void {
        ReactGA.event(
            {
                category: eventCategory,
                action: eventName,
                label: eventLabel,
            },
            parameters
        );
    }
}
