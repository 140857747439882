import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { eventsActions } from '@/events/reducer';
import { ViewMode } from '@/events/reducer/types';

type UseViewModeOperationsResult = {
    onViewMap: () => void;
    onHideMap: () => void;
};

export const useViewModeOperations = (): UseViewModeOperationsResult => {
    const dispatch = useDispatch();

    const onViewMap = useCallback(() => dispatch(eventsActions.updateViewMode(ViewMode.MAP)), []);
    const onHideMap = useCallback(
        () => dispatch(eventsActions.updateViewMode(ViewMode.EVENTS_LIST)),
        []
    );

    return {
        onViewMap,
        onHideMap,
    };
};
