import { useCallback, useEffect, useState } from 'react';
import { AuthenticatedUserModel } from '@/authentication/models';
import { Geolocation } from '@/services/geolocation';
import { Coordinates } from '@/services/geolocation/types';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import { DEFAULT_LOCATION } from '@/constants/defaults';

type GeolocationPositionResponse = {
    coords: {
        latitude: number;
        longitude: number;
    };
};

type UseCurrentLocationParams = {
    authenticatedUser: AuthenticatedUserModel | undefined;
};

export const useCurrentLocation = ({ authenticatedUser }: UseCurrentLocationParams) => {
    const [loadingMyLocation, setLoadingMyLocation] = useState<boolean>(true);
    const [locationCoordinates, setLocationCoordinates] = useState<Coordinates>(DEFAULT_LOCATION);

    const setCurrentPositionFromGeolocation = useCallback(
        (response: GeolocationPositionResponse) => {
            setLocationCoordinates({
                latitude: response.coords.latitude,
                longitude: response.coords.longitude,
            });

            setLoadingMyLocation(false);

            Analytics.getInstance().fireEvent(
                AnalyticsEventName.LocationShared,
                AnalyticsEventCategory.Application,
                'User allows sharing its location',
                {
                    lat: response.coords.latitude,
                    lng: response.coords.longitude,
                }
            );
        },
        [setLocationCoordinates, setLoadingMyLocation]
    );

    const setCurrentPositionFromAuthenticatedUser = useCallback(async (): Promise<void> => {
        if (!authenticatedUser) {
            setLoadingMyLocation(false);
            return;
        }

        const { otherAddress } = authenticatedUser;
        let coordinates: Coordinates | null = null;

        if (otherAddress.latitude && otherAddress.longitude) {
            setLocationCoordinates({
                latitude: otherAddress.latitude,
                longitude: otherAddress.longitude,
            });
        } else if (otherAddress.city && otherAddress.state && otherAddress.zipcode) {
            coordinates = await Geolocation.getInstance().getCoordinates(
                `${otherAddress.addressLine1}, ${otherAddress.city}, ${otherAddress.state} ${otherAddress.zipcode}`
            );
        }

        if (coordinates) {
            setLocationCoordinates(coordinates);
        }

        setLoadingMyLocation(false);
    }, [authenticatedUser]);

    useEffect(() => {
        navigator?.geolocation.getCurrentPosition(
            setCurrentPositionFromGeolocation,
            setCurrentPositionFromAuthenticatedUser
        );
    }, []);

    return {
        loading: loadingMyLocation,
        coordinates: locationCoordinates,
    };
};
