import React, { FunctionComponent, useMemo } from 'react';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { EventModel } from '@/events/models';
import { DateModel } from '@/utilities/dates';
import { TranslationService } from '@/services/translation';
import { VenueType } from '@/venues/models';

type Props = {
    event: EventModel;
    customWidth?: string | number;
    direction?: 'up' | 'down';
};

export const AddEventToCalendar: FunctionComponent<Props> = ({
    event,
    customWidth,
    direction = 'down',
}) => {
    const calendarEvent = useMemo(() => {
        let location = event.venue.name;

        if (event.venue.type === VenueType.IN_PERSON) {
            const streetLine = !!event.venue.addressLine2
                ? `${event.venue.addressLine1}, ${event.venue.addressLine2}`
                : event.venue.addressLine1;

            location = !!streetLine
                ? `${streetLine}, ${event.venue.city}, ${event.venue.state} ${event.venue.zipcode}`
                : `${event.venue.city}, ${event.venue.state} ${event.venue.zipcode}`;
        }

        return {
            name: event.name,
            description: event.description,
            startDate: new DateModel(event.startDateInMilliseconds).format(
                'YYYY-MM-DD',
                event.venue.timezone
            ),
            endDate: new DateModel(event.endDateInMilliseconds).format(
                'YYYY-MM-DD',
                event.venue.timezone
            ),
            startTime: new DateModel(event.startDateInMilliseconds).format(
                'HH:mm',
                event.venue.timezone
            ),
            endTime: new DateModel(event.endDateInMilliseconds).format(
                'HH:mm',
                event.venue.timezone
            ),
            timeZone: event.venue.timezone,
            location,
        };
    }, [event]);

    const styleLight = useMemo(
        () =>
            [
                '--btn-background: #FFFFFF',
                '--btn-text: #004E9C',
                '--btn-border: #004E9C',
                '--btn-border-radius: 8px',
                "--font: 'Noto Sans'",
            ].join('; '),
        []
    );

    return (
        <AddToCalendarButton
            name={calendarEvent.name}
            description={calendarEvent.description}
            location={calendarEvent.location}
            startDate={calendarEvent.startDate}
            startTime={calendarEvent.startTime}
            endDate={calendarEvent.endDate}
            endTime={calendarEvent.endTime}
            timeZone={calendarEvent.timeZone}
            options={['Apple', 'Google']}
            label={TranslationService.getInstance().getPhrase(
                'registration.after_registration.buttons.add_calendar'
            )}
            hideIconButton
            hideCheckmark
            styleLight={styleLight}
        />
    );
};
