export enum VenueType {
    VIRTUAL = 'virtual',
    IN_PERSON = 'person',
}

export interface VenueModel {
    id: string;
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipcode: string;
    phoneNumber?: string;
    type: VenueType;
    timezone?: string;
    latitude?: number;
    longitude?: number;
}

export type VenueLocationModel = {
    id: string;
    lat?: number;
    lng?: number;
    type: VenueType;
};
