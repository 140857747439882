import { TranslationService } from '@/services/translation';

export enum API_VENUE_TYPE {
    InPerson = 'person',
    Virtual = 'virtual',
}

export const EVENT_TYPES = [
    {
        value: 'in-person',
        apiValue: API_VENUE_TYPE.InPerson,
        label: TranslationService.getInstance().getPhrase(
            'filters.bar.event_type.options.in_person'
        ),
    },
    {
        value: 'virtual',
        apiValue: API_VENUE_TYPE.Virtual,
        label: TranslationService.getInstance().getPhrase('filters.bar.event_type.options.virtual'),
    },
];
