import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EventModel, EventSubtype } from '@/events/models';
import Styles from './Styles.module.scss';
import GeneralStyles from '../general-styles.module.scss';
import { useEventClientLogo } from '@/events/components/use-event-client-logo';
import { Button, ButtonType } from '@/components/form-controls/button';
import { getRegistrationButtonLabel } from '@/events/pages/all-events/utils';
import { EventTime } from '@/events/components/event-time';
import { EventLocation } from '@/events/components/event-location';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { useRegistrationFromEventsList } from '@/events/hooks/use-registration-from-events-list';
import { VenueType } from '@/venues/models';
import { EventBadges } from '@/events/components/event-badges';

type Props = {
    event: EventModel;
    onDisplayDetails: (event: EventModel) => void;
    isSelected: boolean;
    onShowMapAppModal: (event: EventModel) => void;
};

export const EventsListSmallItem: FunctionComponent<Props> = ({
    event,
    onDisplayDetails,
    isSelected,
    onShowMapAppModal,
}) => {
    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const { hasClientLogo, getEventLogoComponent } = useEventClientLogo({ event });

    const { onAddMeToGuestList, loading } = useRegistrationFromEventsList({ event });

    const isVirtual = useMemo(() => event.venue.type === VenueType.VIRTUAL, [event?.venue?.type]);

    const isNationwide = useMemo(
        () => event.subtype === EventSubtype.AMBASSADOR || event.subtype === EventSubtype.TOWN_HALL,
        [event?.subtype]
    );

    const leftColumnWidth = useMemo(
        () => (isVirtual || hasClientLogo || isNationwide ? 6 : 12),
        [isVirtual, hasClientLogo, isNationwide]
    );

    const displayDetailsHandler = useCallback(() => {
        onDisplayDetails(event);
    }, [event, onDisplayDetails]);

    const onLocationNameClick = useCallback(
        () => onShowMapAppModal(event),
        [event, onShowMapAppModal]
    );

    return (
        <Card className={`${Styles.EventListItem} ${isSelected && Styles.Selected}`}>
            <Card.Body className={`p-2`}>
                <Row className={'pb-2 mx-0 d-xl-flex d-lg-flex d-md-none d-sm-none d-none'}>
                    <Col
                        xl={leftColumnWidth}
                        lg={leftColumnWidth}
                        md={leftColumnWidth}
                        sm={leftColumnWidth}
                        xs={leftColumnWidth}
                        className={`px-0 d-flex justify-content-start align-items-center`}
                    >
                        <Row className={'mx-0'}>
                            <Col className={'px-0'} xs={'auto'}>
                                <span className={GeneralStyles.Title}>{event.name}</span>
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        xl={6}
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className={`px-0 d-flex justify-content-end align-items-start`}
                    >
                        <EventBadges event={event} />
                    </Col>
                </Row>

                <Row className={'pb-2 mx-0 d-xl-none d-lg-none d-md-block d-sm-block d-block'}>
                    <Col className={`px-0 d-flex justify-content-start align-items-center`}>
                        <EventBadges event={event} rowAlignment={'justify-content-start'} />
                    </Col>
                </Row>

                <Row className={'pb-2 mx-0 d-xl-none d-lg-none d-md-block d-sm-block d-block'}>
                    <Col className={`px-1 d-flex justify-content-start align-items-center`}>
                        <span className={GeneralStyles.Title}>{event.name}</span>
                    </Col>
                </Row>

                {event.description && (
                    <Row className={'pb-2 mx-0'}>
                        <Col className={'px-1'}>
                            <span className={GeneralStyles.InnerText}>{event.description}</span>
                        </Col>
                    </Row>
                )}

                <EventLocation event={event} onClick={onLocationNameClick} />

                <EventTime event={event} />

                <Row className={'pb-1 mx-0'}>
                    <Col className={'d-flex justify-content-end px-1'}>
                        <Button
                            type={ButtonType.LIGHT_GRAY_BLUE}
                            onClick={displayDetailsHandler}
                            customClassName={`px-4 py-2 ${GeneralStyles.RegisterButton}`}
                            disabled={loading}
                        >
                            {getRegistrationButtonLabel(event)}
                        </Button>

                        {authenticatedUser && !event.isAuthenticatedUserRegistered && (
                            <>
                                <span className={'px-1'} />

                                <Button
                                    type={ButtonType.LIGHT_BLUE}
                                    onClick={onAddMeToGuestList}
                                    customClassName={`px-4 py-2 ${GeneralStyles.RegisterButton}`}
                                    disabled={loading}
                                >
                                    <div
                                        className={`${GeneralStyles.RegisterButtonContent} d-flex align-items-center`}
                                    >
                                        <span className={'ps-2'}>Add me to guest list</span>
                                    </div>
                                </Button>
                            </>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
