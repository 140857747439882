import React, { FunctionComponent, useMemo } from 'react';
import { LogoClient } from './logo-client';
import { useQueryParams } from '@/utilities/use-query-params';
import Logo from '@/assets/images/logo.png';
import Styles from './styles.module.scss';

export const Navbar: FunctionComponent = () => {
    const { getQueryParameter } = useQueryParams();

    const clients: string[] = useMemo(() => {
        return getQueryParameter('client')?.toLowerCase().split(';') || [];
    }, [getQueryParameter]);

    return (
        <div
            className={`m-0 d-flex align-items-center justify-content-between ${Styles.NavContainer}`}
        >
            <div className={`d-flex align-items-center px-0 ${Styles.SiteLogoCon}`}>
                <img src={Logo} alt="wider-circle-logo" />
            </div>
            <div className={`d-flex align-items-center ${Styles.NavClientLogoCon}`}>
                <LogoClient client={clients.length === 1 ? clients[0] : ''} />
            </div>
        </div>
    );
};
