import React, { FunctionComponent, useCallback } from 'react';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { EventModel } from '@/events/models';
import Styles from './Styles.module.scss';
import GeneralStyles from '../general-styles.module.scss';
import { Button, ButtonType } from '@/components/form-controls/button';
import { getRegistrationButtonLabel } from '@/events/pages/all-events/utils';
import { EventLocation } from '@/events/components/event-location';
import { EventTime } from '@/events/components/event-time';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { useRegistrationFromEventsList } from '@/events/hooks/use-registration-from-events-list';
import { EventBadges } from '@/events/components/event-badges';

type Props = {
    event: EventModel;
    onDisplayDetails: (event: EventModel) => void;
    isSelected: boolean;
    onShowMapAppModal: (event: EventModel) => void;
};

export const EventsListLargeItem: FunctionComponent<Props> = ({
    event,
    onDisplayDetails,
    isSelected,
    onShowMapAppModal,
}) => {
    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const { onAddMeToGuestList, loading } = useRegistrationFromEventsList({ event });

    const displayDetailsHandler = useCallback(() => {
        onDisplayDetails(event);
    }, [event, onDisplayDetails]);

    const onLocationNameClick = useCallback(
        () => onShowMapAppModal(event),
        [event, onShowMapAppModal]
    );

    return (
        <Card className={`${Styles.EventListItem} ${isSelected && Styles.Selected}`}>
            <Card.Body className={Styles.EventListItemBody}>
                <Row className={'mx-0'}>
                    <Col
                        xl={4}
                        lg={4}
                        md={4}
                        className={`${Styles.ImageContainer} d-flex align-items-start justify-content-start py-3`}
                        style={{
                            backgroundImage: `url(${event.imageUrl})`,
                        }}
                    >
                        <EventBadges event={event} rowAlignment={'justify-content-start'} />
                    </Col>

                    <Col xl={8} lg={8} md={8} className={'px-0'}>
                        <div className={`d-flex`}>
                            <div className={`px-3 w-100 h-100`}>
                                <Row className={'pb-2 mx-0'}>
                                    <Col className={'px-1'}>
                                        <span className={GeneralStyles.Title}>{event.name}</span>
                                    </Col>
                                </Row>

                                {event.description && (
                                    <Row className={'pb-2 mx-0'}>
                                        <Col className={'px-1'}>
                                            <span className={GeneralStyles.InnerText}>
                                                {event.description}
                                            </span>
                                        </Col>
                                    </Row>
                                )}

                                <EventLocation event={event} onClick={onLocationNameClick} />

                                <EventTime event={event} />

                                <Row className={'pb-1 mx-0'}>
                                    <Col className={'d-flex justify-content-end px-1'}>
                                        <Button
                                            type={ButtonType.LIGHT_GRAY_BLUE}
                                            onClick={displayDetailsHandler}
                                            customClassName={`px-4 py-2 ${GeneralStyles.RegisterButton}`}
                                            disabled={loading}
                                        >
                                            {getRegistrationButtonLabel(event)}
                                        </Button>

                                        {authenticatedUser &&
                                            !event.isAuthenticatedUserRegistered && (
                                                <>
                                                    <span className={'px-1'} />

                                                    <Button
                                                        type={ButtonType.LIGHT_BLUE}
                                                        onClick={onAddMeToGuestList}
                                                        customClassName={`px-4 py-2 ${GeneralStyles.RegisterButton}`}
                                                        disabled={loading}
                                                    >
                                                        <div
                                                            className={`${GeneralStyles.RegisterButtonContent} d-flex align-items-center`}
                                                        >
                                                            <span className={'ps-2'}>
                                                                Add me to guest list
                                                            </span>
                                                        </div>
                                                    </Button>
                                                </>
                                            )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
