import React, {
    ChangeEvent,
    FunctionComponent,
    KeyboardEvent,
    useCallback,
    useMemo,
    useState,
} from 'react';
import Styles from './Styles.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TooltipContent } from './tooltip-content';
import { TranslationService } from '@/services/translation';
import './tooltip.css';
import { IMAGES } from '@/assets/images';
import { SearchIcon } from '@/components/icons/search-icon';

export type InputBoxUIOptions = {
    containerHeight?: number | string;
    containerWidth?: number | string;
};

type Props = {
    label: string;
    placeholder?: string;
    type: string;
    value: string | undefined;
    onChange: (value: string) => void;
    onEnter?: () => void;
    onRightIconClick?: () => void;
    isValid: boolean;
    showRightIcon?: boolean;
    uiOptions?: InputBoxUIOptions;
    hideTooltipCompletely?: boolean;
    iconColor?: string;
};

export const SearchBox: FunctionComponent<Props> = ({
    label,
    placeholder,
    type,
    value,
    onChange,
    onEnter = () => null,
    onRightIconClick = () => null,
    isValid,
    showRightIcon = false,
    uiOptions = {},
    hideTooltipCompletely = false,
    iconColor,
}) => {
    const [focused, setFocused] = useState(false);
    const [isTooltipDisplayed, displayTooltip] = useState(false);

    const inputClassName: string = useMemo(
        () => `w-100  ${!isValid && Styles.InputInvalid} h-100 px-2`,
        [isValid]
    );

    const changeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { value: newValue },
            } = event;

            onChange(newValue);
        },
        [onChange]
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key?.toLowerCase() === 'enter') {
                onEnter();
                setFocused(false);
            }
        },
        [onEnter]
    );

    const onFocus = useCallback(() => {
        setFocused(true);
        displayTooltip(true);
    }, []);

    const onBlur = useCallback(() => {
        setFocused(false);
        displayTooltip(false);
    }, []);

    const onTooltipClose = useCallback(() => {
        displayTooltip(false);
    }, [displayTooltip]);

    const renderTooltip = (props: any) => {
        return (
            <Tooltip {...props} id="tooltip-container">
                <TooltipContent
                    label={TranslationService.getInstance().getPhrase(
                        'filters.bar.search.tooltip.label'
                    )}
                    onClose={onTooltipClose}
                />
            </Tooltip>
        );
    };

    return (
        <div
            className={`${Styles.Input} d-flex flex-column align-items-start w-100`}
            style={{ height: uiOptions.containerHeight || 68 }}
        >
            {label && (
                <label htmlFor="first_name" className={`${!isValid && Styles.InvalidLabel} mb-1`}>
                    {label}
                </label>
            )}

            <div
                className={`${Styles.InputContainer} ${
                    focused && Styles.Focused
                } w-100 px-3 py-2 d-flex align-items-center`}
            >
                <SearchIcon strokeColor={iconColor} />

                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                    show={!hideTooltipCompletely && !showRightIcon && isTooltipDisplayed}
                >
                    <input
                        type={type}
                        className={inputClassName}
                        placeholder={placeholder}
                        onChange={changeHandler}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={value}
                    />
                </OverlayTrigger>

                {showRightIcon && value && (
                    <div
                        className={`${Styles.RightIcon} d-flex align-items-center`}
                        onClick={onRightIconClick}
                    >
                        <span className={'pe-1'}>Search</span>
                        <img alt="search-events-box-icon" src={IMAGES.LineArrowRightIcon} />
                    </div>
                )}
            </div>
        </div>
    );
};
