import React, { FunctionComponent, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Styles from '@/events/components/events-filtering/Styles.module.scss';
import Row from 'react-bootstrap/Row';
import { EventsFiltering } from '@/events/components/events-filtering/use-events-filtering-operations';
import { FiltersButton } from '@/events/components/events-filtering/components/filters-button';
import { TranslationService } from '@/services/translation';
import { SearchBox } from '@/events/components/events-filtering/components/search-box';

type Props = {
    eventsFilteringOperations: EventsFiltering;
    onFilterButtonClick: () => void;
};

export const MobileFiltering: FunctionComponent<Props> = ({
    eventsFilteringOperations,
    onFilterButtonClick,
}) => {
    return (
        <Row className={`mx-0 pt-2 ${Styles.MobileFiltering} h-100`}>
            <Col md={3} sm={4} xs={4} className={`px-0 d-flex align-items-center`}>
                <FiltersButton onFilterButtonClick={onFilterButtonClick} />
            </Col>
            <Col md={9} sm={8} xs={8} className={`pe-0 d-flex align-items-center`}>
                <SearchBox
                    label={''}
                    placeholder={TranslationService.getInstance().getPhrase(
                        'filters.bar.search.placeholder'
                    )}
                    type="text"
                    value={eventsFilteringOperations.values.searchText}
                    onChange={eventsFilteringOperations.handlers.searchTextChangeHandler}
                    onEnter={eventsFilteringOperations.handlers.searchTextEnterKeyPressHandler}
                    onRightIconClick={eventsFilteringOperations.handlers.searchTextClickHandler}
                    isValid={true}
                    uiOptions={{
                        containerHeight: '100%',
                    }}
                    showRightIcon={
                        eventsFilteringOperations.values.searchText !== undefined &&
                        eventsFilteringOperations.values.searchText.length > 2
                    }
                />
            </Col>
        </Row>
    );
};
