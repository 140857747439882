import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonType } from '@/components/form-controls/button';
import { useContainerDimensions } from '@/hooks/use-container-dimensions';
import { RadioButton } from '@/components/form-controls/radio-button-v2';
import { useClickListener } from '../common-control-hooks/use-click-listener';

export type CustomUIOptions = {
    top?: number;
};

export interface RadioButtonsOption {
    value: string;
    label: string;
}

type Props = {
    id: string;
    label: string;
    preselectedOption: string;
    options: RadioButtonsOption[];
    onChange: (option: RadioButtonsOption) => void;
    firstOptionRepresentsAllOptions: boolean;
    uiOptions?: CustomUIOptions;
};

export const RadioButtonsGroup: FunctionComponent<Props> = ({
    label,
    preselectedOption,
    options,
    onChange,
    firstOptionRepresentsAllOptions,
    uiOptions = {},
}) => {
    const [optionSelected, setOptionSelected] = useState<string | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const containerRef = useRef(null);
    const menuContainerRef = useRef(null);

    const { width: containerWidth } = useContainerDimensions(containerRef);

    const labelOptionSelected = useMemo(() => {
        const optionsFound = options.find((option) => preselectedOption === option.value);
        return optionsFound?.label;
    }, [preselectedOption, options]);

    const checkboxValues = useMemo(() => {
        return options.map((item) => optionSelected === item.value);
    }, [options, optionSelected]);

    const menuCurtainHeight = useMemo(
        () => (open ? `calc(75px ${options.map(() => '+ 0px').join(' ')})` : 0),
        [open, options]
    );

    const changeHandler = useCallback((option: string) => {
        setOptionSelected(option);
    }, []);

    const clearHandler = useCallback(() => {
        setOptionSelected(options[0].value);
        onChange(options[0]);
        setOpen(false);
    }, [onChange, options, setOptionSelected]);

    const submitHandler = useCallback(() => {
        const optionsFound = options.find((option) => optionSelected === option.value);
        onChange(optionsFound as RadioButtonsOption);
        setOpen(false);
    }, [options, onChange, optionSelected]);

    const clickHandler = useCallback(() => {
        setOpen(!open);

        if (preselectedOption !== optionSelected) {
            setOptionSelected(preselectedOption);
        }
    }, [open, setOpen, preselectedOption, optionSelected]);

    const getCheckboxesList = useCallback(() => {
        const copyOfCheckboxValues = firstOptionRepresentsAllOptions
            ? checkboxValues.slice(1)
            : checkboxValues;

        const copyOfOptions = firstOptionRepresentsAllOptions ? options.slice(1) : options;

        return copyOfCheckboxValues.map((checkboxValue, index) => (
            <div key={copyOfOptions[index].value}>
                <RadioButton
                    id={copyOfOptions[index].value}
                    label={copyOfOptions[index].label}
                    value={copyOfOptions[index].value}
                    customContainerClassName={`${Styles.Option}`}
                    customLabelClassName={Styles.label}
                    isChecked={checkboxValue}
                    onChange={changeHandler}
                />
            </div>
        ));
    }, [firstOptionRepresentsAllOptions, checkboxValues, options, changeHandler]);

    useClickListener(containerRef, menuContainerRef, clickHandler, open);

    useEffect(() => {
        if (preselectedOption) {
            setOptionSelected(preselectedOption);
        }
    }, [preselectedOption, setOptionSelected]);

    return (
        <div
            ref={containerRef}
            className={`${Styles.RadioButtonsContainer} d-flex flex-column align-items-start justify-content-center`}
        >
            <p className={`${Styles.label} mb-1`}>{label}</p>

            <div
                className={`${Styles.RadioButtons} d-flex justify-content-between align-items-center w-100 h-100`}
                onClick={clickHandler}
            >
                <div className={`${Styles.RadioButtonsSelectedValue}`}>
                    <span>{labelOptionSelected}</span>
                </div>

                <img
                    className={`${open ? Styles.ArrowUp : Styles.ArrowDown}`}
                    alt={'arrow-down-icon'}
                    src={IMAGES.ArrowDownIcon}
                />
            </div>

            <div
                className={`${Styles.MenuCurtain} ${open && Styles.ScrollDown}`}
                style={{
                    width: containerWidth + 50,
                    height: menuCurtainHeight,
                }}
            />

            <div
                ref={menuContainerRef}
                className={`${Styles.RadioButtonsOptionsContainer} ${open && Styles.Opened}`}
                style={{ width: containerWidth + 120, top: uiOptions.top || 75 }}
            >
                {firstOptionRepresentsAllOptions && (
                    <div key={options[0].value}>
                        <RadioButton
                            id={options[0].value}
                            label={options[0].label}
                            value={options[0].value}
                            customContainerClassName={`${Styles.Option} ${Styles.general}`}
                            customLabelClassName={Styles.label}
                            isChecked={checkboxValues[0]}
                            onChange={changeHandler}
                        />
                    </div>
                )}
                <div className={`${Styles.OptionsList}`}>{getCheckboxesList()}</div>

                <Row className={`mx-0 pb-2 pt-2 ${Styles.Actions}`}>
                    <Col className={`d-flex justify-content-evenly`}>
                        <p
                            className={`${Styles.ClearAction} py-2 w-100 m-0 d-flex align-items-center`}
                            onClick={clearHandler}
                        >
                            Clear
                        </p>

                        <span className={'px-2'} />

                        <Button
                            type={ButtonType.PRIMARY_BLUE_1}
                            onClick={submitHandler}
                            customClassName={`py-2 w-100`}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
