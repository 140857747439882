import React, { FunctionComponent, useCallback } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { MemberSearchResultModel } from '@/contacts/models';
import { RadioButton } from '@/components/form-controls/radio-button';

type Props = {
    member: MemberSearchResultModel;
    onSelect: (newMember: MemberSearchResultModel) => void;
    selectedMemberId: string | undefined;
    index: number;
    moveToStepFour: () => void;
    isLastMemberOnTheList: boolean;
};

export const PotentialMember: FunctionComponent<Props> = ({
    member,
    onSelect,
    selectedMemberId,
    index,
    moveToStepFour,
    isLastMemberOnTheList,
}) => {
    const changeHandler = useCallback(() => {
        onSelect(member);
    }, [member, onSelect]);

    return (
        <Row className={'pb-3 pt-2 mx-0'} key={member.id}>
            <Col
                className={
                    'px-3 col-12 d-flex align-items-center justify-content-start justify-content-sm-center flex-wrap'
                }
            >
                <RadioButton
                    id={member.id}
                    value={member.id}
                    label={`${member.firstName} ${member.lastName}`}
                    isChecked={selectedMemberId === member.id}
                    onChange={changeHandler}
                />
            </Col>
            {isLastMemberOnTheList && (
                <Col className="col-12 mt-3 px-4">
                    <p
                        onClick={moveToStepFour}
                        className="d-flex justify-content-start justify-content-sm-center align-items-center h-100 text-main text-decoration-underline cursor-pointer"
                    >
                        Not you?
                    </p>
                </Col>
            )}
        </Row>
    );
};
