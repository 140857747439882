import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { DropdownOption } from 'src/components/form-controls/dropdown';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import { EVENT_LANGUAGES } from '@/constants/languages';
import { EVENT_TYPES } from '@/constants/event-types';
import { US_STATES } from '@/constants/us-states';
import { TranslationService } from '@/services/translation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { ClientModel } from '@/clients/models';
import { QueryParameters } from '@/authentication/reducer/state';
import { authenticationActions } from '@/authentication/reducer';
import { eventsActions } from '@/events/reducer';
import { EventLanguage, EventModel, EventType } from '@/events/models';
import { DEFAULT_OPTION, EventFilters } from '@/events/reducer/types';
import { ZIP_CODE_LOCAL_STORAGE_KEY } from '@/constants/local-storage';

export type EventsFiltering = {
    options: {
        states: DropdownOption[];
        languages: DropdownOption[];
        eventTypes: DropdownOption[];
        clients: DropdownOption[];
    };
    values: {
        defaultOption: string;
        onlyRegisteredEvents: boolean;
        searchText: string | undefined;
    };
    handlers: {
        onlyRegisteredChangeHandler: (newValue: boolean) => void;
        statesChangeHandler: (statesSelected: DropdownOption[]) => void;
        languagesChangeHandler: (languagesSelected: DropdownOption[]) => void;
        eventTypesChangeHandler: (eventTypesSelected: DropdownOption[]) => void;
        clientChangeHandler: (clientSelected: DropdownOption) => void;
        searchTextEnterKeyPressHandler: () => void;
        searchTextClickHandler: () => void;
        searchTextChangeHandler: (searchText: string) => void;
        zipcodeChangeHandler: (zipcode: string) => void;
        allOptionsChangeHandler: (
            statesSelected: DropdownOption[],
            languagesSelected: DropdownOption[],
            eventTypesSelected: DropdownOption[]
        ) => void;
        resetClickHandler: () => void;
        resetStatesHandler: () => void;
        resetLanguagesHandler: () => void;
        resetEventTypesHandler: () => void;
        resetClientHandler: () => void;
    };
};

export const useEventsFilteringOperations = (): EventsFiltering => {
    const clients: ClientModel[] = useSelector<RootState, ClientModel[]>(
        (state) => state.clients.fetchedClients
    );

    const queryParameters: QueryParameters = useSelector<RootState, QueryParameters>(
        (state) => state.authentication.queryParameters
    );

    const usStatesFromState: string[] = useSelector<RootState, string[]>(
        (state) => state.events.usStates
    );

    const filters: EventFilters = useSelector<RootState, EventFilters>(
        (state) => state.events.filters
    );

    const hasZipcodeBeingInput = useSelector<RootState, boolean>(
        (state) => state.events.hasZipcodeBeingInput
    );

    const hasFirstCorrectZipcodeBeenInputted = useSelector<RootState, boolean>(
        (state) => state.events.hasFirstCorrectZipcodeBeenInputted
    );

    const [searchText, setSearchText] = useState<string | undefined>(undefined);

    const dispatch = useDispatch();

    const stateOptions = useMemo(() => {
        const capitalizedStates: string[] = usStatesFromState.map((item) => item.toUpperCase());

        const statesFiltered = US_STATES.filter(({ abbreviation }) =>
            capitalizedStates.includes(abbreviation)
        );

        return [
            {
                value: 'ALL',
                label: 'All',
            },
            ...statesFiltered.map(
                (item): DropdownOption => ({
                    value: item.abbreviation,
                    label: _.startCase(item.name.toLowerCase()),
                })
            ),
        ];
    }, [usStatesFromState]);

    const languageOptions = useMemo(() => {
        return [
            {
                value: 'ALL',
                label: 'All',
            },
            {
                value: EVENT_LANGUAGES[0].value,
                label: TranslationService.getInstance().getPhrase(
                    'filters.bar.language.options.english'
                ),
            },
            {
                value: EVENT_LANGUAGES[1].value,
                label: TranslationService.getInstance().getPhrase(
                    'filters.bar.language.options.spanish'
                ),
            },
        ];
    }, []);

    const eventTypeOptions = useMemo(() => {
        return [
            {
                value: 'ALL',
                label: 'All',
            },
            ...EVENT_TYPES.map(
                (item): DropdownOption => ({
                    value: item.value,
                    label: item.label,
                })
            ),
        ];
    }, []);

    const clientOptions = useMemo(() => {
        return [
            {
                value: 'ALL',
                label: 'All health plans',
            },
            ...clients.map(
                (item): DropdownOption => ({
                    value: item.parameter,
                    label: item.label,
                })
            ),
        ];
    }, [clients]);

    const [onlyRegisteredEvents, showOnlyRegisteredEvents] = useState<boolean>(false);

    const [client, setClient] = useState<string>(
        queryParameters.clients.length > 0 ? queryParameters.clients[0] : DEFAULT_OPTION.value
    );

    const statesChangeHandler = useCallback(
        (statesSelected: DropdownOption[]) => {
            const newStates = statesSelected.map((option) => option.value);
            dispatch(eventsActions.updateStatesFilter(newStates));

            Analytics.getInstance().fireEvent(
                AnalyticsEventName.UsStateFilterChange,
                AnalyticsEventCategory.EventsFiltering,
                `US State filter changed to: ${newStates.join(', ')}`
            );
        },
        [dispatch, eventsActions.updateStatesFilter]
    );

    const languagesChangeHandler = useCallback(
        (languagesSelected: DropdownOption[]) => {
            const newLanguages = languagesSelected.map((option) => option.value);
            dispatch(eventsActions.updateLanguagesFilter(newLanguages as EventLanguage[]));

            Analytics.getInstance().fireEvent(
                AnalyticsEventName.LanguageFilterChange,
                AnalyticsEventCategory.EventsFiltering,
                `Language filter changed to: ${newLanguages.join(', ')}`
            );
        },
        [dispatch, eventsActions.updateLanguagesFilter]
    );

    const eventTypesChangeHandler = useCallback(
        (eventTypesSelected: DropdownOption[]) => {
            const newEventTypes = eventTypesSelected.map((option) => option.value);

            dispatch(eventsActions.updateVenueTypesFilter(newEventTypes as EventType[]));

            Analytics.getInstance().fireEvent(
                AnalyticsEventName.EventTypeFilterChange,
                AnalyticsEventCategory.EventsFiltering,
                `Event type filter changed to: ${newEventTypes.join(', ')}`
            );
        },
        [dispatch, eventsActions.updateVenueTypesFilter]
    );

    const clientChangeHandler = useCallback(
        (clientSelected: DropdownOption) => {
            const newClients =
                clientSelected.value === DEFAULT_OPTION.value ? [] : [clientSelected.value];

            dispatch(
                authenticationActions.storeQueryParameters({
                    ...queryParameters,
                    clients: newClients,
                })
            );
            dispatch(eventsActions.updateClientFilter(clientSelected.value));

            Analytics.getInstance().fireEvent(
                AnalyticsEventName.EventTypeFilterChange,
                AnalyticsEventCategory.EventsFiltering,
                `Health Provider filter changed to: ${clientSelected.label}`
            );
        },
        [dispatch, authenticationActions.storeQueryParameters, eventsActions.updateClientFilter]
    );

    const searchTextClickHandler = useCallback(() => {
        if (!searchText) {
            return;
        }

        dispatch(eventsActions.updateSearchTextFilter(searchText));

        Analytics.getInstance().fireEvent(
            AnalyticsEventName.SearchTextFilterChange,
            AnalyticsEventCategory.EventsFiltering,
            `Search text filter changed to: ${searchText}`
        );
    }, [searchText]);

    const searchTextEnterKeyPressHandler = useCallback(() => {
        if (!searchText) {
            return;
        }

        dispatch(eventsActions.updateSearchTextFilter(searchText));

        Analytics.getInstance().fireEvent(
            AnalyticsEventName.SearchTextFilterChange,
            AnalyticsEventCategory.EventsFiltering,
            `Search text filter changed to: ${searchText}`
        );
    }, [searchText]);

    const searchTextChangeHandler = useCallback(
        (newSearchText: string) => {
            setSearchText(newSearchText);

            if (filters.searchText && newSearchText.length <= 2) {
                dispatch(eventsActions.updateSearchTextFilter(''));
            }
        },
        [dispatch, filters.searchText]
    );

    const zipcodeChangeHandler = useCallback(
        (zipcode: string) => {
            dispatch(eventsActions.updateZipcodeFilter(zipcode));

            if (zipcode.length === 5) {
                localStorage.setItem(ZIP_CODE_LOCAL_STORAGE_KEY, zipcode);
            }

            if (!hasZipcodeBeingInput && zipcode.length === 5) {
                dispatch(eventsActions.updateHasZipcodeBeenInputted(true));
            }
        },
        [hasZipcodeBeingInput]
    );

    const onlyRegisteredChangeHandler = useCallback((newValue: boolean) => {
        Analytics.getInstance().fireEvent(
            AnalyticsEventName.RegisteredOnlyFilterChange,
            AnalyticsEventCategory.EventsFiltering,
            `Show only registered events filter changed to: ${newValue}`
        );
    }, []);

    const allOptionsChangeHandler = useCallback(
        (
            statesSelected: DropdownOption[],
            languagesSelected: DropdownOption[],
            eventTypesSelected: DropdownOption[]
        ) => {
            const newStates = statesSelected.map((option) => option.value);
            const newLanguages = languagesSelected.map((option) => option.value);
            const newEventTypes = eventTypesSelected.map((option) => option.value);

            dispatch(eventsActions.updateStatesFilter(newStates));
            dispatch(eventsActions.updateLanguagesFilter(newLanguages));
            dispatch(eventsActions.updateVenueTypesFilter(newEventTypes));
        },
        []
    );

    const resetClickHandler = useCallback(() => {
        dispatch(eventsActions.resetFilters());

        Analytics.getInstance().fireEvent(
            AnalyticsEventName.ResetFiltersButtonClick,
            AnalyticsEventCategory.EventsFiltering,
            'Reset button clicked'
        );
    }, [dispatch, eventsActions.resetFilters]);

    const resetStatesHandler = useCallback(() => {
        dispatch(eventsActions.updateStatesFilter([DEFAULT_OPTION.value]));

        Analytics.getInstance().fireEvent(
            AnalyticsEventName.UsStateFilterChange,
            AnalyticsEventCategory.EventsFiltering,
            `US State filter changed to: ${DEFAULT_OPTION.value}`
        );
    }, [dispatch, eventsActions.updateStatesFilter]);

    const resetLanguagesHandler = useCallback(() => {
        dispatch(eventsActions.updateLanguagesFilter([DEFAULT_OPTION.value]));

        Analytics.getInstance().fireEvent(
            AnalyticsEventName.LanguageFilterChange,
            AnalyticsEventCategory.EventsFiltering,
            `Language filter changed to: ${DEFAULT_OPTION.value}`
        );
    }, [dispatch, eventsActions.updateLanguagesFilter]);

    const resetEventTypesHandler = useCallback(() => {
        dispatch(eventsActions.updateVenueTypesFilter([DEFAULT_OPTION.value]));

        Analytics.getInstance().fireEvent(
            AnalyticsEventName.EventTypeFilterChange,
            AnalyticsEventCategory.EventsFiltering,
            `Event type filter changed to: ${DEFAULT_OPTION.value}`
        );
    }, [dispatch, eventsActions.updateVenueTypesFilter]);

    const resetClientHandler = useCallback(() => {
        dispatch(eventsActions.updateClientFilter(DEFAULT_OPTION.value));

        Analytics.getInstance().fireEvent(
            AnalyticsEventName.EventTypeFilterChange,
            AnalyticsEventCategory.EventsFiltering,
            `Health Provider filter changed to: ${DEFAULT_OPTION.value}`
        );
    }, [dispatch, eventsActions.updateClientFilter]);

    useEffect(() => {
        if (!queryParameters.clients.includes(client)) {
            setClient(queryParameters.clients[0]);
        }
    }, [queryParameters, client]);

    useEffect(() => {
        if (hasZipcodeBeingInput && hasFirstCorrectZipcodeBeenInputted) {
            dispatch(eventsActions.updateVenueTypesFilter([DEFAULT_OPTION.value]));
        }
    }, [hasZipcodeBeingInput, hasFirstCorrectZipcodeBeenInputted]);

    return {
        options: {
            states: stateOptions,
            languages: languageOptions,
            eventTypes: eventTypeOptions,
            clients: clientOptions,
        },
        values: {
            defaultOption: DEFAULT_OPTION.value,
            onlyRegisteredEvents,
            searchText,
        },
        handlers: {
            onlyRegisteredChangeHandler,
            statesChangeHandler,
            languagesChangeHandler,
            eventTypesChangeHandler,
            clientChangeHandler,
            searchTextClickHandler,
            searchTextEnterKeyPressHandler,
            searchTextChangeHandler,
            zipcodeChangeHandler,
            resetClickHandler,
            resetStatesHandler,
            resetLanguagesHandler,
            resetEventTypesHandler,
            resetClientHandler,
            allOptionsChangeHandler,
        },
    };
};
