import { RootState } from '@/store';
import { connect, ConnectedProps } from 'react-redux';
import { eventsActions, fetchEvents, fetchEventsForMap } from '@/events/reducer';
import { authenticationActions } from '@/authentication/reducer';

const mapState = (state: RootState) => ({
    authenticatedUser: state.authentication.authenticatedUser,
    queryParameters: state.authentication.queryParameters,
    filteredEventsByUser: state.events.filteredEventsByUser,
    fetchedEvents: state.events.fetchedEvents,
    fetchedEventsForMap: state.events.fetchedEventsForMap,
    isLoadingEvents: state.events.isLoadingEventsForMap,
    isLoadingClients: state.clients.isLoadingClients,
});

const actionsCreator = {
    storeQueryParameters: authenticationActions.storeQueryParameters,
    fetchEvents,
    fetchEventsForMap,
    updateEventIdsFilter: eventsActions.updateEventIdsFilter,
};

export const AllEventsPageConnector = connect(mapState, actionsCreator);
export type AllEventsPageConnectorProps = ConnectedProps<typeof AllEventsPageConnector>;
