import React from 'react';

const InfoIcon = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="16"
                height="16"
                transform="translate(0 0.885803)"
                fill="white"
                style={{ mixBlendMode: 'multiply' }}
            />
            <path
                d="M8.5 11.8858V7.88581H6.5V8.88581H7.5V11.8858H6V12.8858H10V11.8858H8.5Z"
                fill="#004E9C"
            />
            <path
                d="M8 4.88581C7.85167 4.88581 7.70666 4.92979 7.58333 5.0122C7.45999 5.09461 7.36386 5.21175 7.30709 5.34879C7.25033 5.48584 7.23548 5.63664 7.26441 5.78212C7.29335 5.92761 7.36478 6.06125 7.46967 6.16614C7.57456 6.27103 7.7082 6.34246 7.85369 6.3714C7.99917 6.40033 8.14997 6.38548 8.28701 6.32872C8.42406 6.27195 8.54119 6.17582 8.6236 6.05248C8.70602 5.92915 8.75 5.78414 8.75 5.63581C8.75 5.43689 8.67098 5.24613 8.53033 5.10548C8.38968 4.96482 8.19892 4.88581 8 4.88581Z"
                fill="#004E9C"
            />
            <path
                d="M8 15.8858C6.61553 15.8858 5.26216 15.4753 4.11101 14.7061C2.95987 13.9369 2.06266 12.8437 1.53285 11.5646C1.00303 10.2855 0.86441 8.87804 1.13451 7.52017C1.4046 6.16231 2.07129 4.91503 3.05026 3.93606C4.02922 2.95709 5.2765 2.29041 6.63437 2.02031C7.99224 1.75021 9.3997 1.88884 10.6788 2.41865C11.9579 2.94846 13.0511 3.84567 13.8203 4.99681C14.5895 6.14796 15 7.50134 15 8.88581C15 10.7423 14.2625 12.5228 12.9497 13.8356C11.637 15.1483 9.85652 15.8858 8 15.8858ZM8 2.88581C6.81332 2.88581 5.65328 3.2377 4.66658 3.89699C3.67989 4.55628 2.91085 5.49335 2.45673 6.58971C2.0026 7.68606 1.88378 8.89246 2.11529 10.0563C2.3468 11.2202 2.91825 12.2893 3.75736 13.1284C4.59648 13.9676 5.66558 14.539 6.82946 14.7705C7.99335 15.002 9.19975 14.8832 10.2961 14.4291C11.3925 13.975 12.3295 13.2059 12.9888 12.2192C13.6481 11.2325 14 10.0725 14 8.88581C14 7.29451 13.3679 5.76838 12.2426 4.64317C11.1174 3.51795 9.5913 2.88581 8 2.88581Z"
                fill="#004E9C"
            />
        </svg>
    );
};

export default InfoIcon;
