import React, { FunctionComponent, useMemo } from 'react';
import { EventModel, EventType } from '@/events/models';
import Styles from './Styles.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TranslationService } from '@/services/translation';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { UpcomingWeeklyEvent } from '@/events/components/upcoming-weekly-event';

type Props = {
    handleSelectionOfEvent: (event: EventModel) => void;
};

export const OtherEvents: FunctionComponent<Props> = ({ handleSelectionOfEvent }) => {
    const selectedEvent = useSelector<RootState, EventModel | undefined>(
        (state) => state.events.selectedEvent
    );

    const fetchedUpcomingWeeklyEvents = useSelector<RootState, EventModel[]>(
        (state) => state.events.fetchedUpcomingWeeklyEvents
    );

    const fetchedEvents = useSelector<RootState, EventModel[]>(
        (state) => state.events.fetchedEvents
    );

    const relatedEvents = useMemo(() => {
        if (!selectedEvent) {
            return [];
        }

        if (selectedEvent.type === EventType.KICKOFF) {
            return [...fetchedUpcomingWeeklyEvents].sort((item1, item2) => {
                return item1.startDateInMilliseconds < item2.startDateInMilliseconds ? -1 : 1;
            });
        }

        const eventsFiltered = fetchedEvents.filter((item) => item.id !== selectedEvent.id);

        return eventsFiltered
            .sort((item1, item2) =>
                item1.startDateInMilliseconds < item2.startDateInMilliseconds ? -1 : 1
            )
            .slice(0, 2);
    }, [fetchedUpcomingWeeklyEvents, fetchedEvents, selectedEvent]);

    const title = useMemo(() => {
        const firstPart =
            selectedEvent?.type === EventType.KICKOFF
                ? TranslationService.getInstance().getPhrase(
                      'registration.other_events_section.series_of_events.title'
                  )
                : TranslationService.getInstance().getPhrase(
                      'registration.other_events_section.related_events.title'
                  );

        const secondPart =
            selectedEvent?.type === EventType.KICKOFF ? ` (${relatedEvents.length})` : '';

        return `${firstPart}${secondPart}`;
    }, [selectedEvent, relatedEvents]);

    if (relatedEvents.length === 0) {
        return null;
    }

    return (
        <div className={`px-0 ${Styles.OtherEvents} pt-4`}>
            <Row className={`mx-0 pb-2 ${Styles.RelatedEventSecTitle}`}>
                <Col lg={7} md={6} xs={12} className={'pb-1 px-0 d-flex align-items-center'}>
                    <h3 className="m-0">{title}</h3>
                </Col>
            </Row>
            <div className={`${Styles.RelatedEventsCon}`}>
                <Row className={`mx-0 pt-3`}>
                    {relatedEvents.map((item, index) => (
                        <Col
                            key={item.id}
                            lg={6}
                            md={6}
                            xs={12}
                            className={`${
                                index % 2 === 0
                                    ? Styles.RecommendedEventRightDivider
                                    : Styles.RecommendedEventLeftDivider
                            } pb-3`}
                        >
                            <UpcomingWeeklyEvent
                                event={item}
                                onViewDetails={handleSelectionOfEvent}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
            {selectedEvent?.type === EventType.KICKOFF && relatedEvents.length > 0 && (
                <div className={`p-3 ${Styles.RelatedEventsNote}`}>
                    <p className="m-0">
                        {TranslationService.getInstance().getPhrase(
                            'registration.other_events_section.series_of_events.note'
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};
