import { useCallback, useState } from 'react';
import { useQueryParams } from '@/utilities/use-query-params';
import { ClientModel } from '@/clients/models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { TranslationService } from '@/services/translation';
import { eventsActions } from '@/events/reducer';
import { AuthenticatedUserModel } from '@/authentication/models';
import { EventFilters } from '@/events/reducer/types';

type Params = {
    updateQueryParametersInsideState: (clientName: string) => void;
};

export const useInitializationFromUrl = ({ updateQueryParametersInsideState }: Params) => {
    const [clientFilterInitialized, setClientFilterInitialized] = useState(false);
    const { getQueryParameter } = useQueryParams();
    const dispatch = useDispatch();

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const clients: ClientModel[] = useSelector<RootState, ClientModel[]>(
        (state) => state.clients.fetchedClients
    );

    const authenticatedUser: AuthenticatedUserModel | undefined = useSelector<
        RootState,
        AuthenticatedUserModel | undefined
    >((state) => state.authentication.authenticatedUser);

    const getClientNamesFromUrlParameter = useCallback((): string[] => {
        const clientsParameter: string[] = getQueryParameter('client')?.split(';') || [];
        return clientsParameter.map((item) => item.toLowerCase());
    }, [getQueryParameter]);

    const updateClientFiltersFromAuthenticatedUser = useCallback(() => {
        if (authenticatedUser?.client) {
            dispatch(eventsActions.updateClientFilter(authenticatedUser?.client));
            updateQueryParametersInsideState(authenticatedUser?.client);
            TranslationService.getInstance().setClient([authenticatedUser?.client]);
        }

        setClientFilterInitialized(true);
    }, [authenticatedUser, dispatch]);

    const handleClientParamInUrl = useCallback(() => {
        const clientNamesFromUrl = getClientNamesFromUrlParameter();

        if (clientNamesFromUrl.length === 0) {
            setClientFilterInitialized(true);
            return;
        }

        const clientFound = clients.find((clientModel) =>
            clientNamesFromUrl.includes(clientModel.parameter)
        );

        if (clientFound) {
            dispatch(eventsActions.updateClientFilter(clientFound.parameter));
            updateQueryParametersInsideState(clientFound.parameter);
            TranslationService.getInstance().setClient([clientFound.parameter]);
            setClientFilterInitialized(true);
            return;
        }

        updateClientFiltersFromAuthenticatedUser();
    }, [
        getClientNamesFromUrlParameter,
        clients,
        updateClientFiltersFromAuthenticatedUser,
        dispatch,
        updateQueryParametersInsideState,
        filters.client,
    ]);

    return {
        handleClientParamInUrl,
        clientFilterInitialized,
    };
};
