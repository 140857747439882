import { httpClient } from '@/services/http-client';
import { VenueInformationApi } from '@/venues/api/types';
import { VenueLocationModel } from '@/venues/models';
import { venueInformationApiToVenueLocationModel } from '@/venues/mappers/venues-mapper';

type VenuesInformationResponse = {
    venues: VenueInformationApi[];
};

export const getVenueLocations = async (): Promise<VenueLocationModel[]> => {
    const response = await httpClient.requestFull<VenuesInformationResponse>({
        method: 'GET',
        url: '/selfserve/venues',
    });

    return response.data.venues.map(venueInformationApiToVenueLocationModel);
};
