import { connect, ConnectedProps } from 'react-redux';
import { AuthenticatedUserModel } from '@/authentication/models';
import { QueryParameters } from '@/authentication/reducer/state';
import { RootState } from '@/store';
import { authenticationActions } from '@/authentication/reducer';
import { EventPageWrapperComponent } from '@/events/pages/event-page-wrapper-component';
import { fetchClients } from '@/clients/reducer';
import { ClientModel } from '@/clients/models';
import { eventsActions } from '@/events/reducer';
import { EventModel } from '@/events/models';
import { RegistrationStatus } from '@/events/reducer/types';

type EventPageWrapperStoreProps = {
    authenticatedUser: AuthenticatedUserModel | undefined;
    queryParameters: QueryParameters | undefined;
    isLoadingClients: boolean;
    fetchedClients: ClientModel[];
    selectedEvent?: EventModel;
    registrationStatus: RegistrationStatus;
};

const mapState = (state: RootState): EventPageWrapperStoreProps => ({
    authenticatedUser: state.authentication.authenticatedUser,
    queryParameters: state.authentication.queryParameters,
    isLoadingClients: state.clients.isLoadingClients,
    fetchedClients: state.clients.fetchedClients,
    selectedEvent: state.events.selectedEvent,
    registrationStatus: state.events.registrationStatus,
});

const actionCreators = {
    storeQueryParameters: authenticationActions.storeQueryParameters,
    storeAuthenticatedUser: authenticationActions.storeAuthenticatedUser,
    fetchClients,
    updateClientFilter: eventsActions.updateClientFilter,
};

export const EventPageWrapperConnector = connect(mapState, actionCreators);
export type EventPageWrapperConnectorProps = ConnectedProps<typeof EventPageWrapperConnector>;
export const EventPageWrapper = EventPageWrapperConnector(EventPageWrapperComponent);
