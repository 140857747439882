import React, { FunctionComponent } from 'react';

import Styles from './Styles.module.scss';
import { EventModel } from '@/events/models';
import { EventDetailsImage } from '@/events/components/event-details-image';
import { VenueType } from '@/venues/models';
import { MapView } from '@/events/components/map-view';
import { EventLocation } from '@/events/components/event-location';
import { EventTime } from '@/events/components/event-time';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { EventDetailsMiscellaneous } from '@/events/components/event-details-miscellaneous';
import { OtherEvents } from '@/events/components/other-events';

type Props = {
    onClosingModal: () => void;
    handleSelectionOfEvent: (event: EventModel) => void;
};

export const ViewDetailsStatus: FunctionComponent<Props> = ({
    onClosingModal,
    handleSelectionOfEvent,
}) => {
    const selectedEvent = useSelector<RootState, EventModel | undefined>(
        (state) => state.events.selectedEvent
    );

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    return (
        <div className={Styles.ViewDetailsStatus}>
            <div className="p-0 pt-4 mb-3 mt-2">
                {selectedEvent?.description && (
                    <span className={Styles.EventDescription}>{selectedEvent?.description}</span>
                )}
            </div>

            {selectedEvent?.imageUrl && <EventDetailsImage event={selectedEvent} />}

            <div className={`p-0 d-block mt-3`}>
                {selectedEvent?.venue.type === VenueType.IN_PERSON ? (
                    <div className={`w-100 mb-3 ${Styles.EventMapContainer}`}>
                        {selectedEvent && (
                            <MapView
                                events={[selectedEvent]}
                                authenticatedUser={authenticatedUser}
                                onViewDetails={() => {}}
                                onSelectedEvents={() => {}}
                                areEventsClickable={false}
                                customClassname={Styles.CustomInnerMapClass}
                                loadingEvents={false}
                                recordBoundaries={false}
                                showFirstEventLocationOnly
                            />
                        )}
                    </div>
                ) : null}

                <div className={'d-xl-block d-lg-block d-md-block d-sm-none d-none w-100 py-2'} />

                <EventLocation event={selectedEvent as EventModel} />

                <EventTime event={selectedEvent as EventModel} />

                <div
                    className={'d-xl-block d-lg-block d-md-block d-sm-none d-none w-100 pb-4 pt-2'}
                >
                    <div className={Styles.DividerFull} />
                </div>
            </div>

            <EventDetailsMiscellaneous event={selectedEvent as EventModel} />

            <div className={`${Styles.DividerFull} my-4`} />

            <OtherEvents handleSelectionOfEvent={handleSelectionOfEvent} />
        </div>
    );
};
