import { VenueLocationModel, VenueType } from '@/venues/models';
import { VenueInformationApi } from '@/venues/api/types';

export const venueInformationApiToVenueLocationModel = (
    venueApi: VenueInformationApi
): VenueLocationModel => {
    return {
        id: venueApi.id,
        lat: venueApi.latitude,
        lng: venueApi.longitude,
        type: venueApi.type.toLowerCase().includes('virtual')
            ? VenueType.VIRTUAL
            : VenueType.IN_PERSON,
    };
};
