import React, { FunctionComponent, ReactNode } from 'react';
import Styles from './Styles.module.scss';

export enum LinkType {
    PRIMARY,
    PRIMARY_BLUE,
    LIGHT_BLUE,
    PRIMARY_BLUE_OUTLINE,
}

type Props = {
    type: LinkType;
    children?: ReactNode | ReactNode[];
    customClassName?: string;
    href: string;
    target?: string;
};

export const Link: FunctionComponent<Props> = ({
    type,
    children,
    customClassName,
    href,
    target = '_self',
}) => {
    switch (type) {
        case LinkType.PRIMARY:
            return (
                <a
                    href={href}
                    target={target}
                    className={`${Styles.LinkPrimary} ${customClassName}`}
                >
                    {children}
                </a>
            );

        case LinkType.PRIMARY_BLUE:
            return (
                <a
                    href={href}
                    target={target}
                    className={`${Styles.LinkPrimaryBlue} ${customClassName}`}
                >
                    {children}
                </a>
            );

        case LinkType.LIGHT_BLUE:
            return (
                <a
                    href={href}
                    target={target}
                    className={`${Styles.LinkLightBlue} ${customClassName}`}
                >
                    {children}
                </a>
            );

        case LinkType.PRIMARY_BLUE_OUTLINE:
            return (
                <a
                    href={href}
                    target={target}
                    className={`${Styles.LinkPrimaryBlueOutline} ${customClassName}`}
                >
                    {children}
                </a>
            );

        default:
            return null;
    }
};
