import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { ClientModel } from '@/clients/models';
import { CLIENTS } from '@/constants/clients';
import Styles from './Styles.module.scss';
import { Badge } from '@/components/badge-v2';
import { EventModel, EventSubtype } from '@/events/models';
import { AuthenticatedUserModel } from '@/authentication/models';

type EventClientLogoProps = {
    imageUrl: string;
};

const EventClientLogo: FunctionComponent<EventClientLogoProps> = ({ imageUrl }) => (
    <Badge customClassName={Styles.ClientBadge}>
        <img className={Styles.ClientLogo} alt="event-client-url" src={imageUrl} />
    </Badge>
);

export const useEventClientLogo = ({ event }: { event: EventModel }) => {
    const clients = useSelector<RootState, ClientModel[]>((state) => state.clients.fetchedClients);

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const clientsRelatedToEvent = useMemo(
        () =>
            clients.filter((clientItem) => {
                const foundClientNameInEvent = clientItem.nameOnEvents.find((name) =>
                    event.clients.includes(name.toLowerCase())
                );

                return !!foundClientNameInEvent;
            }),
        [clients, event.clients]
    );

    const client = useMemo(() => {
        if (
            !authenticatedUser &&
            [EventSubtype.AMBASSADOR, EventSubtype.TOWN_HALL].includes(event.subtype)
        ) {
            return undefined;
        }

        if (clientsRelatedToEvent.length === 1) {
            const clientWithImage = clientsRelatedToEvent.find(
                (clientItem) =>
                    CLIENTS[clientItem.parameter] && CLIENTS[clientItem.parameter].imageUrl
            );

            return !!clientWithImage ? clientWithImage.parameter : undefined;
        }

        if (!authenticatedUser && clientsRelatedToEvent.length > 1) {
            return undefined;
        }

        const clientWithImage = clientsRelatedToEvent.find(
            (clientItem) =>
                CLIENTS[clientItem.parameter] &&
                CLIENTS[clientItem.parameter].imageUrl &&
                clientItem.parameter === authenticatedUser?.client
        );

        return !!clientWithImage ? clientWithImage.parameter : undefined;
    }, [clientsRelatedToEvent, authenticatedUser]);

    return {
        hasClientLogo: Boolean(client && CLIENTS[client]?.imageUrl),
        getEventLogoComponent: () => {
            if (client && CLIENTS[client]?.imageUrl) {
                return <EventClientLogo imageUrl={CLIENTS[client]?.imageUrl as string} />;
            }

            return null;
        },
    };
};
