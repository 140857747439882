import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { EventsGroup } from '@/events/reducer/types';
import Styles from './Styles.module.scss';
import { TranslationService } from '@/services/translation';
import { EventsListLargeItem, EventsListSmallItem } from '@/events/components/events-list-items';
import { EventModel } from '@/events/models';

type Props = {
    isMapHidden: boolean;
    onViewDetails: (event: EventModel) => void;
    selectedEventIds: string[];
    onShowMapAppModal: (event: EventModel) => void;
    eventsGroup: EventsGroup;
};

export const EventsGroupList: FunctionComponent<Props> = ({
    isMapHidden,
    onViewDetails,
    selectedEventIds,
    onShowMapAppModal,
    eventsGroup,
}) => {
    const title = useMemo(
        () =>
            `${TranslationService.getInstance().getPhrase(
                'events_list.sections.in_person_events.title'
            )} ${eventsGroup.fromDate} - ${eventsGroup.toDate}`,
        [eventsGroup.fromDate, eventsGroup.toDate]
    );

    const displayEventsList = useCallback(() => {
        return (
            <>
                {eventsGroup.events.map((event) => (
                    <Col key={event.id} xs={12} className={'pb-3 px-3'}>
                        {isMapHidden ? (
                            <EventsListLargeItem
                                event={event}
                                onDisplayDetails={onViewDetails}
                                isSelected={selectedEventIds.includes(event.id)}
                                onShowMapAppModal={onShowMapAppModal}
                            />
                        ) : (
                            <EventsListSmallItem
                                event={event}
                                onDisplayDetails={onViewDetails}
                                isSelected={selectedEventIds.includes(event.id)}
                                onShowMapAppModal={onShowMapAppModal}
                            />
                        )}
                    </Col>
                ))}
            </>
        );
    }, [eventsGroup.events, isMapHidden, onViewDetails, selectedEventIds, onShowMapAppModal]);

    return (
        <>
            <Row className={'mx-0 pb-3'}>
                <Col className={'px-3'}>
                    <span className={`${Styles.Title}`}>{title}</span>
                </Col>
            </Row>

            <Row className={'mx-0'}>{displayEventsList()}</Row>
        </>
    );
};
