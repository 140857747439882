import React from 'react';

const CloseIcon = () => {
    return (
        <svg width="36" height="37" viewBox="0 0 36 37" xmlns="http://www.w3.org/2000/svg">
            <rect
                width="36"
                height="36"
                transform="translate(0 0.885742)"
                fill="white"
                style={{ mixBlendMode: 'multiply' }}
            />
            <path
                d="M27 11.4607L25.425 9.88574L18 17.3107L10.575 9.88574L9 11.4607L16.425 18.8857L9 26.3107L10.575 27.8857L18 20.4607L25.425 27.8857L27 26.3107L19.575 18.8857L27 11.4607Z"
                fill="#004E9C"
            />
        </svg>
    );
};

export default CloseIcon;
