import React, { FunctionComponent, useMemo } from 'react';
import Styles from './styles.module.scss';
import { CLIENTS } from '@/constants/clients';

type Props = {
    client?: string;
};

export const LogoClient: FunctionComponent<Props> = ({ client }) => {
    const imageUrl = useMemo((): string | undefined => {
        if (!client) {
            return undefined;
        }

        const clientFound = Object.keys(CLIENTS).find(
            (item: string) => CLIENTS[item].name === client
        );

        if (!clientFound) {
            return undefined;
        }

        return CLIENTS[clientFound].imageUrl;
    }, [client]);

    return (
        <>
            {client && (
                <div className="p-0 w-100">
                    {imageUrl && (
                        <img src={imageUrl} alt="wider-circle-logo" className={Styles.ClientLogo} />
                    )}
                </div>
            )}
        </>
    );
};
