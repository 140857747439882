import { EventModel } from '@/events/models';

export const getUSStatesForFiltering = (events: EventModel[]): string[] => {
    const states: {
        [key: string]: number;
    } = {};

    events.forEach((event: EventModel) => {
        states[event.venue.state] = 1;
    });

    return Object.keys(states).sort();
};
