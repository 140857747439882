import { ClientModel } from '@/clients/models';
import { ClientApi } from '@/clients/api/types';

export const clientApiToClientModel = (clientApi: ClientApi): ClientModel => {
    return {
        label: clientApi.label,
        parameter: clientApi.parameter,
        nameOnEvents: [...clientApi.names_on_events],
    };
};
