import React, { FunctionComponent, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Styles from './Styles.module.scss';
import { CLIENTS } from '@/constants/clients';
import { TranslationService } from '@/services/translation';

type Props = {
    client?: string;
};

export const PartnerInfo: FunctionComponent<Props> = ({ client }) => {
    const imageUrl = useMemo((): string | undefined => {
        if (!client) {
            return undefined;
        }

        const clientFound = Object.keys(CLIENTS).find(
            (item: string) => CLIENTS[item].name === client
        );

        if (!clientFound) {
            return undefined;
        }

        return CLIENTS[clientFound].imageUrl;
    }, [client]);

    if (!imageUrl) {
        return null;
    }

    return (
        <Col xs={6} lg={4} className={`px-1`}>
            <h5 className={Styles.SidebarItemTitle}>
                {TranslationService.getInstance().getPhrase('registration.sidebar.logo.title')}
            </h5>

            <span className={`${Styles.PartnerLogo} p-0`}>
                <div className="p-0 w-100">
                    {imageUrl && (
                        <img src={imageUrl} alt="wider-circle-logo" className={Styles.ClientLogo} />
                    )}
                </div>
            </span>
        </Col>
    );
};
