import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { RootState } from '@/store';
import { EventModel } from '@/events/models';
import Styles from './Styles.module.scss';
import { TranslationService } from '@/services/translation';
import { Button, ButtonType } from '@/components/form-controls/button';
import { Link, LinkType } from '@/components/form-controls/link';
import { Config } from '@/utilities/config';
import { eventsActions } from '@/events/reducer';
import { RegistrationStatus } from '@/events/reducer/types';

type Props = {
    step: number;
    onPrevious: () => void;
    onNext: () => void;
    isNextButtonDisabled: boolean;
};

export const StepActions: FunctionComponent<Props> = ({
    step,
    onPrevious,
    onNext,
    isNextButtonDisabled,
}) => {
    const selectedEvent = useSelector<RootState, EventModel | undefined>(
        (state) => state.events.selectedEvent
    );

    const leftColumnWidthInSmallDevices = useMemo(() => (step === 1 || step === 2 ? 6 : 5), [step]);

    const leftColumnClassName = useMemo(() => {
        const baseClassName = `d-flex align-items-center px-1 px-md-2 ${Styles.RegistrationModalLinkContainer}`;

        return `${baseClassName} ${
            step === 1 ? 'justify-content-between' : 'justify-content-start'
        }`;
    }, [step]);

    const rightColumnWidthInSmallDevices = useMemo(
        () => (step === 1 || step === 2 ? 6 : 7),
        [step]
    );

    const rightButtonLabel = useMemo(() => {
        if (step === 1 || step === 4) {
            return TranslationService.getInstance().getPhrase('sign_in.buttons.submit');
        }

        return TranslationService.getInstance().getPhrase('sign_in.buttons.next');
    }, [step]);

    const dispatch = useDispatch();

    const onClosingAuthenticationModal = useCallback(() => {
        if (selectedEvent) {
            dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_DETAILS));
            return;
        }

        dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.VIEWING_EVENTS));
    }, [dispatch, selectedEvent]);

    return (
        <Row className={`d-flex flex-row mx-0 px-3 pt-3 pb-4 ${Styles.StepActions}`}>
            <Col xs={6} sm={leftColumnWidthInSmallDevices} className={leftColumnClassName}>
                {step === 1 && !selectedEvent && (
                    <Button
                        type={ButtonType.LIGHT_BLUE}
                        onClick={onClosingAuthenticationModal}
                        customClassName={Styles.RegistrationModalLink}
                    >
                        {TranslationService.getInstance().getPhrase('sign_in.buttons.go_to_events')}
                    </Button>
                )}

                {step === 1 && selectedEvent && (
                    <Button
                        type={ButtonType.LIGHT_BLUE}
                        onClick={onClosingAuthenticationModal}
                        customClassName={Styles.RegistrationModalLink}
                    >
                        {TranslationService.getInstance().getPhrase('sign_in.buttons.go_to_event')}
                    </Button>
                )}

                {step === 5 && (
                    <Link
                        type={LinkType.PRIMARY_BLUE}
                        href={Config.WcPublicUrl}
                        customClassName={'text-center w-100'}
                    >
                        {TranslationService.getInstance().getPhrase('sign_in.buttons.close')}
                    </Link>
                )}

                {step > 1 && step <= 4 && (
                    <Button
                        type={ButtonType.LINK_BUTTON}
                        onClick={onPrevious}
                        customClassName={`px-4 py-2  ${Styles.RegistrationModalButtonBack}`}
                    >
                        {TranslationService.getInstance().getPhrase('sign_in.buttons.back')}
                    </Button>
                )}
            </Col>
            <Col xs={6} sm={rightColumnWidthInSmallDevices} className="text-end px-1 px-md-2">
                {step !== 5 && (
                    <Button
                        type={ButtonType.PRIMARY_BLUE}
                        onClick={onNext}
                        customClassName={`${Styles.RegistrationModalButtonRight}`}
                        disabled={isNextButtonDisabled}
                    >
                        {rightButtonLabel}
                    </Button>
                )}
            </Col>
        </Row>
    );
};
