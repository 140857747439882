import Axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { TOKEN_PROP_NAME } from '@/authentication/hooks/use-authorization';

export interface Interceptor<T> {
    onFulfilled?: (value: T) => T | Promise<T>;
    onRejected?: (error: any) => any;
}

export interface HttpClientOptions {
    baseUrl: string;
    timeout?: number;
    headers: { [key: string]: string };
}

const defaultTimeout = 60000;

export class HttpClient {
    private axios: AxiosInstance;

    constructor({ baseUrl, timeout, headers }: HttpClientOptions) {
        this.axios = Axios.create({
            baseURL: baseUrl,
            timeout: timeout ?? defaultTimeout,
            headers,
        });
    }

    requestFull<T>(config: AxiosRequestConfig) {
        const controller = new AbortController();
        const id = setTimeout(() => {
            controller.abort();
        }, config.timeout ?? defaultTimeout);

        const token = localStorage.getItem(TOKEN_PROP_NAME);

        return this.axios
            .request<T>({
                ...config,
                headers: {
                    ...config.headers,
                    authorizationtoken: token
                        ? `Bearer ${localStorage.getItem(TOKEN_PROP_NAME)}`
                        : undefined,
                },
                signal: controller.signal,
            })
            .then((response) => {
                clearTimeout(id);
                return response;
            });
    }

    request<T>(config: AxiosRequestConfig): Promise<T> {
        return this.requestFull<T>(config).then((response: AxiosResponse<T>) => response.data);
    }
}
