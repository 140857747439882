import { useCallback, useEffect } from 'react';

export const useClickListener = (
    controlContainerRef: any,
    menuContainerRef: any,
    callback: () => void,
    isMenuOpen: boolean
) => {
    const handleClick = useCallback(
        (event: any) => {
            if (!isMenuOpen) {
                return;
            }

            const clickPositionX = event.clientX;
            const clickPositionY = event.clientY;

            const positionXStart = controlContainerRef?.current.getBoundingClientRect().x;

            const positionXEnd =
                menuContainerRef?.current.getBoundingClientRect().width + positionXStart;

            const positionYStart = controlContainerRef?.current.getBoundingClientRect().y;

            const positionYEnd =
                controlContainerRef?.current.getBoundingClientRect().height +
                menuContainerRef?.current.getBoundingClientRect().height +
                positionYStart;

            if (
                clickPositionX < positionXStart ||
                clickPositionX > positionXEnd ||
                clickPositionY < positionYStart ||
                clickPositionY > positionYEnd
            ) {
                console.log('HERE');
                callback();
            }
        },
        [isMenuOpen, controlContainerRef, menuContainerRef, callback]
    );

    useEffect(() => {
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [handleClick]);
};
