import { ContactModel, MemberSearchResultModel } from '@/contacts/models';

export type SignInBaseForm = {
    phoneNumber?: string;
    lastName?: string;
};

export interface ContactsState {
    searchResults: MemberSearchResultModel[];
    selectedContact?: MemberSearchResultModel;
    matchedContact?: ContactModel;
    signInForm: SignInBaseForm;
}

export const initialContactsState: ContactsState = {
    searchResults: [],
    selectedContact: undefined,
    matchedContact: undefined,
    signInForm: {},
};
