import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';

type Props = {
    id: string;
    customContainerClassName?: string;
    customLabelClassName?: string;
    label: string;
    value: string;
    isChecked: boolean;
    onChange: (newValue: string) => void;
};

export const RadioButton: FunctionComponent<Props> = ({
    id,
    customContainerClassName,
    customLabelClassName,
    label,
    value,
    isChecked,
    onChange,
}) => {
    const changeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        },
        [onChange]
    );

    const clickHandler = useCallback(() => {
        onChange(value);
    }, [value, onChange]);

    return (
        <div
            className={`${Styles.RadioButton} ${customContainerClassName} d-flex align-items-center`}
            onClick={clickHandler}
        >
            <img
                alt="radio-button-icon"
                className={Styles.icon}
                src={isChecked ? IMAGES.RadioCheckedIcon : IMAGES.RadioUncheckedIcon}
            />
            <label className={customLabelClassName} htmlFor={id}>
                {label}
            </label>
        </div>
    );
};
