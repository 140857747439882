export type TConfig = {
    ApiUrl: string;
    ApiSecretHeader: string;
    WcPublicUrl: string;
    SelfServePublicUrl: string;
    SelfServeSharingUrl: string;
    GoogleTagId: string;
};

export const Config: TConfig = {
    ApiUrl:
        process.env.REACT_APP_API_URL ||
        'https://widercircle--qa.sandbox.my.site.com/portal/services/apexrest',
    ApiSecretHeader: process.env.REACT_APP_API_SECRET || '123456',
    WcPublicUrl: process.env.REACT_APP_WIDER_CIRCLE_PUBLIC_URL || 'https://www.widercircle.com/',
    SelfServePublicUrl:
        process.env.REACT_APP_SELF_SERVE_PUBLIC_URL ||
        'https://event-registration-dev.widercircle.com/',
    SelfServeSharingUrl:
        process.env.REACT_APP_SELF_SERVE_SHARING_URL ||
        'https://event-registration-dev.widercircle.com/',
    GoogleTagId: process.env.REACT_APP_GOOGLE_TAG_ID || 'G-42Y86BYFZ9',
};
