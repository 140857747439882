import { RootState } from '@/store';
import { connect, ConnectedProps } from 'react-redux';
import {
    eventsActions,
    fetchEvents,
    fetchEventsForMap,
    fetchUpcomingWeeklyEvents,
} from '@/events/reducer';

const mapState = (state: RootState) => ({
    fetchedEvents: state.events.fetchedEvents,
    fetchedUpcomingWeeklyEvents: state.events.fetchedUpcomingWeeklyEvents,
    isLoadingEvents: state.events.isLoadingEvents,
    selectedEvent: state.events.selectedEvent,
    authenticatedUser: state.authentication.authenticatedUser,
    queryParameters: state.authentication.queryParameters,
    fetchedClients: state.clients.fetchedClients,
    registrationStatus: state.events.registrationStatus,
    filters: state.events.filters,
});

const actionsCreator = {
    selectEvent: eventsActions.selectEvent,
    fetchEvents,
    fetchEventsForMap,
    fetchUpcomingWeeklyEvents,
};

export const EventRegistrationConnector = connect(mapState, actionsCreator);
export type EventRegistrationConnectorProps = ConnectedProps<typeof EventRegistrationConnector>;
