import AetnaLogo from '@/assets/images/clients/aetna-logo.png';
import HpsmLogo from '@/assets/images/clients/hpsm-logo.jpeg';
import OptumLogo from '@/assets/images/clients/optum-logo.png';
import BcbsazLogo from '@/assets/images/clients/bcbsaz-logo.jpeg';
import { ClientsGroup, ClientsList } from '@/constants/types';

export const CLIENTS: ClientsList = {
    aetna: {
        name: 'aetna',
        phoneNumber: '(866) 396-0084',
        phoneRef: 'tel:18663960084',
        numberOfImages: 30,
        imageNamePrefix: 'general',
        imageUrl: AetnaLogo,
    },
    bcbsm: {
        name: 'bcbsm',
        phoneNumber: '(734) 521-3000',
        phoneRef: 'tel:17345213000',
        numberOfImages: 30,
        imageNamePrefix: 'general',
    },
    bcbsaz: {
        name: 'bcbsaz',
        phoneNumber: '(734) 521-3000',
        phoneRef: 'tel:17345213000',
        numberOfImages: 30,
        imageNamePrefix: 'general',
        imageUrl: BcbsazLogo,
    },
    bcc: {
        name: 'bcc',
        phoneNumber: '(800) 656-0228',
        phoneRef: 'tel:18006560228',
        numberOfImages: 8,
        imageNamePrefix: 'bcc',
    },
    'blue cross complete': {
        name: 'bcc',
        phoneNumber: '(800) 656-0228',
        phoneRef: 'tel:18006560228',
        numberOfImages: 8,
        imageNamePrefix: 'bcc',
    },
    optum: {
        name: 'optum',
        phoneNumber: '(562) 365-2470',
        phoneRef: 'tel:15623652470',
        numberOfImages: 30,
        imageNamePrefix: 'general',
        imageUrl: OptumLogo,
    },
    'Apple Care': {
        name: 'apple care',
        phoneNumber: '(562) 365-2470',
        phoneRef: 'tel:15623652470',
        numberOfImages: 30,
        imageNamePrefix: 'general',
        imageUrl: OptumLogo,
    },
    'Health Care Partners': {
        name: 'health care partners',
        phoneNumber: '(562) 365-2470',
        phoneRef: 'tel:15623652470',
        numberOfImages: 30,
        imageNamePrefix: 'general',
        imageUrl: OptumLogo,
    },
    'CA Mixed': {
        name: 'ca mixed',
        phoneNumber: '(562) 365-2470',
        phoneRef: 'tel:15623652470',
        numberOfImages: 30,
        imageNamePrefix: 'general',
        imageUrl: OptumLogo,
    },
    uhc: {
        name: 'uhc',
        phoneNumber: '(213) 652-3184',
        phoneRef: 'tel:12136523184',
        numberOfImages: 30,
        imageNamePrefix: 'general',
    },
    'united healthcare': {
        name: 'united healthcare',
        phoneNumber: '(213) 652-3184',
        phoneRef: 'tel:12136523184',
        numberOfImages: 30,
        imageNamePrefix: 'general',
    },
    hpsm: {
        name: 'hpsm',
        phoneNumber: '(650) 502-8891',
        phoneRef: 'tel:16505028891',
        numberOfImages: 30,
        imageNamePrefix: 'general',
        imageUrl: HpsmLogo,
    },
    ahcla: {
        name: 'ahcla',
        phoneNumber: '(225) 376-2596',
        phoneRef: 'tel:12253762596',
        numberOfImages: 30,
        imageNamePrefix: 'general',
    },
    ahcde: {
        name: 'ahcde',
        phoneNumber: '(225) 376-2596',
        phoneRef: 'tel:12253762596',
        numberOfImages: 30,
        imageNamePrefix: 'general',
    },
    ahcsc: {
        name: 'ahcsc',
        phoneNumber: '(225) 376-2596',
        phoneRef: 'tel:12253762596',
        numberOfImages: 30,
        imageNamePrefix: 'general',
    },
    ahcpa: {
        name: 'ahcpa',
        phoneNumber: '(225) 376-2596',
        phoneRef: 'tel:12253762596',
        numberOfImages: 30,
        imageNamePrefix: 'general',
    },
    default: {
        name: 'wider circle',
        phoneNumber: '(800) 656-0228',
        phoneRef: 'tel:18006560228',
        numberOfImages: 30,
        imageNamePrefix: 'general',
    },
};

export const CLIENTS_GROUPS: ClientsGroup[] = [
    {
        id: 'United Healthcare Group',
        clients: [
            CLIENTS.uhc.name,
            CLIENTS['united healthcare'].name,
            'apple care',
            'optum',
            'health care partners',
            'ca mixed',
            CLIENTS.hpsm.name,
        ],
    },
];
