import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { InfoBox } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';

import { EventModel } from '@/events/models';
import Styles from './Styles.module.scss';
import { Coordinates } from '@/services/geolocation/types';
import { IMAGES } from '@/assets/images';
import { Button, ButtonType } from '@/components/form-controls/button';
import { DateModel } from '@/utilities/dates';
import { VenueType } from '@/venues/models';
import GeneralStyles from '@/events/components/events-list-items/general-styles.module.scss';

import { getRegistrationButtonLabel } from '@/events/pages/all-events/utils';
import { useRegistrationFromEventsList } from '@/events/hooks/use-registration-from-events-list';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { EventBadges } from '@/events/components/event-badges';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

type Props = {
    event: EventModel;
    position: Coordinates;
    onClose: () => void;
    onDisplayDetails: (event: EventModel) => void;
};

const options = {
    closeBoxURL: '',
    enableEventPropagation: true,
    boxStyle: {
        width: '',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
    },
};

export const EventDetailsBox: FunctionComponent<Props> = ({
    event,
    position,
    onClose,
    onDisplayDetails,
}) => {
    const [infoBoxOptions, setInfoBoxOptions] = useState(options);

    const { width } = useWindowDimensions();

    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const { onAddMeToGuestList, loading } = useRegistrationFromEventsList({ event });

    const center = new google.maps.LatLng({
        lat: position.latitude,
        lng: position.longitude,
    });

    const displayDetailsHandler = useCallback(() => {
        onDisplayDetails(event);
    }, [event, onDisplayDetails]);

    useEffect(() => {
        setInfoBoxOptions({
            ...options,
            boxStyle: {
                ...options.boxStyle,
                width: width < 900 ? '36vw' : '61vw',
            },
        });
    }, [width, setInfoBoxOptions]);

    return (
        <InfoBox onCloseClick={onClose} position={center} options={infoBoxOptions}>
            <Card className={Styles.EventDetailsBox}>
                <Card.Body className={`p-2`}>
                    <Row className={'pb-2 mx-0'}>
                        <Col className={'px-1'}>
                            <span className={`${Styles.EventTitle}`}>{event.name}</span>
                        </Col>

                        <Col className={'px-0'} xs={'auto'}>
                            <img
                                alt={'event-details-box-close-icon'}
                                src={IMAGES.CloseCircleIcon}
                                className={Styles.CloseIcon}
                                onClick={onClose}
                            />
                        </Col>
                    </Row>

                    <EventBadges event={event} />

                    <Row className={'pt-2 pb-1 mx-0'}>
                        <Col className={'px-1 d-flex align-items-center'}>
                            {event.venue.type === VenueType.IN_PERSON ? (
                                <img
                                    alt="direction-sign-icon"
                                    className={`pe-2 ${Styles.DirectionSignIcon}`}
                                    src={IMAGES.DirectionSignIcon}
                                />
                            ) : (
                                <img
                                    alt="headset-icon"
                                    className={`pe-2 ${Styles.Icon}`}
                                    src={IMAGES.HeadsetIcon}
                                />
                            )}

                            <span className={Styles.EventLocation}>{event.venue.name}</span>
                        </Col>
                    </Row>

                    <Row className={'pb-1 mx-0'}>
                        <Col className={'px-1 d-flex align-items-center'}>
                            <img
                                alt="map-location-icon"
                                className={`pe-2 ${Styles.Icon}`}
                                src={IMAGES.MapLocationIcon}
                            />
                            <span
                                className={Styles.EventLocation}
                            >{`${event.venue.city} ${event.venue.state} ${event.venue.zipcode}`}</span>
                        </Col>
                    </Row>

                    <Row className={'pb-1 mx-0'}>
                        <Col className={'px-1 d-flex align-items-center'}>
                            <img
                                alt="calendar-icon"
                                className={`pe-2 ${Styles.CalendarIcon}`}
                                src={IMAGES.CalendarIcon}
                            />
                            <span className={Styles.EventLocation}>{`${new DateModel(
                                event.startDateInMilliseconds
                            ).format('MMMM DD')} at ${new DateModel(
                                event.startDateInMilliseconds
                            ).format('LT')} to ${new DateModel(event.endDateInMilliseconds).format(
                                'LT'
                            )} (${new DateModel(event.endDateInMilliseconds).format('z')})`}</span>
                        </Col>
                    </Row>

                    <Row className={'pb-1 py-2 mx-0'}>
                        <Col className={'d-flex justify-content-end px-1'}>
                            <Button
                                type={ButtonType.LIGHT_GRAY_BLUE}
                                onClick={displayDetailsHandler}
                                customClassName={`px-4 py-2 ${GeneralStyles.RegisterButton}`}
                            >
                                {getRegistrationButtonLabel(event)}
                            </Button>

                            {authenticatedUser && !event.isAuthenticatedUserRegistered && (
                                <>
                                    <span className={'px-1'} />

                                    <Button
                                        type={ButtonType.LIGHT_BLUE}
                                        onClick={onAddMeToGuestList}
                                        customClassName={`px-4 py-2 ${GeneralStyles.RegisterButton}`}
                                        disabled={loading}
                                    >
                                        <div
                                            className={`${GeneralStyles.RegisterButtonContent} d-flex align-items-center`}
                                        >
                                            <span className={'ps-2'}>Add me to guest list</span>
                                        </div>
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </InfoBox>
    );
};
