export enum AnalyticsEventName {
    ApplicationError = 'application_error',
    MismatchContact = 'mismatch_contact',
    ViewDetailsButtonClick = 'view_details_button_click',
    UnregisterEventButtonClick = 'unregister_event_button_click',
    RegisterEventButtonClick = 'register_event_button_click',
    UsStateFilterChange = 'us_state_filter_change',
    RegisteredOnlyFilterChange = 'registered_only_filter_change',
    LanguageFilterChange = 'language_filter_change',
    EventTypeFilterChange = 'event_type_filter_change',
    SearchTextFilterChange = 'search_text_filter_change',
    ResetFiltersButtonClick = 'reset_filters_button_click',
    ZipcodeSearch = 'zipcode_search',
    LocationShared = 'location_shared',
    MapInteracted = 'map_interacted',
    SignInFormFocused = 'sign_in_form_focused',
    SignInFormSubmitted = 'sign_in_form_submitted',
    UnmatchedSignInFormFocused = 'unmatched_sign_in_form_focused',
    UnmatchedSignInFormSubmit = 'unmatched_sign_in_form_submit',
    EventMarkerClicked = 'event_marker_clicked',
}

export enum AnalyticsEventCategory {
    Application = 'Application',
    EventRegistration = 'Event Registration',
    EventsFiltering = 'Event Filtering',
    MapInteraction = 'Map Interaction',
    SignIn = 'Sign In',
}
