import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ZIP_CODE_LOCAL_STORAGE_KEY } from '@/constants/local-storage';
import { eventsActions } from '@/events/reducer';

export const useLocalStorageInitializer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const currentZipCode = localStorage.getItem(ZIP_CODE_LOCAL_STORAGE_KEY);

        if (currentZipCode) {
            dispatch(eventsActions.updateZipcodeFilter(currentZipCode));
            dispatch(eventsActions.updateHasZipcodeBeenInputted(true));
        }
    }, []);
};
