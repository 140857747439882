import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';

type Props = {
    id: string;
    customContainerClassName?: string;
    customLabelClassName?: string;
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
};

export const Checkbox: FunctionComponent<Props> = ({
    id,
    customContainerClassName,
    customLabelClassName,
    label,
    value,
    onChange,
}) => {
    const clickHandler = useCallback(() => {
        onChange(!value);
    }, [value, onChange]);

    return (
        <div
            className={`${Styles.Checkbox} ${customContainerClassName} d-flex align-items-center`}
            onClick={clickHandler}
        >
            <img
                alt="checkbox-mark"
                className={Styles.icon}
                src={value ? IMAGES.CheckboxCheckedIcon : IMAGES.CheckboxUncheckedIcon}
            />
            <label className={customLabelClassName} htmlFor={id}>
                {label}
            </label>
        </div>
    );
};
