import React, { FunctionComponent } from 'react';
import Styles from './Styles.module.scss';

type Props = {
    step: number;
    loading: boolean;
};

export const StepBoard: FunctionComponent<Props> = ({ step, loading }) => {
    return (
        <ul className={`steps-nav ${Styles.StepBoard} px-0`}>
            <li className={!loading && step === 1 ? `${Styles.active} px-4` : ''}>
                <span>Step</span>&nbsp;1
            </li>

            <span className={'px-1'} />

            <li className={!loading && step === 2 ? `${Styles.active} px-4` : ''}>
                <span>Step</span>&nbsp;2
            </li>

            <span className={'px-1'} />

            <li className={!loading && step === 3 ? `${Styles.active} px-4` : ''}>
                <span>Step</span>&nbsp;3
            </li>
        </ul>
    );
};
