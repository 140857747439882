import React, { FunctionComponent, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { EventModel, EventType } from '@/events/models';
import { Button, ButtonType } from '@/components/form-controls/button';
import {
    getEventTypeLabel,
    getRegistrationButtonLabel,
    getVenueTypeLabel,
} from '@/events/pages/all-events/utils';
import Styles from './Styles.module.scss';
import { EventLocation } from '@/events/components/event-location';
import { EventTime } from '@/events/components/event-time';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AuthenticatedUserModel } from '@/authentication/models';
import { useRegistrationFromEventsList } from '@/events/hooks/use-registration-from-events-list';
import { useEventClientLogo } from '@/events/components/use-event-client-logo';
import { Badge } from '@/components/badge-v2';
import GeneralStyles from '@/events/components/events-list-items/general-styles.module.scss';

type Props = {
    event: EventModel;
    onViewDetails: (event: EventModel) => void;
};

export const UpcomingWeeklyEvent: FunctionComponent<Props> = ({ event, onViewDetails }) => {
    const authenticatedUser = useSelector<RootState, AuthenticatedUserModel | undefined>(
        (state) => state.authentication.authenticatedUser
    );

    const { hasClientLogo, getEventLogoComponent } = useEventClientLogo({ event });

    const { onAddMeToGuestList, loading } = useRegistrationFromEventsList({ event });

    return (
        <>
            <div className={`p-0 ${Styles.UpcomingWeeklyEvent}`}>
                {event.imageUrl && (
                    <Col xs={12} className={`${Styles.EventImageCon} px-0`}>
                        <img className={`${Styles.EventImage}`} src={event.imageUrl} alt="Event" />

                        <div
                            className={`d-xl-flex d-lg-flex d-md-none d-sm-flex d-none align-item-center ${Styles.BadgesContainer}`}
                        >
                            {hasClientLogo && <>{getEventLogoComponent()}</>}

                            <span className={'px-1'} />

                            <Badge
                                label={getVenueTypeLabel(event)}
                                backgroundColor={'#FFFFFF'}
                                color={'#262626'}
                            />

                            {event?.type === EventType.KICKOFF && (
                                <>
                                    <span className={'px-1'} />

                                    <Badge
                                        label={getEventTypeLabel(event)}
                                        backgroundColor={'#FFFFFF'}
                                        color={'#262626'}
                                    />
                                </>
                            )}
                        </div>

                        <div
                            className={`d-xl-none d-lg-none d-md-flex d-sm-none d-flex flex-column align-item-center ${Styles.BadgesContainer}`}
                        >
                            <div className={'d-flex'}>
                                {hasClientLogo && <>{getEventLogoComponent()}</>}

                                <span className="px-1" />

                                <Badge
                                    label={getVenueTypeLabel(event)}
                                    backgroundColor={'#FFFFFF'}
                                    color={'#262626'}
                                />
                            </div>

                            {event?.type === EventType.KICKOFF && (
                                <div className={'d-flex pt-2'}>
                                    <Badge
                                        label={getEventTypeLabel(event)}
                                        backgroundColor={'#FFFFFF'}
                                        color={'#262626'}
                                    />
                                </div>
                            )}
                        </div>
                    </Col>
                )}
                <div className="p-3">
                    <Row className={'mx-0'}>
                        <Col className={`px-0 d-flex justify-content-start align-items-center`}>
                            <h3>{event.name}</h3>
                        </Col>
                    </Row>

                    <div className={`${Styles.EventInfo}`}>
                        <EventLocation event={event} />

                        <EventTime event={event} />
                    </div>

                    <Row className={'pb-1 mx-0 mt-3'}>
                        <Col className={'d-flex justify-content-end px-0'}>
                            <Button
                                type={ButtonType.LIGHT_GRAY_BLUE}
                                onClick={() => onViewDetails(event)}
                                customClassName={`px-1 py-2 ${Styles.RegisterButton}`}
                            >
                                {getRegistrationButtonLabel(event)}
                            </Button>

                            {authenticatedUser && !event.isAuthenticatedUserRegistered && (
                                <>
                                    <span className={'px-1'} />

                                    <Button
                                        type={ButtonType.LIGHT_BLUE}
                                        onClick={onAddMeToGuestList}
                                        customClassName={`px-2 py-2 ${GeneralStyles.RegisterButton}`}
                                        disabled={loading}
                                    >
                                        <div
                                            className={`${Styles.RegisterButtonContent} d-flex align-items-center`}
                                        >
                                            Register
                                        </div>
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
