import React, { Component, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Analytics } from '@/services/analytics';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { CLIENTS } from '@/constants/clients';

type Props = {
    children: ReactNode;
};

type State = {
    hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ hasError: true });
        Analytics.getInstance().fireEvent(
            AnalyticsEventName.ApplicationError,
            AnalyticsEventCategory.Application,
            typeof error === 'string' ? error : error.message,
            {}
        );
    }

    render() {
        return this.state.hasError ? (
            <Container className={Styles.Container}>
                <Row className={'h-100'}>
                    <Col
                        className={
                            'd-flex d-sm-flex d-md-flex d-lg-none d-xl-none flex-column justify-content-center align-items-center'
                        }
                    >
                        <img
                            alt="error-boundary"
                            src={IMAGES.ErrorBoundaryImage}
                            className={`${Styles.LargeImage}`}
                        />

                        <div
                            className={`d-flex flex-column ${Styles.SmallMessage} pt-4 text-center`}
                        >
                            <span className={'pb-3'}>
                                We are sorry but we are experiencing some problems at the moment.
                            </span>
                            <span>
                                Try to refresh your browser or inform us at{' '}
                                <a href={CLIENTS.default.phoneRef}>{CLIENTS.default.phoneNumber}</a>
                            </span>
                        </div>
                    </Col>

                    <Col
                        className={
                            'd-none d-sm-none d-md-none d-lg-flex d-xl-flex justify-content-center align-items-center'
                        }
                    >
                        <img
                            alt="error-boundary"
                            src={IMAGES.ErrorBoundaryImage}
                            className={`${Styles.SmallImage}`}
                        />

                        <div className={`d-flex flex-column ${Styles.LargeMessage}`}>
                            <span className={'pb-3'}>
                                We are sorry but we are experiencing some problems at the moment.
                            </span>
                            <span>
                                Try to refresh your browser or inform us at{' '}
                                <a href={CLIENTS.default.phoneRef}>{CLIENTS.default.phoneNumber}</a>
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        ) : (
            this.props.children
        );
    }
}
