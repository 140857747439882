import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export class DateModel {
    private _date: dayjs.Dayjs;

    constructor(milliseconds?: number) {
        if (milliseconds) {
            this._date = dayjs(milliseconds);
        } else {
            this._date = dayjs();
        }
    }

    public format(pattern: string, tz?: string): string {
        return tz ? this._date.tz(tz).format(pattern) : this._date.format(pattern);
    }

    public addSeconds(seconds: number): Date {
        return this._date.add(seconds, 'second').toDate();
    }

    public getTimezone(): string {
        return dayjs.tz.guess();
    }

    public setTimezone(tz: string) {
        this._date.tz(tz);
    }

    public getWeek() {
        const week: { [index: string]: any } = {
            sunday: {},
            monday: {},
            tuesday: {},
            wednesday: {},
            thursday: {},
            friday: {},
            saturday: {},
        };

        const dayOfWeekFromCurrentDate = this._date.day();

        Object.keys(week).forEach((dayOfWeek, index) => {
            const dayOfWeekObject = dayjs(this._date).subtract(
                dayOfWeekFromCurrentDate - index,
                'day'
            );

            const newDateObject = dayjs(`${dayOfWeekObject.format('L')} 00:00:00`);

            week[dayOfWeek] = {
                month: newDateObject.format('MMMM'),
                day: newDateObject.format('DD'),
                timestamp: newDateObject.valueOf(),
            };
        });

        return week;
    }
}
