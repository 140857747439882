import React, {
    ChangeEvent,
    FunctionComponent,
    KeyboardEvent,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { Polygon, Polyline } from '@react-google-maps/api';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { Boundaries, Coordinates } from '@/services/geolocation/types';
import { TranslationService } from '@/services/translation';

type LocatorProps = {
    onZipcodeChange: (newZipcode: string | undefined) => void;
};

type ZipcodeBoundariesProps = {
    boundaries: Boundaries;
};

export const Locator: FunctionComponent<LocatorProps> = ({ onZipcodeChange }) => {
    const [newZipcode, setNewZipcode] = useState<string | undefined>(undefined);

    const changeHandlerForWeb = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { value },
            } = event;

            setNewZipcode(value);
        },
        [setNewZipcode]
    );

    const changeHandlerForMobile = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { value },
            } = event;

            setNewZipcode(value);

            if (value.length === 5) {
                onZipcodeChange(value);
            }
        },
        [onZipcodeChange]
    );

    const keyDownHandler = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                onZipcodeChange(newZipcode);
            }
        },
        [newZipcode, onZipcodeChange]
    );

    const clearHandler = useCallback(() => {
        setNewZipcode('');
        onZipcodeChange(undefined);
    }, [setNewZipcode, onZipcodeChange]);

    return (
        <>
            <div className={'d-xl-block d-lg-block d-md-block d-sm-none d-none'}>
                <div className={`${Styles.Locator} p-2 d-flex align-items-center`}>
                    <img
                        alt="locator-icon"
                        className={`${Styles.Icon} pe-1`}
                        src={IMAGES.LocatorIcon}
                    />

                    <input
                        className={``}
                        value={newZipcode}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'map.zipcode.placeholder'
                        )}
                        onChange={changeHandlerForWeb}
                        onKeyDown={keyDownHandler}
                        max={5}
                    />

                    {newZipcode && (
                        <img
                            alt="close-icon"
                            className={`${Styles.ClearIcon} ps-1`}
                            src={IMAGES.CloseCircleIcon}
                            onClick={clearHandler}
                        />
                    )}
                </div>
            </div>

            <div className={'d-xl-none d-lg-none d-md-none d-sm-block d-block'}>
                <div className={`${Styles.LocatorMobile} p-2 d-flex align-items-center mt-2 me-3`}>
                    <input
                        className={``}
                        type="number"
                        max={5}
                        value={newZipcode}
                        placeholder={TranslationService.getInstance().getPhrase(
                            'map.zipcode.placeholder'
                        )}
                        onChange={changeHandlerForMobile}
                    />

                    {newZipcode && (
                        <img
                            alt="close-icon"
                            className={`${Styles.ClearIcon} ps-1`}
                            src={IMAGES.CloseCircleIcon}
                            onClick={clearHandler}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export const ZipcodeBoundaries: FunctionComponent<ZipcodeBoundariesProps> = ({ boundaries }) => {
    const polygonPath = useMemo(
        () => [
            {
                lat: boundaries.northwest.latitude,
                lng: boundaries.northwest.longitude,
            },
            {
                lat: boundaries.southwest.latitude,
                lng: boundaries.southwest.longitude,
            },
            {
                lat: boundaries.southeast.latitude,
                lng: boundaries.southeast.longitude,
            },
            {
                lat: boundaries.northeast.latitude,
                lng: boundaries.northeast.longitude,
            },
        ],
        [
            boundaries.northwest.latitude,
            boundaries.northwest.longitude,
            boundaries.northeast.latitude,
            boundaries.northeast.longitude,
            boundaries.southwest.latitude,
            boundaries.southwest.longitude,
            boundaries.southeast.latitude,
            boundaries.southeast.longitude,
        ]
    );

    const polylineOptions = useMemo(
        () => ({
            strokeWeight: 0,
            icons: [
                {
                    icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: 3,
                        strokeColor: '#FF5C00',
                    },
                    offset: '0',
                    repeat: '20px',
                },
            ],
        }),
        []
    );

    const northwestToNortheastPath = useMemo(
        () => [
            {
                lat: boundaries.northwest.latitude,
                lng: boundaries.northwest.longitude,
            },
            {
                lat: boundaries.northeast.latitude,
                lng: boundaries.northeast.longitude,
            },
        ],
        [
            boundaries.northwest.latitude,
            boundaries.northwest.longitude,
            boundaries.northeast.latitude,
            boundaries.northeast.longitude,
        ]
    );

    const northwestToSouthwestPath = useMemo(
        () => [
            {
                lat: boundaries.northwest.latitude,
                lng: boundaries.northwest.longitude,
            },
            {
                lat: boundaries.southwest.latitude,
                lng: boundaries.southwest.longitude,
            },
        ],
        [
            boundaries.northwest.latitude,
            boundaries.northwest.longitude,
            boundaries.southwest.latitude,
            boundaries.southwest.longitude,
        ]
    );

    const northeastToSoutheastPath = useMemo(
        () => [
            {
                lat: boundaries.northeast.latitude,
                lng: boundaries.northeast.longitude,
            },
            {
                lat: boundaries.southeast.latitude,
                lng: boundaries.southeast.longitude,
            },
        ],
        [
            boundaries.northeast.latitude,
            boundaries.northeast.longitude,
            boundaries.southeast.latitude,
            boundaries.southeast.longitude,
        ]
    );

    const southwestToSoutheastPath = useMemo(
        () => [
            {
                lat: boundaries.southwest.latitude,
                lng: boundaries.southwest.longitude,
            },
            {
                lat: boundaries.southeast.latitude,
                lng: boundaries.southeast.longitude,
            },
        ],
        [
            boundaries.southwest.latitude,
            boundaries.southwest.longitude,
            boundaries.southeast.latitude,
            boundaries.southeast.longitude,
        ]
    );

    return (
        <>
            <Polygon
                options={{
                    fillColor: '#FF5C00',
                    fillOpacity: 0.2,
                    strokeColor: null,
                    strokeWeight: 0,
                }}
                path={polygonPath}
            />

            <Polyline path={northwestToNortheastPath} options={polylineOptions} />
            <Polyline path={northwestToSouthwestPath} options={polylineOptions} />
            <Polyline path={northeastToSoutheastPath} options={polylineOptions} />
            <Polyline path={southwestToSoutheastPath} options={polylineOptions} />
        </>
    );
};
