import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { EventModel } from '@/events/models';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { DateModel } from '@/utilities/dates';

type Props = {
    event: EventModel;
};

export const EventTime: FunctionComponent<Props> = ({ event }) => (
    <div className={Styles.EventTime}>
        <Row className={'pb-3 mx-0'}>
            <Col className={'ps-1 pe-4'} xs={'auto'}>
                <img
                    alt="calendar-icon"
                    className={`pe-2 ${Styles.CalendarIcon}`}
                    src={IMAGES.CalendarIcon}
                />
                <span className={Styles.Text}>{`${new DateModel(
                    event.startDateInMilliseconds
                ).format('MMMM DD')} at ${new DateModel(event.startDateInMilliseconds).format(
                    'LT',
                    event.venue.timezone
                )} to ${new DateModel(event.endDateInMilliseconds).format(
                    'LT (z)',
                    event.venue.timezone
                )}`}</span>
            </Col>
        </Row>
    </div>
);
