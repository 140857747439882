import React, { FunctionComponent } from 'react';
import Styles from './Styles.module.scss';

type Props = {
    label: string;
    onClose: () => void;
};

export const TooltipContent: FunctionComponent<Props> = ({ label, onClose }, ref) => {
    return (
        <div className={`${Styles.TooltipContent} d-flex align-items-start px-1 py-2`}>
            <div className={'d-flex flex-column'}>
                {label.split('\n').map((item, index) => (
                    <span
                        key={item}
                        className={`text-start ${
                            index + 1 === label.split('\n').length ? '' : 'pb-2'
                        }`}
                    >
                        {item}
                    </span>
                ))}
            </div>

            <span className={`${Styles.CloseLabel} text-end`} onClick={onClose}>
                Close
            </span>
        </div>
    );
};
