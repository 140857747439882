import React, { FunctionComponent, useCallback } from 'react';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Styles from './Styles.module.scss';
import Placeholder from 'react-bootstrap/Placeholder';

export const EventsListItemLoading: FunctionComponent = () => {
    return (
        <Card className={Styles.EventListItem}>
            <Card.Body>
                <Row className={'pb-2 mx-0'}>
                    <Placeholder animation={'glow'} as={Card.Title}>
                        <Placeholder xs={8} bg="secondary" />
                    </Placeholder>
                </Row>

                <Row className={'pb-2 mx-0'}>
                    <Col className={'px-1'}>
                        <Placeholder animation={'glow'} as={Card.Text}>
                            <Placeholder xs={12} bg="secondary" size="sm" />
                            <Placeholder xs={12} bg="secondary" size="sm" />
                            <Placeholder xs={6} bg="secondary" size="sm" />
                        </Placeholder>
                    </Col>
                </Row>

                <Row className={'pb-2 mx-0'}>
                    <Col className={'px-1'}>
                        <Placeholder animation={'glow'} as={Card.Text}>
                            <Placeholder xs={8} bg="secondary" size="sm" />
                        </Placeholder>
                    </Col>
                </Row>

                <Row className={'pb-2 mx-0'}>
                    <Col className={'px-1'}>
                        <Placeholder animation={'glow'} as={Card.Text}>
                            <Placeholder xs={8} bg="secondary" size="sm" />
                        </Placeholder>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
