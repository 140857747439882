import { useCallback, useEffect, useState } from 'react';

export const useWindowDimensions = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);

    const handleResize = useCallback((event: UIEvent) => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return {
        width,
        height,
    };
};
