import { VenueLocationModel } from '@/venues/models';

export type VenuesState = {
    isLoadingVenueLocations: boolean;
    fetchedVenueLocations: VenueLocationModel[];
};

export const initialVenuesState: VenuesState = {
    isLoadingVenueLocations: true,
    fetchedVenueLocations: [],
};
