function ShieldIcon() {
    const svgString = `
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" transform="translate(0.882812 0.783203)" fill="white" style="mix-blend-mode:multiply"/>
      <path d="M11.3828 13.2257L9.44032 11.2832L8.38282 12.3407L11.3828 15.3407L17.3828 9.3407L16.3253 8.2832L11.3828 13.2257Z" fill="#07648D"/>
      <path d="M12.8828 23.2832L8.25097 20.8135C6.93042 20.111 5.8261 19.0622 5.05653 17.7796C4.28697 16.497 3.88121 15.029 3.88282 13.5332V3.7832C3.88323 3.38551 4.0414 3.00422 4.32262 2.723C4.60383 2.44179 4.98512 2.28362 5.38282 2.2832H20.3828C20.7805 2.28362 21.1618 2.44179 21.443 2.723C21.7242 3.00422 21.8824 3.38551 21.8828 3.7832V13.5332C21.8844 15.029 21.4787 16.497 20.7091 17.7796C19.9395 19.0622 18.8352 20.111 17.5147 20.8135L12.8828 23.2832ZM5.38282 3.7832V13.5332C5.38152 14.7571 5.71353 15.9582 6.34323 17.0076C6.97292 18.057 7.87651 18.9152 8.95702 19.4899L12.8828 21.5832L16.8086 19.4899C17.8891 18.9152 18.7927 18.057 19.4224 17.0076C20.0521 15.9582 20.3841 14.7571 20.3828 13.5332V3.7832H5.38282Z" fill="#07648D"/>
    </svg>
  `;

    return <div dangerouslySetInnerHTML={{ __html: svgString }} />;
}

export default ShieldIcon;
