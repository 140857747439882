import React, { FunctionComponent, useCallback, MouseEvent, ReactNode, useMemo } from 'react';
import BootstrapButton from 'react-bootstrap/esm/Button';
import Styles from './Styles.module.scss';

export enum ButtonType {
    PRIMARY,
    LIGHT,
    PRIMARY_BLUE,
    PRIMARY_BLUE_1,
    PRIMARY_BLUE_OUTLINE,
    LIGHT_BLUE,
    DARK_OUTLINE_BLUE,
    LIGHT_PURPLE,
    LIGHT_GRAY,
    LIGHT_GRAY_BLUE,
    DARK_OUTLINE_BLUE_PILL,
    LINK_BUTTON,
}

const ButtonTypeAndClassNames = {
    [ButtonType.LIGHT]: Styles.ButtonLight,
    [ButtonType.LIGHT_BLUE]: Styles.ButtonLightBlue,
    [ButtonType.DARK_OUTLINE_BLUE]: Styles.ButtonDarkOutlineBlue,
    [ButtonType.PRIMARY]: Styles.ButtonPrimary,
    [ButtonType.PRIMARY_BLUE]: Styles.ButtonPrimaryBlue,
    [ButtonType.PRIMARY_BLUE_1]: Styles.ButtonPrimaryBlue1,
    [ButtonType.PRIMARY_BLUE_OUTLINE]: Styles.ButtonPrimaryBlueOutline,
    [ButtonType.LIGHT_PURPLE]: Styles.ButtonLightPurple,
    [ButtonType.LIGHT_GRAY]: Styles.ButtonLightGray,
    [ButtonType.LIGHT_GRAY_BLUE]: Styles.ButtonLightGrayBlue,
    [ButtonType.DARK_OUTLINE_BLUE_PILL]: Styles.ButtonDarkOutlineBluePill,
    [ButtonType.LINK_BUTTON]: Styles.LinkButton,
};

const ButtonTypeAndVariants = {
    [ButtonType.LIGHT]: 'outline-dark',
    [ButtonType.LIGHT_BLUE]: 'outline-dark',
    [ButtonType.DARK_OUTLINE_BLUE]: 'outline-dark',
    [ButtonType.PRIMARY]: undefined,
    [ButtonType.PRIMARY_BLUE]: undefined,
    [ButtonType.PRIMARY_BLUE_1]: undefined,
    [ButtonType.PRIMARY_BLUE_OUTLINE]: undefined,
    [ButtonType.LIGHT_PURPLE]: 'outline-dark',
    [ButtonType.LIGHT_GRAY]: 'outline-dark',
    [ButtonType.LIGHT_GRAY_BLUE]: 'outline-dark',
    [ButtonType.DARK_OUTLINE_BLUE_PILL]: 'outline-dark',
    [ButtonType.LINK_BUTTON]: undefined,
};

type Props = {
    type: ButtonType;
    onClick: () => void;
    children?: ReactNode | ReactNode[];
    customClassName?: string;
    disabled?: boolean;
};

export const Button: FunctionComponent<Props> = ({
    type,
    onClick,
    children,
    customClassName,
    disabled,
}) => {
    const clickHandler = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            onClick();
        },
        [onClick]
    );

    const className = useMemo(() => {
        return `${ButtonTypeAndClassNames[type]} ${customClassName}`;
    }, [type, customClassName]);

    return (
        <BootstrapButton
            variant={ButtonTypeAndVariants[type]}
            onClick={clickHandler}
            className={className}
            disabled={disabled}
        >
            {children}
        </BootstrapButton>
    );
};
