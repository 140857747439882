import { AuthenticatedUserModel } from '@/authentication/models';
import { AuthenticatedUserApi } from '@/authentication/api/types';

export const authenticatedUserApiToAuthenticatedUser = (
    user: AuthenticatedUserApi
): AuthenticatedUserModel => ({
    firstName: user.first_name,
    lastName: user.last_name,
    id: user.id,
    client: user.client_parameter,
    clientNames: user.client_names,
    zipCode: user.zip,
    email: user.email,
    customEmail: user.custom_email,
    phone: user.phone,
    customPhone: user.custom_phone,
    mailingAddress: {
        addressLine1: user.mailing_address?.street || '',
        city: user.mailing_address?.city || '',
        state: user.mailing_address?.state || '',
        zipcode: user.mailing_address?.zip || '',
        latitude: user.mailing_address?.latitude || 0,
        longitude: user.mailing_address?.longitude || 0,
    },
    otherAddress: {
        addressLine1: user.other_address?.street || '',
        city: user.other_address?.city || '',
        state: user.other_address?.state || '',
        zipcode: user.other_address?.zip || '',
        latitude: user.other_address?.latitude || 0,
        longitude: user.other_address?.longitude || 0,
    },
    canItBeContactedThroughEmail: user.can_it_be_contacted_through_email,
    canItBeContactedThroughPhone: user.can_it_be_contacted_through_phone,
});
