import { httpClient } from '@/services/http-client';
import { ClientApi } from '@/clients/api/types';
import { ClientModel } from '@/clients/models';
import { clientApiToClientModel } from '@/clients/mappers/clients-mapper';

type ClientsResponse = {
    clients: ClientApi[];
};

export const getClients = async (): Promise<ClientModel[]> => {
    const response = await httpClient.requestFull<ClientsResponse>({
        method: 'GET',
        url: '/selfserve/clients',
    });

    return response.data.clients.map(clientApiToClientModel);
};
