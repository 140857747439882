import { useCallback } from 'react';
import { ContactMatchingBody, EventRegistrationBody, MatchedContact } from '@/events/api/types';
import { matchContact, registerForEvent } from '@/events/api';
import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import { AuthenticatedUserModel } from '@/authentication/models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { EventFilters } from '@/events/reducer/types';
import { eventsActions } from '@/events/reducer';

type UseRegistrationOperationsParams = {
    authenticatedUser: AuthenticatedUserModel | undefined;
    clients: string[];
    onError: () => void;
    onSuccess: () => void;
};

export const useRegistrationOperations = ({
    authenticatedUser,
    clients,
    onError,
    onSuccess,
}: UseRegistrationOperationsParams) => {
    const dispatch = useDispatch();

    const numberOfRegisteredEvents = useSelector<RootState, number>(
        (state) => state.events.numberOfRegisteredEvents
    );

    const findMatch = useCallback(
        async (
            eventId: string,
            keepUpdatedEmail: boolean,
            keepUpdatedText: boolean,
            personalInformationSharingAllowed: boolean
        ): Promise<MatchedContact | undefined> => {
            if (!authenticatedUser) {
                return undefined;
            }

            const body: ContactMatchingBody = {
                first_name: authenticatedUser?.firstName,
                last_name: authenticatedUser?.lastName,
                mobile_phone: authenticatedUser?.phone,
                home_phone: authenticatedUser?.phone,
                zip_code: authenticatedUser?.zipCode,
                primary_email: authenticatedUser?.email,
                client_name: clients.join(';'),
                event_id: eventId,
                keep_updated_email: keepUpdatedEmail,
                keep_updated_text: keepUpdatedText,
                personal_information_sharing_allowed: personalInformationSharingAllowed,
            };

            return await matchContact(body);
        },
        [authenticatedUser, clients]
    );

    const register = useCallback(
        async (
            eventId: string,
            keepUpdatedEmail: boolean,
            keepUpdatedText: boolean,
            personalInformationSharingAllowed: boolean,
            unregistering: boolean
        ): Promise<void> => {
            const matchedContact: MatchedContact | undefined = await findMatch(
                eventId,
                keepUpdatedEmail,
                keepUpdatedText,
                personalInformationSharingAllowed
            );

            if (!matchedContact) {
                Analytics.getInstance().fireEvent(
                    AnalyticsEventName.MismatchContact,
                    AnalyticsEventCategory.EventRegistration,
                    `${eventId} | ${authenticatedUser?.firstName} | ${authenticatedUser?.lastName}`,
                    {
                        event_id: eventId,
                        first_name: authenticatedUser?.firstName,
                        last_name: authenticatedUser?.lastName,
                        email: authenticatedUser?.email,
                        zipcode: authenticatedUser?.zipCode,
                        mobile_phone: authenticatedUser?.phone,
                        home_phone: authenticatedUser?.phone,
                    }
                );
                onError();
                return;
            }

            const body: EventRegistrationBody = {
                event_id: eventId,
                contact_ids: [authenticatedUser?.id as string],
                keep_updated_email: keepUpdatedEmail,
                keep_updated_text: keepUpdatedText,
                personal_information_sharing_allowed: personalInformationSharingAllowed,
                client_name: clients.join(';'),
                is_unregistering: unregistering,
            };

            await registerForEvent(body);

            if (unregistering) {
                dispatch(
                    eventsActions.updateNumberOfRegisteredEvents(numberOfRegisteredEvents - 1)
                );
            } else {
                dispatch(
                    eventsActions.updateNumberOfRegisteredEvents(numberOfRegisteredEvents + 1)
                );
            }

            onSuccess();
        },
        [
            findMatch,
            authenticatedUser?.id,
            authenticatedUser?.firstName,
            authenticatedUser?.lastName,
            authenticatedUser?.email,
            authenticatedUser?.zipCode,
            authenticatedUser?.phone,
            clients,
            onSuccess,
            onError,
            dispatch,
            numberOfRegisteredEvents,
        ]
    );

    return {
        register,
    };
};
