import React, { FunctionComponent, useMemo } from 'react';
import { EventModel } from '@/events/models';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Styles from './Styles.module.scss';
import Row from 'react-bootstrap/Row';
import { buildAddressFromEvent } from '@/events/components/map-view/helpers';
import { IMAGES } from '@/assets/images';

type Props = {
    event: EventModel | undefined;
    show: boolean;
    onClose: () => void;
};

const GOOGLE_MAP_URL = 'https://maps.google.com/?q=';
const APPLE_MAP_URL = 'https://maps.apple.com/?q=';

export const MapAppModal: FunctionComponent<Props> = ({ event, show, onClose }) => {
    const address = useMemo(() => (!!event ? buildAddressFromEvent(event) : ''), [event]);

    const googleMapLink = useMemo(() => {
        return `${GOOGLE_MAP_URL}${address}`;
    }, [address]);

    const appleMapLink = useMemo(() => {
        return `${APPLE_MAP_URL}${address}`;
    }, [address]);

    return (
        <Modal centered={true} show={show} onHide={onClose}>
            <Modal.Body className={Styles.OpenMapAppModal}>
                <Row className={'pb-4'}>
                    <Col className={'d-flex justify-content-between align-items-center'}>
                        <span className={Styles.Title}>
                            How to get to <strong>{address}</strong>?
                        </span>

                        <img
                            className={Styles.CloseIcon}
                            alt="close-modal-icon"
                            src={IMAGES.CloseIcon}
                            onClick={onClose}
                        />
                    </Col>
                </Row>

                <Row className={'pb-2'}>
                    <Col className={'d-flex justify-content-start align-items-center'}>
                        <a
                            href={googleMapLink}
                            className={'d-flex flex-row align-items-center py-2 px-3'}
                            onClick={onClose}
                        >
                            <img alt="google-maps-logo" src={IMAGES.GoogleMapsLogo} />
                            <span className={'ps-2'}>Google Maps</span>
                        </a>
                    </Col>
                </Row>

                <Row className={'pb-4'}>
                    <Col className={'d-flex justify-content-start align-items-center'}>
                        <a
                            href={appleMapLink}
                            className={'d-flex flex-row align-items-center py-2 px-3'}
                            onClick={onClose}
                        >
                            <img alt="apple-maps-logo" src={IMAGES.AppleMapsLogo} />
                            <span className={'ps-2'}>Apple Maps</span>
                        </a>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
