import { ClientModel } from '@/clients/models';

export type ClientsState = {
    isLoadingClients: boolean;
    fetchedClients: ClientModel[];
};

export const initialClientsState: ClientsState = {
    isLoadingClients: true,
    fetchedClients: [],
};
