import React, { FunctionComponent, useCallback, useState } from 'react';
import { DropdownOption } from 'src/components/form-controls/dropdown';
import Styles from './Styles.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MobileFilterBadge } from '@/events/components/events-filtering/components/filter-category/mobile-filter-badge';

type Props = {
    title: string;
    optionsSelected: string[];
    options: DropdownOption[];
    setOptionsSelected: (newOptions: DropdownOption[]) => void;
    isSingleSelection?: boolean;
    disabled?: boolean;
};

export const FilterCategory: FunctionComponent<Props> = ({
    title,
    options,
    optionsSelected,
    setOptionsSelected,
    isSingleSelection = false,
    disabled = false,
}) => {
    const clickHandler = useCallback(
        (value: string) => {
            if (disabled) {
                return;
            }

            if (isSingleSelection) {
                const optionFound = options.find((o) => o.value === value);
                setOptionsSelected([optionFound as DropdownOption]);
                return;
            }

            if (value === options[0].value) {
                setOptionsSelected([options[0]]);
                return;
            }

            if (optionsSelected.includes(options[0].value)) {
                const optionFound = options.find((o) => o.value === value);
                setOptionsSelected([optionFound as DropdownOption]);
                return;
            }

            if (optionsSelected.includes(value) && optionsSelected.length > 1) {
                const index = optionsSelected.indexOf(value);

                const newOptionsSelected = [
                    ...optionsSelected.slice(0, index),
                    ...optionsSelected.slice(index + 1),
                ];

                setOptionsSelected(options.filter((o) => newOptionsSelected.includes(o.value)));
            } else if (!optionsSelected.includes(value)) {
                const newOptionsSelected = [...optionsSelected, value];

                setOptionsSelected(options.filter((o) => newOptionsSelected.includes(o.value)));
            }
        },
        [optionsSelected, setOptionsSelected, options, isSingleSelection, disabled]
    );

    return (
        <>
            <Row className={`mx-0 pb-2`}>
                <Col className={`d-flex align-items-center justify-content-between px-0`}>
                    <span className={`${Styles.Subtitle}`}>{title}</span>
                </Col>
            </Row>

            <Row className={`mx-0 row-cols-auto px-0`}>
                {options.map((option) => (
                    <Col key={option.value} className={`ps-0 pe-2 pb-2`}>
                        <MobileFilterBadge
                            label={option.label}
                            value={option.value}
                            isActive={optionsSelected.includes(option.value)}
                            onClick={clickHandler}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};
