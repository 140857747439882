import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { EventModel } from '@/events/models';
import { VenueType } from '@/venues/models';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';

type Props = {
    event: EventModel;
    onClick?: () => void;
};

type VenueIconProps = {
    type: string;
};

const VenueIcon: FunctionComponent<VenueIconProps> = ({ type }) =>
    type === VenueType.IN_PERSON ? (
        <img
            alt="direction-sign-icon"
            className={`pe-2 ${Styles.DirectionSignIcon}`}
            src={IMAGES.DirectionSignIcon}
        />
    ) : (
        <img
            alt="headset-icon"
            className={`pe-2 ${Styles.HeadsetsIcon}`}
            src={IMAGES.HeadsetIcon}
        />
    );

export const EventLocation: FunctionComponent<Props> = ({ event, onClick }) => {
    return (
        <div className={Styles.EventLocation}>
            <Row className={'pb-1 mx-0'}>
                <Col className={'px-1'}>
                    <VenueIcon type={event.venue.type} />

                    <span className={Styles.Text} onClick={onClick}>
                        {event.venue.name}
                    </span>
                </Col>
            </Row>

            {event.venue.type === VenueType.IN_PERSON && (
                <Row className={'pb-1 mx-0'}>
                    <Col className={'px-1'}>
                        <img
                            alt="map-location-icon"
                            className={`pe-2 ${Styles.MapLocationIcon}`}
                            src={IMAGES.MapLocationIcon}
                        />
                        <span
                            className={Styles.Text}
                        >{`${event.venue.city} ${event.venue.state} ${event.venue.zipcode}`}</span>
                    </Col>
                </Row>
            )}
        </div>
    );
};
