import React, { FunctionComponent } from 'react';
import Styles from './Styles.module.scss';

type Props = {
    label?: string;
    color?: string;
    bgColor?: string;
    border?: string;
};

export const Badge: FunctionComponent<Props> = ({ label, color, bgColor, border }) => {
    return (
        <span
            className={`${Styles.Badge} py-0 px-4`}
            style={{ color, backgroundColor: bgColor, border }}
        >
            {label}
        </span>
    );
};
