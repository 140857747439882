import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getClients } from '@/clients/api';
import { initialClientsState } from '@/clients/reducer/state';

export const fetchClients = createAsyncThunk('clients/fetchClients', async () => {
    return await getClients();
});

export const clientsSlice = createSlice({
    name: 'clients',
    initialState: initialClientsState,
    reducers: {
        storeFetchedClients: (state, action) => {
            state.fetchedClients = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchClients.pending, (state) => {
                state.isLoadingClients = true;
            })
            .addCase(fetchClients.fulfilled, (state, action) => {
                state.fetchedClients = action.payload;
                state.isLoadingClients = false;
            })
            .addCase(fetchClients.rejected, (state) => {
                state.isLoadingClients = false;
            });
    },
});

export const clientsReducer = clientsSlice.reducer;
export const clientsActions = clientsSlice.actions;
