import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getVenueLocations } from '@/venues/api';
import { initialVenuesState } from '@/venues/reducer/state';
import { VenueLocationModel } from '@/venues/models';

export const fetchVenueLocations = createAsyncThunk('venues/fetchVenueLocations', async () => {
    return await getVenueLocations();
});

export const venuesSlice = createSlice({
    name: 'venues',
    initialState: initialVenuesState,
    reducers: {
        storeFetchedVenueLocations: (state, action: PayloadAction<VenueLocationModel[]>) => {
            state.fetchedVenueLocations = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVenueLocations.pending, (state) => {
                state.isLoadingVenueLocations = true;
            })
            .addCase(
                fetchVenueLocations.fulfilled,
                (state, action: PayloadAction<VenueLocationModel[]>) => {
                    state.isLoadingVenueLocations = false;
                    state.fetchedVenueLocations = action.payload;
                }
            )
            .addCase(fetchVenueLocations.rejected, (state) => {
                state.isLoadingVenueLocations = false;
            });
    },
});

export const venuesReducer = venuesSlice.reducer;
export const venuesActions = venuesSlice.actions;
