import React, { useCallback, useMemo, useState } from 'react';
import { isUsPhoneValid } from '@/utilities/phone-util';

export interface RegistrationModalData {
    lastName: {
        value: string | undefined;
        setValue: (newValue: string) => void;
        isValid: boolean;
    };
    phoneNumber: {
        value: string | undefined;
        setValue: (newValue: string) => void;
        isValid: boolean;
    };
}

export const useRegistrationModalData = (): RegistrationModalData => {
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);

    const isLastNameValid = useMemo(() => {
        return Boolean(lastName && lastName.length >= 2);
    }, [lastName]);

    const isMobilePhoneValid = useMemo(() => {
        return Boolean(phoneNumber && phoneNumber.length >= 10 && isUsPhoneValid(phoneNumber));
    }, [phoneNumber]);

    const onMobilePhoneChange = useCallback((newMobilePhone: string) => {
        setPhoneNumber(newMobilePhone);
    }, []);

    return {
        lastName: {
            value: lastName,
            setValue: setLastName,
            isValid: isLastNameValid,
        },
        phoneNumber: {
            value: phoneNumber,
            setValue: onMobilePhoneChange,
            isValid: isMobilePhoneValid,
        },
    };
};
