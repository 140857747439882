import React, { FunctionComponent, useCallback, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Styles from './Styles.module.scss';
import { InputBox } from '@/components/form-controls/input';
import { Button, ButtonType } from '@/components/form-controls/button';
import { PhoneInputBox } from '@/components/form-controls/phone-input';
import { TranslationService } from '@/services/translation';
import { useRegistrationModalData } from '@/contacts/components/registration-modal/use-registration-modal-data';
import { useDispatch } from 'react-redux';
import { contactsActions } from '@/contacts/reducer';
import { eventsActions } from '@/events/reducer';
import { RegistrationStatus } from '@/events/reducer/types';

type Props = {};

export const SignInPanel: FunctionComponent<Props> = (props) => {
    const [hasUserMakeChanges, userHasMakeChanges] = useState(false);
    const registrationModalData = useRegistrationModalData();

    const dispatch = useDispatch();

    const onPhoneNumberChange = useCallback(
        (newPhoneNumber: string) => {
            registrationModalData.phoneNumber.setValue(newPhoneNumber);

            if (!hasUserMakeChanges) {
                userHasMakeChanges(!hasUserMakeChanges);
            }
        },
        [registrationModalData.phoneNumber.setValue, hasUserMakeChanges]
    );

    const onLastNameChange = useCallback(
        (newLastName: string) => {
            registrationModalData.lastName.setValue(newLastName);

            if (!hasUserMakeChanges) {
                userHasMakeChanges(!hasUserMakeChanges);
            }
        },
        [registrationModalData.lastName.setValue, hasUserMakeChanges]
    );

    const onSubmitButtonClick = useCallback(() => {
        dispatch(
            contactsActions.setSignInForm({
                phoneNumber: registrationModalData.phoneNumber.value,
                lastName: registrationModalData.lastName.value,
            })
        );

        dispatch(eventsActions.updateRegistrationStatus(RegistrationStatus.AUTHENTICATING));
    }, [registrationModalData.phoneNumber.value, registrationModalData.lastName.value, dispatch]);

    return (
        <Row className={`mx-0 ${Styles.SignInPanel} pb-4`}>
            <Col>
                <div className={`${Styles.MessageBox} py-3 px-4`}>
                    <span>Your events</span>

                    <p className={'mb-0 pt-1'}>
                        {
                            "Connect for Life is a no cost benefit through your health insurance plan. Let's find your account so we can recommend the right events for you."
                        }
                    </p>
                </div>

                <Row className={'mx-0 pt-3'}>
                    <Col xl={5} lg={5} md={5} className={'d-flex align-items-center ps-0 pe-1'}>
                        <PhoneInputBox
                            label={TranslationService.getInstance().getPhrase(
                                'sign_in.step_one.form.phone.label'
                            )}
                            placeholder={TranslationService.getInstance().getPhrase(
                                'sign_in.step_one.form.phone.placeholder'
                            )}
                            value={registrationModalData.phoneNumber.value}
                            onChange={onPhoneNumberChange}
                            isValid={true}
                        />
                    </Col>

                    <Col xl={5} lg={5} md={5} className={'d-flex align-items-center px-1'}>
                        <InputBox
                            label={TranslationService.getInstance().getPhrase(
                                'sign_in.step_one.form.last_name.label'
                            )}
                            type={'text'}
                            placeholder={TranslationService.getInstance().getPhrase(
                                'sign_in.step_one.form.last_name.placeholder'
                            )}
                            value={registrationModalData.lastName.value}
                            onChange={onLastNameChange}
                            isValid={true}
                        />
                    </Col>

                    <Col xl={2} lg={2} md={2} className={'d-flex align-items-end ps-1 pe-0'}>
                        <Button
                            type={ButtonType.PRIMARY_BLUE}
                            onClick={onSubmitButtonClick}
                            customClassName={`w-100 ${Styles.SubmitButton} px-0`}
                            disabled={
                                !registrationModalData.phoneNumber.isValid ||
                                !registrationModalData.lastName.isValid
                            }
                        >
                            {TranslationService.getInstance().getPhrase(
                                'events_list.sign_in_panel.button.label'
                            )}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
