import React, { FunctionComponent, useMemo } from 'react';

import { EventModel } from '@/events/models';
import Styles from './Styles.module.scss';
import { IMAGES } from '@/assets/images';
import { TranslationService } from '@/services/translation';
import { RootState } from '@/store';
import { RegistrationStatus } from '@/events/reducer/types';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AddEventToCalendar } from '@/events/components/add-event-to-calendar';

type Props = {
    event: EventModel;
};

export const EventHost: FunctionComponent<Props> = ({ event }) => {
    const registrationStatus = useSelector<RootState, RegistrationStatus>(
        (state) => state.events.registrationStatus
    );

    const hostName = useMemo(() => {
        if (event.member.firstName) {
            return `${event.member.firstName} ${event.member.lastName}`;
        }
        return `${event.facilitator.firstName} ${event.facilitator.lastName}`;
    }, [event]);

    const hostInitials = useMemo(() => {
        if (event.member.firstName) {
            return `${event.member.firstName[0]}${event.member.lastName[0]}`;
        }
        return `${event.facilitator.firstName[0]}${event.facilitator.lastName[0]}`;
    }, [event]);

    const hostPhotoUrl = useMemo(() => {
        if (event.member.firstName) {
            return event.member.photo;
        }
        return event.facilitator.photo;
    }, [event]);

    if (registrationStatus === RegistrationStatus.AFTER_CONFIRMATION) {
        return (
            <div className={Styles.EventHost}>
                <Row className={'mx-0'}>
                    <Col xl={6} lg={6} className={`px-0`}>
                        <AddEventToCalendar event={event} customWidth={'100%'} />
                    </Col>
                </Row>

                {!event.member.firstName && event.facilitator.phone && (
                    <Row className={'mx-0 pt-3'}>
                        <Col xl={6} lg={6} className={`${Styles.MobileHostInformation} px-0`}>
                            <div className={`d-flex flex-row align-items-center p-3`}>
                                <img
                                    alt="info-circle-transparent-icon"
                                    src={IMAGES.InfoCircleTransparentIcon}
                                />

                                <span className={'ps-2'}>Host information</span>
                            </div>

                            <div className={`d-flex flex-column px-3 pb-3`}>
                                <span className={`${Styles.HostName} pb-2`}>{hostName}</span>

                                <div className={`${Styles.PhoneBox} p-3`}>
                                    <img
                                        className={`${Styles.PhoneIcon} me-2`}
                                        alt="phone-icon"
                                        src={IMAGES.PhoneIcon}
                                    />

                                    <span
                                        className={Styles.Text}
                                    >{`${event.facilitator.phone}`}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }

    return (
        <div className={Styles.EventHost}>
            <h5 className={Styles.HostInfoTitle}>
                {TranslationService.getInstance().getPhrase('registration.sidebar.host.title')}
            </h5>

            <div className={'d-flex'}>
                <div
                    className={`${Styles.HostProfileImg} ${Styles.HostInfoTitle}`}
                    title={hostName}
                >
                    {!hostPhotoUrl ? hostInitials : <img alt={'host-avatar'} src={hostPhotoUrl} />}
                </div>

                <div className={'d-flex flex-column justify-content-center ps-4'}>
                    <span className={Styles.EventDetails}>{hostName}</span>
                    {!event.member.firstName && event.facilitator.phone && (
                        <div className={'d-flex align-items-center pt-2'}>
                            <img
                                className={`${Styles.PhoneIcon} me-2`}
                                alt="phone-icon"
                                src={IMAGES.PhoneIcon}
                            />

                            <span className={Styles.Text}>{`${event.facilitator.phone}`}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
