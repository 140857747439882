import React, { useCallback, useEffect, useMemo, useState } from 'react';
import validator from 'validator';
import { AuthenticatedUserModel } from '@/authentication/models';

interface BasicFormInfo {
    value: string | undefined;
    setValue: (newValue: string) => void;
    isValid: boolean;
    onBlur?: () => void;
}

interface BasicCheckboxInfo {
    value: boolean;
    setValue: (newValue: boolean) => void;
}

export interface RegistrationFormData {
    firstName: BasicFormInfo;
    lastName: BasicFormInfo;
    zipCode: BasicFormInfo;
    mobilePhone: BasicFormInfo;
    homePhone: BasicFormInfo;
    email: BasicFormInfo;
    willContactByEmail: BasicCheckboxInfo;
    willContactBySms: BasicCheckboxInfo;
    doesAcceptPhotos: BasicCheckboxInfo;
    isWholeFormValid: {
        value: boolean;
    };
}

type Props = {
    preSelectedContact?: AuthenticatedUserModel;
};

export const useRegistrationFormData = ({ preSelectedContact }: Props): RegistrationFormData => {
    const [firstName, setFirstName] = useState<string | undefined>(preSelectedContact?.firstName);
    const [lastName, setLastName] = useState<string | undefined>(preSelectedContact?.lastName);
    const [zipCode, setZipCode] = useState<string | undefined>(preSelectedContact?.zipCode);

    const [mobilePhone, setMobilePhone] = useState<string | undefined>(preSelectedContact?.phone);

    const [homePhone, setHomePhone] = useState<string | undefined>(preSelectedContact?.phone);
    const [email, setEmail] = useState<string | undefined>(preSelectedContact?.email);
    const [willContactByEmail, setWillContactByEmail] = useState<boolean>(true);
    const [willContactBySms, setWillContactBySms] = useState<boolean>(true);
    const [doesAcceptPhotos, setDoesAcceptPhotos] = useState<boolean>(true);

    const isFirstNameValid = useMemo(() => {
        return Boolean(firstName && firstName.length >= 2);
    }, [firstName]);

    const isLastNameValid = useMemo(() => {
        return Boolean(lastName && lastName.length >= 2);
    }, [lastName]);

    const isZipCodeValid = useMemo(() => {
        return Boolean(zipCode && zipCode.length >= 5 && validator.isPostalCode(zipCode, 'US'));
    }, [zipCode]);

    const isMobilePhoneValid = useMemo(() => {
        return Boolean(
            mobilePhone && mobilePhone.length >= 10 && validator.isMobilePhone(mobilePhone, 'en-US')
        );
    }, [mobilePhone]);

    const isHomePhoneValid = useMemo(() => {
        return Boolean(
            homePhone && homePhone.length >= 10 && validator.isMobilePhone(homePhone, 'en-US')
        );
    }, [homePhone]);

    const isEmailValid = useMemo(() => {
        if (!email) return true;
        return validator.isEmail(email);
    }, [email]);

    const onMobilePhoneBlur = useCallback(() => {
        if (isMobilePhoneValid) {
            setHomePhone(mobilePhone);
        }
    }, [isMobilePhoneValid, mobilePhone]);

    const onMobilePhoneChange = useCallback((newMobilePhone: string) => {
        let formattedMobilePhone = newMobilePhone;

        if (newMobilePhone.length === 1) {
            if (newMobilePhone.indexOf('(') === -1) {
                formattedMobilePhone = '(' + newMobilePhone;
            }
        } else if (newMobilePhone.length === 4) {
            if (newMobilePhone.indexOf(')') === -1) {
                formattedMobilePhone = newMobilePhone + ') ';
            }
        } else if (newMobilePhone.length === 9) {
            if (newMobilePhone.indexOf('-') === -1) {
                formattedMobilePhone = newMobilePhone + '-';
            }
        }

        setMobilePhone(formattedMobilePhone);
    }, []);

    useEffect(() => {
        setFirstName(preSelectedContact?.firstName);
        setLastName(preSelectedContact?.lastName);
        setMobilePhone(preSelectedContact?.phone);
        setHomePhone(preSelectedContact?.phone);
        setEmail(preSelectedContact?.email);
        setZipCode(preSelectedContact?.zipCode);
    }, [preSelectedContact]);

    return {
        firstName: {
            value: firstName,
            setValue: setFirstName,
            isValid: isFirstNameValid,
        },
        lastName: {
            value: lastName,
            setValue: setLastName,
            isValid: isLastNameValid,
        },
        zipCode: {
            value: zipCode,
            setValue: setZipCode,
            isValid: isZipCodeValid,
        },
        mobilePhone: {
            value: mobilePhone,
            setValue: onMobilePhoneChange,
            isValid: isMobilePhoneValid,
            onBlur: onMobilePhoneBlur,
        },
        homePhone: {
            value: homePhone,
            setValue: setHomePhone,
            isValid: isHomePhoneValid,
        },
        email: {
            value: email,
            setValue: setEmail,
            isValid: isEmailValid,
        },
        willContactByEmail: {
            value: willContactByEmail,
            setValue: setWillContactByEmail,
        },
        willContactBySms: {
            value: willContactBySms,
            setValue: setWillContactBySms,
        },
        doesAcceptPhotos: {
            value: doesAcceptPhotos,
            setValue: setDoesAcceptPhotos,
        },
        isWholeFormValid: {
            value:
                isFirstNameValid &&
                isLastNameValid &&
                isZipCodeValid &&
                isMobilePhoneValid &&
                isHomePhoneValid &&
                isEmailValid,
        },
    };
};
