import React from 'react';

const SiteLogo = () => {
    return (
        <svg
            width="150"
            height="141"
            viewBox="0 0 150 141"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M140.039 73.567C149.967 50.7979 139.183 42.2622 130.111 25.2745C125.496 16.6311 125.221 12.7147 115.219 5.07606C102.718 -4.47228 84.1486 3.42346 78.8169 6.36141C73.4852 9.29936 39.5137 31.732 45.7646 57.439C52.0156 83.1461 78.8169 71.0208 98.1213 82.0136C117.317 92.9447 130.111 96.3362 140.039 73.567Z"
                fill="#FA9D1C"
            />
            <path
                d="M53.3913 134.796C68.0713 127.087 79.8729 135.705 86 110.327C85.511 91.3661 70.3384 90.3014 53.9543 93.4052C37.5702 96.509 33.3379 74.0987 18.088 73.5219C10.4631 73.2335 3.00296 75.585 0.518557 92.9279C-1.96585 110.271 5.01644 124.07 10.0137 129.886C21.9506 143.779 38.7113 142.506 53.3913 134.796Z"
                fill="#FFC658"
            />
            <rect x="9.5" y="4" width="123" height="123" rx="61.5" fill="white" />
            <rect x="9.5" y="4" width="123" height="123" rx="61.5" stroke="#FFE8BC" />
            <circle cx="144" cy="22.5" r="6" fill="#FFDD9B" />
            <circle cx="16.5" cy="6" r="2.5" fill="#FFC658" />
            <circle cx="119.5" cy="125" r="2.5" fill="#FFC658" />
            <g clipPath="url(#clip0)">
                <path
                    d="M44.8745 53.509C43.7641 53.509 40.9469 58.3179 40.31 60.9214C39.4148 64.5752 42.2389 70.514 44.7641 71.2975C47.0156 62.3642 50.858 53.509 52.3008 53.509H44.8745Z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M68.6148 50.5452C65.7418 50.5452 59.687 80.1918 56.7344 80.1918H64.1593C67.1105 80.1918 73.1667 50.5452 76.0383 50.5452H68.6148Z"
                    fill="url(#paint1_linear)"
                />
                <path
                    d="M44.8535 53.509C48.4766 53.509 53.1751 80.1918 56.7339 80.1918H64.1588C61.2858 80.1918 55.2311 53.509 52.2798 53.509H44.8535Z"
                    fill="url(#paint2_linear)"
                />
                <path
                    d="M92.3731 47.58C89.5001 47.58 83.4454 83.157 80.4941 83.157H87.919C90.8703 83.157 96.9264 47.58 99.7981 47.58H92.3731Z"
                    fill="url(#paint3_linear)"
                />
                <path
                    d="M68.6152 50.5452C71.5665 50.5452 77.6226 83.157 80.4942 83.157H87.9191C85.0461 83.157 78.9914 50.5452 76.0401 50.5452H68.6152Z"
                    fill="url(#paint4_linear)"
                />
                <path
                    d="M92.373 47.58C93.5924 47.58 96.5618 58.3431 98.3705 65.3699C104.216 65.3699 103.136 55.2563 102.607 53.509C101.534 49.9698 100.562 47.58 99.7966 47.58H92.373Z"
                    fill="url(#paint5_linear)"
                />
                <path
                    d="M60.3421 72.9498C58.9579 77.1176 57.6869 80.1932 56.7344 80.1932H64.1607C63.1746 80.1932 61.8198 77.0645 60.3421 72.9498Z"
                    fill="#FCB813"
                />
                <path
                    d="M52.279 53.509H44.8555C45.9938 53.509 47.2369 56.1446 48.5204 59.7593C50.0512 56.0399 51.5066 53.509 52.279 53.509Z"
                    fill="#FCB813"
                />
                <path
                    d="M76.0387 50.5452H68.6152C69.5692 50.5452 70.8458 53.949 72.2341 58.5554C73.7076 54.0021 75.0597 50.5452 76.0387 50.5452Z"
                    fill="#FCB813"
                />
                <path
                    d="M84.1242 74.3829C82.7317 79.4264 81.4509 83.157 80.4941 83.157H87.9176C86.94 83.157 85.5921 79.3733 84.1242 74.3829Z"
                    fill="#FCB813"
                />
                <path
                    d="M99.7966 47.58H92.373C93.1482 47.58 94.6287 51.9266 96.0715 56.9003C97.5129 51.5816 98.8342 47.58 99.7966 47.58Z"
                    fill="#FCB813"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="46.2205"
                    y1="71.2981"
                    x2="46.2205"
                    y2="53.5096"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.4" stopColor="#F58B1F" />
                    <stop offset="0.85" stopColor="#F27321" />
                    <stop offset="0.891" stopColor="#F16422" />
                    <stop offset="0.9527" stopColor="#EF5423" />
                    <stop offset="1" stopColor="#EF4E23" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="66.3871"
                    y1="80.1927"
                    x2="66.3871"
                    y2="50.5455"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF4E23" />
                    <stop offset="0.0527" stopColor="#F05723" />
                    <stop offset="0.1384" stopColor="#F26F21" />
                    <stop offset="0.15" stopColor="#F27321" />
                    <stop offset="0.5" stopColor="#F58B1F" />
                    <stop offset="0.85" stopColor="#F27321" />
                    <stop offset="0.8616" stopColor="#F26F21" />
                    <stop offset="0.9473" stopColor="#F05723" />
                    <stop offset="1" stopColor="#EF4E23" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear"
                    x1="54.5069"
                    y1="80.1927"
                    x2="54.5069"
                    y2="53.5096"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.05" stopColor="#F89C1C" />
                    <stop offset="0.225" stopColor="#FCB720" />
                    <stop offset="0.3809" stopColor="#FEC722" />
                    <stop offset="0.5" stopColor="#FFCD23" />
                    <stop offset="0.6191" stopColor="#FEC722" />
                    <stop offset="0.775" stopColor="#FCB720" />
                    <stop offset="0.95" stopColor="#F89C1C" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear"
                    x1="90.1454"
                    y1="83.1568"
                    x2="90.1454"
                    y2="47.5801"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF4E23" />
                    <stop offset="0.15" stopColor="#F27321" />
                    <stop offset="0.5" stopColor="#F58B1F" />
                    <stop offset="0.85" stopColor="#F27321" />
                    <stop offset="1" stopColor="#EF4E23" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear"
                    x1="78.2674"
                    y1="83.1568"
                    x2="78.2674"
                    y2="50.5455"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.05" stopColor="#F89C1C" />
                    <stop offset="0.225" stopColor="#FCB720" />
                    <stop offset="0.3809" stopColor="#FEC722" />
                    <stop offset="0.5" stopColor="#FFCD23" />
                    <stop offset="0.6191" stopColor="#FEC722" />
                    <stop offset="0.775" stopColor="#FCB720" />
                    <stop offset="0.95" stopColor="#F89C1C" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear"
                    x1="97.7086"
                    y1="65.3699"
                    x2="97.7086"
                    y2="47.5801"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFCD23" />
                    <stop offset="0.2383" stopColor="#FEC722" />
                    <stop offset="0.5499" stopColor="#FCB720" />
                    <stop offset="0.9" stopColor="#F89C1C" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="64" height="64" fill="white" transform="translate(39.5 33.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SiteLogo;
