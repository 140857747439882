import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';

export const formSubmitted = (contactId: string) => {
    Analytics.getInstance().fireEvent(
        AnalyticsEventName.SignInFormSubmitted,
        AnalyticsEventCategory.SignIn,
        'User has submitted Eligibility Check form information',
        {
            contactId,
        }
    );
};

export const formSubmittedAfterUnmatched = (contactId: string | undefined) => {
    Analytics.getInstance().fireEvent(
        AnalyticsEventName.UnmatchedSignInFormSubmit,
        AnalyticsEventCategory.SignIn,
        'User has submitted Eligibility Check form information after it unmatched',
        {
            contactId,
        }
    );
};
