import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { EventModel } from '@/events/models';
import { VenueType } from '@/venues/models';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useQueryParams } from '@/utilities/use-query-params';
import { Link, LinkType } from '@/components/form-controls/link';
import { Button, ButtonType } from '@/components/form-controls/button';
import { DateModel } from '@/utilities/dates';
import { IMAGES } from '@/assets/images';
import { MapView } from '@/events/components/map-view';
import { AuthenticatedUserModel } from '@/authentication/models';
import { AddEventToCalendar } from '@/events/components/add-event-to-calendar';
import { TranslationService } from '@/services/translation';
import CloseIcon from '@/assets/icons/CloseIcon';
import ArrowLeftIcon from '@/assets/icons/ArrowLeftIcon';
import InfoIcon from '@/assets/icons/InfoIcon';
import { EventsList } from '@/events/components/events-list';
import Styles from './Styles.module.scss';

type Props = {
    event: EventModel;
    authenticatedUser: AuthenticatedUserModel | undefined;
    showBackAllEvents: () => void;
    registrationComplete?: boolean;
    clients: string[];
    events: EventModel[];
    selectedEventIds: string[];
    isLoading: boolean;
    onViewDetails: (event: EventModel) => void;
};

// tslint:disable-next-line:no-empty
const doNothing = (event: EventModel) => {};

export const EventRegistrationCompleteModal: FunctionComponent<Props> = ({
    event,
    authenticatedUser,
    showBackAllEvents,
    registrationComplete,
    clients,
    events,
    isLoading,
    onViewDetails,
}) => {
    const { getQueryParameter } = useQueryParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [activeDotIndex, setActiveDotIndex] = useState<number>(0);
    const [showDetailsCon, setShowDetailsCon] = useState<boolean>(true);
    const [showWhatsNext, setShowWhatsNext] = useState<boolean>(true);
    const [showKeepinTouch, setshowKeepinTouch] = useState<boolean>(true);

    const [mobileScreen, setMobileScreen] = useState(false);

    const [selectedEventIds, selectEventsIds] = useState<string[]>([]);

    const selectedEventsHandler = useCallback(
        (newSelectedEvents: EventModel[]) => {
            selectEventsIds(newSelectedEvents.map((item) => item.id));
        },
        [selectEventsIds]
    );

    const handleDotClick = (index: number) => {
        setActiveDotIndex(index);

        setShowDetailsCon(index === 0);
        setShowWhatsNext(index === 1);
        setshowKeepinTouch(index === 2);
    };

    useEffect(() => {
        function handleResize() {
            setMobileScreen(window.innerWidth < 568);

            setShowDetailsCon(window.innerWidth < 568 ? true : true);
            setShowWhatsNext(window.innerWidth < 568 ? false : true);
            setshowKeepinTouch(window.innerWidth < 568 ? false : true);
            setActiveDotIndex(0);
        }
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Modal
            dialogClassName={Styles.RegistraionCompltModalDialog}
            contentClassName={Styles.RegistraionCompltModalContent}
            centered={true}
            show={registrationComplete}
        >
            <Modal.Header className={`${Styles.RegistraionCompltModalHeader}`}>
                {mobileScreen && !showWhatsNext ? (
                    <div className="d-flex ailgn-items-center justify-content-start gap-4 w-100">
                        <span onClick={showBackAllEvents}>
                            <ArrowLeftIcon />
                        </span>
                        <h2 className="text-center w-75">
                            {TranslationService.getInstance().getPhrase(
                                'registration.after_registration.title'
                            )}
                        </h2>
                    </div>
                ) : (
                    <>
                        <div>
                            {!mobileScreen ? (
                                <h2>
                                    {TranslationService.getInstance().getPhrase(
                                        'registration.after_registration.title'
                                    )}
                                </h2>
                            ) : (
                                <div className="d-flex align-items-center gap-3">
                                    {showWhatsNext ? (
                                        <div className={`${Styles.ItemNumb}`}>
                                            <span>1</span>
                                        </div>
                                    ) : showKeepinTouch ? (
                                        <div className={`${Styles.ItemNumb}`}>
                                            <span>2</span>
                                        </div>
                                    ) : null}
                                    <h2>
                                        {showWhatsNext
                                            ? TranslationService.getInstance().getPhrase(
                                                  'registration.after_registration.what_is_next.step_1.title'
                                              )
                                            : showKeepinTouch
                                              ? TranslationService.getInstance().getPhrase(
                                                    'registration.after_registration.what_is_next.step_2.title'
                                                )
                                              : null}
                                    </h2>
                                </div>
                            )}
                        </div>
                        <span onClick={showBackAllEvents} className="cursor-pointer">
                            <CloseIcon />
                        </span>
                    </>
                )}
            </Modal.Header>
            <Modal.Body className={`p-0 ${Styles.RegistraionCompltModalBody}`}>
                <Row
                    className={`align-items-stretch flex-column flex-md-row position-relative m-0 ${Styles.RegistrationCompleteCon}`}
                >
                    {showDetailsCon ? (
                        <Col
                            xs={12}
                            className={`p-0 d-flex flex-column justify-content-center ${Styles.EvenDetailsCon}`}
                        >
                            {event.venue.type === VenueType.IN_PERSON ? (
                                <div className={`w-100 ${Styles.EventMapContainer}`}>
                                    <MapView
                                        events={[event]}
                                        authenticatedUser={authenticatedUser}
                                        onViewDetails={doNothing}
                                        onSelectedEvents={selectedEventsHandler}
                                        customHeight={'200px'}
                                        loadingEvents={false}
                                        recordBoundaries={false}
                                    />
                                </div>
                            ) : null}

                            <div className={`p-0 ${Styles.EventDescCon}`}>
                                <span className={`d-block ${Styles.EventTitle}`}>{event.name}</span>
                                <div
                                    className={`d-flex align-items-center mb-2 ${Styles.EventInfoRow}`}
                                >
                                    {event.venue.type === VenueType.IN_PERSON ? (
                                        <img
                                            alt="direction-sign-icon"
                                            className={`${Styles.DirectionSignIcon}`}
                                            src={IMAGES.DirectionSignIcon}
                                        />
                                    ) : (
                                        <img
                                            alt="headset-icon"
                                            className={`${Styles.Icon}`}
                                            src={IMAGES.HeadsetIcon}
                                        />
                                    )}
                                    <span className={`${Styles.TextDarkBlue}`}>
                                        {event.venue.name}
                                    </span>
                                </div>
                                {event.venue.type === VenueType.IN_PERSON ? (
                                    <div
                                        className={`d-flex align-items-center mb-2 ${Styles.EventInfoRow}`}
                                    >
                                        <img
                                            alt="map-location-icon"
                                            className={`${Styles.Icon}`}
                                            src={IMAGES.MapLocationIcon}
                                        />
                                        <span
                                            className={`${Styles.TextDarkBlue}`}
                                        >{`${event.venue.city} ${event.venue.state} ${event.venue.zipcode}`}</span>
                                    </div>
                                ) : null}
                                <div
                                    className={`d-flex align-items-center  ${Styles.EventInfoRow}`}
                                >
                                    <img
                                        alt="calendar-icon"
                                        className={`${Styles.CalendarIcon}`}
                                        src={IMAGES.CalendarIcon}
                                    />
                                    <span className={Styles.Details}>{`${new DateModel(
                                        event.startDateInMilliseconds
                                    ).format('MMMM DD')}`}</span>
                                    <span>at</span>
                                    <span className={Styles.EventDateAndTime}>
                                        {`${new DateModel(event.startDateInMilliseconds).format(
                                            'LT (z)'
                                        )}`}
                                    </span>
                                </div>
                            </div>
                            <div className={`d-flex align-items-center mt-3 mt-md-4`}>
                                <AddEventToCalendar event={event} />
                                <Button
                                    type={ButtonType.LINK_BUTTON}
                                    customClassName={`px-4 py-2 d-flex align-items-center justify-content-center gap-2`}
                                    disabled={loading}
                                    onClick={() => {}}
                                >
                                    {TranslationService.getInstance().getPhrase(
                                        'registration.after_registration.buttons.contact_host'
                                    )}
                                    <InfoIcon />
                                </Button>
                            </div>
                            <div className="d-flex flex-column flex-md-row gap-2 mt-3 mt-md-4 align-items-start align-items-md-center justify-content-between">
                                <div>
                                    <div
                                        className={`d-flex gap-2 align-items-center mt-2 ${Styles.EventInfoRow}`}
                                    >
                                        <img
                                            alt="phone-icon"
                                            className={`${Styles.Icon}`}
                                            src={IMAGES.PhoneIcon}
                                        />
                                        <span>651-488-8888</span>
                                    </div>
                                    <div
                                        className={`d-flex gap-2 align-items-center mt-2 ${Styles.EventInfoRow}`}
                                    >
                                        <img
                                            alt="phone-icon"
                                            className={`${Styles.Icon}`}
                                            src={IMAGES.EmailIcon}
                                        />
                                        <span>
                                            <a
                                                href="mailto:facilitatorsname@widercircle.com"
                                                className="nav-link text-dark"
                                            >
                                                facilitatorsname@widercircle.com
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex gap-2 align-items-center">
                                    <span>
                                        <img
                                            alt="copy-icon"
                                            className={`${Styles.Icon}`}
                                            src={IMAGES.CopyIcon}
                                        />
                                    </span>
                                    <span className="text-main-primary">
                                        Copy contact information
                                    </span>
                                </div>
                            </div>
                        </Col>
                    ) : null}
                    {showWhatsNext || showKeepinTouch ? (
                        <>
                            <div className={`d-none d-md-block ${Styles.Divider}`} />
                            <Col
                                xs={12}
                                className={`p-0 d-flex flex-column justify-content-center ${Styles.DetailsSectionRight}`}
                            >
                                {showWhatsNext ? (
                                    <>
                                        <div className={`${Styles.ItemOne}`}>
                                            <div className={`${Styles.ItemTitleRow}`}>
                                                <div
                                                    className={`d-none d-md-flex${Styles.ItemNumb}`}
                                                >
                                                    <span>1</span>
                                                </div>
                                                <div>
                                                    <h2 className="d-none d-md-block">
                                                        {TranslationService.getInstance().getPhrase(
                                                            'registration.after_registration.what_is_next.step_1.title'
                                                        )}
                                                    </h2>
                                                    <p>
                                                        {TranslationService.getInstance().getPhrase(
                                                            'registration.after_registration.what_is_next.step_1.paragraph'
                                                        )}
                                                    </p>
                                                    <div className="d-flex gap-3 align-items-start mt-4">
                                                        <input
                                                            type="checkbox"
                                                            className={`${Styles.FormCheckBox}`}
                                                        />
                                                        <div>
                                                            <span className="d-block">
                                                                Please keep me updated via email (No
                                                                email.
                                                                <a
                                                                    href="#"
                                                                    className="text-main-primary"
                                                                >
                                                                    Add email here
                                                                </a>
                                                                )
                                                            </span>
                                                            <span className="d-block mt-2 mb-2 fw-medium">
                                                                Email for reminders
                                                            </span>
                                                            <span className="d-block">
                                                                <input
                                                                    type="email"
                                                                    placeholder="abc@email.com"
                                                                    className={`${Styles.RegCompltModalFormControl}`}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex gap-3 align-items-start mt-4">
                                                        <input
                                                            type="checkbox"
                                                            className={`${Styles.FormCheckBox}`}
                                                        />
                                                        <div>
                                                            <span className="d-block">
                                                                Please keep me updated via text
                                                                message to 651-488-8888 (
                                                                <a
                                                                    href="#"
                                                                    className="text-main-primary"
                                                                >
                                                                    Edit
                                                                </a>
                                                                )
                                                            </span>
                                                            <span className="d-block mt-2 mb-2 fw-medium">
                                                                The number we text
                                                            </span>
                                                            <span className="d-block">
                                                                <input
                                                                    type="tel"
                                                                    placeholder="000 000 0000"
                                                                    className={`${Styles.RegCompltModalFormControl}`}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`justify-content-end align-items-end ${Styles.ButtonRow}`}
                                                    >
                                                        <Button
                                                            type={ButtonType.PRIMARY_BLUE}
                                                            onClick={() => handleDotClick(1)}
                                                            customClassName={`w-auto ${Styles.UpdateButton}`}
                                                            disabled={true}
                                                        >
                                                            <div className={`text-center`}>
                                                                {TranslationService.getInstance().getPhrase(
                                                                    'registration.after_registration.what_is_next.step_2.update_contact'
                                                                )}
                                                            </div>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${Styles.DividerFull}`} />
                                        <div className={`${Styles.ItemTwo}`}>
                                            <div className={`${Styles.ItemTitleRow}`}>
                                                <div
                                                    className={`d-none d-md-flex ${Styles.ItemNumb}`}
                                                >
                                                    <span>2</span>
                                                </div>
                                                <div>
                                                    <div className="d-flex gap-3 align-items-center mb-4">
                                                        <div
                                                            className={`d-flex d-md-none ${Styles.ItemNumb}`}
                                                        >
                                                            <span>2</span>
                                                        </div>
                                                        <h2>
                                                            {mobileScreen
                                                                ? TranslationService.getInstance().getPhrase(
                                                                      'registration.after_registration.what_is_next.step_app.title'
                                                                  )
                                                                : TranslationService.getInstance().getPhrase(
                                                                      'registration.after_registration.what_is_next.step_2.title'
                                                                  )}
                                                        </h2>
                                                    </div>
                                                    <p className="text-base">
                                                        {mobileScreen
                                                            ? TranslationService.getInstance().getPhrase(
                                                                  'registration.after_registration.what_is_next.step_2.paragraph'
                                                              )
                                                            : TranslationService.getInstance().getPhrase(
                                                                  'registration.after_registration.what_is_next.step_app.paragraph'
                                                              )}
                                                    </p>
                                                    <div
                                                        className={`justify-content-between ${Styles.ButtonRow}`}
                                                    >
                                                        <div className={Styles.LogoCon}>
                                                            <img
                                                                src={IMAGES.LogoSquare}
                                                                className={Styles.MemberAppLogo}
                                                                alt="Wider Circle"
                                                            />
                                                            {!mobileScreen ? (
                                                                <h4>
                                                                    Connect For Life
                                                                    <br />
                                                                    App
                                                                </h4>
                                                            ) : (
                                                                <h4>Connect For Life App</h4>
                                                            )}
                                                        </div>
                                                        <div className="d-flex flex-wrap flex-md-nowrap align-items-center gap-2">
                                                            <Link
                                                                type={LinkType.PRIMARY_BLUE_OUTLINE}
                                                                customClassName={
                                                                    Styles.DownloadButton
                                                                }
                                                                href={
                                                                    'https://www.widercircle.com/wp-content/uploads/2022/12/WC-member-app-flier-12_05_22-FINAL.pdf'
                                                                }
                                                                target={'_blank'}
                                                            >
                                                                <div className={`text-center`}>
                                                                    {TranslationService.getInstance().getPhrase(
                                                                        'registration.after_registration.what_is_next.step_2.download_button'
                                                                    )}
                                                                </div>
                                                            </Link>
                                                            <Link
                                                                type={LinkType.LIGHT_BLUE}
                                                                customClassName={
                                                                    Styles.DownloadButton
                                                                }
                                                                href={
                                                                    'https://www.widercircle.com/memberapp/'
                                                                }
                                                                target={'_blank'}
                                                            >
                                                                <img
                                                                    src={IMAGES.DownloadIcon}
                                                                    className={Styles.BtnIcon}
                                                                    alt="Download"
                                                                />
                                                                Download App
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className={`${Styles.PromotionalVideo}`}>
                                                        <iframe
                                                            width="100%"
                                                            height="450"
                                                            src="https://www.youtube.com/embed/aWujK4CCT3w"
                                                            title="YouTube video player"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            allowFullScreen={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </Col>
                        </>
                    ) : null}
                </Row>
                {mobileScreen && showDetailsCon ? (
                    <Row className="m-0 mt-4 mb-5 pb-4">
                        <Col xs={12} className="p-0 mt-2">
                            <h2 className="mb-3 text-dark">Series of events</h2>
                            <EventsList
                                authenticatedUser={authenticatedUser}
                                clients={clients}
                                selectedEventIds={selectedEventIds}
                                isLoading={isLoading}
                                onViewDetails={onViewDetails}
                                registeredEventsOnly={false}
                            />
                        </Col>
                    </Row>
                ) : null}
                <div
                    className={`m-0 d-flex align-items-center justify-content-end gap-0 gap-md-2 ${Styles.ModalFooterButtons}`}
                >
                    {mobileScreen ? (
                        <Row className="m-0 w-100">
                            <Col xs={6} className="ps-0 pe-1">
                                {!showWhatsNext ? (
                                    <Button
                                        type={ButtonType.LIGHT_BLUE}
                                        onClick={showBackAllEvents}
                                        customClassName={`px-2 px-md-4 py-2`}
                                        disabled={loading}
                                    >
                                        {TranslationService.getInstance().getPhrase(
                                            'events_list.buttons.back_to_events'
                                        )}
                                    </Button>
                                ) : (
                                    <Button
                                        type={ButtonType.LIGHT_BLUE}
                                        onClick={() => handleDotClick(0)}
                                        customClassName={`px-2 px-md-4 py-2`}
                                        disabled={loading}
                                    >
                                        {TranslationService.getInstance().getPhrase(
                                            'events_list.buttons.back'
                                        )}
                                    </Button>
                                )}
                            </Col>

                            <Col
                                xs={6}
                                className={`pe-0 ps-1 d-flex align-items-center ${Styles.EventBtns}`}
                            >
                                {showDetailsCon ? (
                                    <Button
                                        type={ButtonType.PRIMARY_BLUE}
                                        onClick={() => handleDotClick(1)}
                                        customClassName={`px-2 px-md-4 py-2`}
                                        disabled={loading}
                                    >
                                        What's Next
                                    </Button>
                                ) : null}
                                {showWhatsNext ? (
                                    <>
                                        <Button
                                            type={ButtonType.PRIMARY_BLUE}
                                            onClick={() => handleDotClick(2)}
                                            customClassName={`px-2 px-md-4 py-2`}
                                            disabled={loading}
                                        >
                                            {TranslationService.getInstance().getPhrase(
                                                'registration.buttons.go_my_events'
                                            )}
                                        </Button>
                                    </>
                                ) : null}
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Button
                                type={ButtonType.PRIMARY_BLUE_OUTLINE}
                                onClick={() => {}}
                                customClassName={`px-2 px-md-4 py-2`}
                                disabled={loading}
                            >
                                {TranslationService.getInstance().getPhrase(
                                    'events_list.buttons.view_my_events'
                                )}
                            </Button>
                            <Button
                                type={ButtonType.PRIMARY_BLUE}
                                onClick={showBackAllEvents}
                                customClassName={`px-2 px-md-4 py-2`}
                                disabled={loading}
                            >
                                {TranslationService.getInstance().getPhrase(
                                    'events_list.buttons.back_to_events'
                                )}
                            </Button>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};
