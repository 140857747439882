import React, { FunctionComponent, useCallback } from 'react';
import Styles from '../filters-modal/Styles.module.scss';

type Props = {
    label: string;
    value: string;
    isActive: boolean;
    onClick: (value: string) => void;
};

export const MobileFilterBadge: FunctionComponent<Props> = ({
    label,
    value,
    isActive,
    onClick,
}) => {
    const clickHandler = useCallback(() => {
        onClick(value);
    }, [value, onClick]);

    return (
        <div
            className={`${Styles.MobileFilterBadge} ${
                isActive && Styles.Active
            } py-2 px-4 d-flex justify-content-center align-items-center`}
            onClick={clickHandler}
        >
            <span>{label}</span>
        </div>
    );
};
