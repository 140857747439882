import Geocode from 'react-geocode';
import { Boundaries, Coordinates, NativeGeolocationResponse } from '@/services/geolocation/types';

export class Geolocation {
    private static _instance: Geolocation;
    private _apiKey: string;

    public constructor() {
        this._apiKey = process.env.REACT_APP_MAP_API_KEY || '';
        Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY || '');
        Geocode.setLanguage('en');
        Geocode.setRegion('es');
    }

    public async getCoordinates(address: string): Promise<Coordinates> {
        Geocode.setLocationType('ROOFTOP');

        const response: NativeGeolocationResponse = (await Geocode.fromAddress(
            address
        )) as NativeGeolocationResponse;

        return {
            latitude: response.results[0].geometry.location.lat,
            longitude: response.results[0].geometry.location.lng,
        };
    }

    public async getCoordinatesFromZipcode(zipcode: string): Promise<Boundaries> {
        Geocode.setLocationType('GEOMETRIC_CENTER');

        const response: NativeGeolocationResponse = (await Geocode.fromAddress(
            zipcode
        )) as NativeGeolocationResponse;

        const { long_name } = response.results[0].address_components[2];

        const {
            location,
            bounds: { northeast, southwest },
        } = response.results[0].geometry;

        return {
            northwest: {
                latitude: northeast.lat,
                longitude: southwest.lng,
            },
            northeast: {
                latitude: northeast.lat,
                longitude: northeast.lng,
            },
            southwest: {
                latitude: southwest.lat,
                longitude: southwest.lng,
            },
            southeast: {
                latitude: southwest.lat,
                longitude: northeast.lng,
            },
            center: {
                latitude: location.lat,
                longitude: location.lng,
            },
            state: long_name,
        };
    }

    public getMapUrl(): string {
        return `https://maps.googleapis.com/maps/api/js?key=${this._apiKey}&v=3.exp&libraries=geometry,drawing,places`;
    }

    public getApiKey(): string {
        return this._apiKey;
    }

    public static getInstance(): Geolocation {
        if (this._instance === undefined) {
            this._instance = new Geolocation();
        }

        return this._instance;
    }
}
