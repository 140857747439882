import UsZipCodes from '@/constants/us-zipcodes.json';

export type ZipCodeInformation = {
    latitude: number;
    longitude: number;
    city: string;
    state: string;
};

type RawZipCodeInformation = {
    zip_code: number;
    latitude: number;
    longitude: number;
    city: string;
    state: string;
};

export const searchZipCode = (zipCode: string): ZipCodeInformation | undefined => {
    const zipCodeFound = (UsZipCodes as RawZipCodeInformation[]).find(
        (item) => String(item.zip_code) === zipCode
    );

    return !!zipCodeFound
        ? {
              latitude: zipCodeFound.latitude,
              longitude: zipCodeFound.longitude,
              city: zipCodeFound.city,
              state: zipCodeFound.state,
          }
        : undefined;
};
