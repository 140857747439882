import { Analytics } from '@/services/analytics';
import { AnalyticsEventCategory, AnalyticsEventName } from '@/services/analytics/types';
import { EventModel } from '@/events/models';

export const onDrag = () => {
    Analytics.getInstance().fireEvent(
        AnalyticsEventName.MapInteracted,
        AnalyticsEventCategory.MapInteraction,
        `Map was dragged`,
        {}
    );
};

export const onZoomed = (
    mapInstance: google.maps.Map | undefined,
    callback: (newZoom: number | undefined) => void
) => {
    if (mapInstance) {
        callback(mapInstance.getZoom());
    }

    Analytics.getInstance().fireEvent(
        AnalyticsEventName.MapInteracted,
        AnalyticsEventCategory.MapInteraction,
        `User zoomed in/out the map`,
        {}
    );
};

export const eventMarkerClicked = (events: EventModel[]) => {
    Analytics.getInstance().fireEvent(
        AnalyticsEventName.EventMarkerClicked,
        AnalyticsEventCategory.MapInteraction,
        'User clicked on event marker',
        {
            eventIds: events.map((event) => event.id),
        }
    );
};

export const buildAddressFromEvent = (event: EventModel) => {
    const {
        venue: { addressLine1, city, state, zipcode },
    } = event;

    if (addressLine1 && city && state && zipcode) {
        return `${addressLine1} ${city}, ${state} ${zipcode}`;
    }

    if (city && state && zipcode) {
        return `${city}, ${state} ${zipcode}`;
    }

    if (zipcode) {
        return `${zipcode}`;
    }

    return '';
};
