import { useCallback, useEffect, useState } from 'react';

const DEFAULT_DIMENTIONS = {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
};

export const useContainerDimensions = (myRef: any) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, x: 0, y: 0 });

    const getDimensions = useCallback(() => {
        if (!myRef?.current) {
            return DEFAULT_DIMENTIONS;
        }

        return {
            width: myRef.current.offsetWidth,
            height: myRef.current.offsetHeight,
            x: myRef.current.getBoundingClientRect().x,
            y: myRef.current.getBoundingClientRect().y,
        };
    }, [myRef]);

    const handleResize = useCallback(
        () => setDimensions(getDimensions()),
        [getDimensions, setDimensions]
    );

    useEffect(() => {
        if (myRef.current) {
            handleResize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [myRef, getDimensions, handleResize]);

    return dimensions;
};
