import React, { FunctionComponent, useMemo } from 'react';
import { Button, ButtonType } from '@/components/form-controls/button';
import Styles from '@/events/components/events-filtering/Styles.module.scss';
import { TranslationService } from '@/services/translation';
import { IMAGES } from '@/assets/images';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { DEFAULT_OPTION, EventFilters } from '@/events/reducer/types';

type Props = {
    onFilterButtonClick: () => void;
};

export const FiltersButton: FunctionComponent<Props> = ({ onFilterButtonClick }) => {
    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const numberOfFiltersApplied = useMemo(() => {
        const stateFilters = filters.states.includes(DEFAULT_OPTION.value)
            ? 0
            : filters.states.length;

        const languageFilters = filters.languages.includes(DEFAULT_OPTION.value)
            ? 0
            : filters.languages.length;

        const venueTypeFilters = filters.venueTypes.includes(DEFAULT_OPTION.value)
            ? 0
            : filters.venueTypes.length;

        const clientFilter = filters.client === DEFAULT_OPTION.value ? 0 : 1;

        return stateFilters + languageFilters + venueTypeFilters + clientFilter;
    }, [filters.states, filters.languages, filters.venueTypes, filters.client]);

    return (
        <Button
            type={ButtonType.LIGHT_GRAY}
            customClassName={`${Styles.ResetButton} py-2 px-4 w-100`}
            onClick={onFilterButtonClick}
        >
            <div className={'d-flex justify-content-center align-items-center'}>
                <span>{TranslationService.getInstance().getPhrase('filters.modal.title')}</span>
                {numberOfFiltersApplied > 0 && `(${numberOfFiltersApplied})`}
                <img alt={'CloseCircleIcon'} src={IMAGES.FilterIcon} className={`ms-2`} />
            </div>
        </Button>
    );
};
