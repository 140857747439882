import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import Styles from './Styles.module.scss';

type Props = {
    label: string;
    placeholder?: string;
    type: string;
    value: string | undefined;
    onChange: (value: string) => void;
    isValid: boolean;
    onBlur?: () => void;
};

export const InputBox: FunctionComponent<Props> = ({
    label,
    placeholder,
    type,
    value,
    onChange,
    isValid,
    onBlur,
}) => {
    const changeHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { value: newValue },
            } = event;

            onChange(newValue);
        },
        [onChange]
    );

    return (
        <div className={`form-control-box w-100`}>
            <label htmlFor="first_name" className={`${!isValid && Styles.InvalidLabel}`}>
                {label}
            </label>

            <div className={`${Styles.InputContainer}`}>
                <input
                    type={type}
                    className={`w-100  ${!isValid && Styles.InputInvalid}`}
                    placeholder={placeholder}
                    onChange={changeHandler}
                    onBlur={onBlur}
                    value={value}
                />
            </div>
        </div>
    );
};
