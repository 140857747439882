import { DropdownOption } from 'src/components/form-controls/dropdown';
import { EventModel } from '@/events/models';

export const DEFAULT_OPTION: DropdownOption = {
    value: 'ALL',
    label: 'All',
};

export enum ViewMode {
    MAP,
    EVENTS_LIST,
}

export type EventFilters = {
    previousPage: number;
    page: number;
    pageSize: number;
    eventIds: string[];
    searchText?: string;
    client?: string;
    states: string[];
    venueTypes: string[];
    languages: string[];
    zipcode?: string;
    topic?: string[];
    onlyRegisteredEvents: boolean;
    campaigns: string[];
    latitude: number;
    longitude: number;
    mileRadius?: number;
};

export enum RegistrationStatus {
    VIEWING_EVENTS,
    VIEWING_DETAILS,
    AUTHENTICATING,
    CONFIRMING,
    AFTER_CONFIRMATION,
    WHAT_IS_NEXT,
}

export type EventsGroup = {
    events: EventModel[];
    eventIds: string[];
    fromDate: string;
    toDate: string;
};

export type FilteringEventIdsByType = {
    virtualEvents: EventModel[];
    inPersonEvents: EventModel[];
};
