import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { DEFAULT_OPTION, EventFilters } from '@/events/reducer/types';
import { ClientModel } from '@/clients/models';
import { EventModel } from '@/events/models';
import { EVENT_TYPES } from '@/constants/event-types';
import { EVENT_LANGUAGES } from '@/constants/languages';
import { eventsActions } from '@/events/reducer';
import { QueryParameters } from '@/authentication/reducer/state';

type FilteringOptions = {
    clients: ClientModel[];
    filters: EventFilters;
};

export const useEventsForMapUpdater = () => {
    const dispatch = useDispatch();

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const fetchedClients = useSelector<RootState, ClientModel[]>(
        (state) => state.clients.fetchedClients
    );

    const fetchedEventsForMap = useSelector<RootState, EventModel[]>(
        (state) => state.events.fetchedEventsForMap
    );

    const queryParameters = useSelector<RootState, QueryParameters | undefined>(
        (state) => state.authentication.queryParameters
    );

    const filterEventsByClient = useCallback(
        (events: EventModel[], options: FilteringOptions): EventModel[] => {
            let clientSelected: ClientModel | undefined;

            if (options.filters.client) {
                clientSelected = options.clients.find(
                    (item) => item.parameter === options.filters.client
                );
            }

            if (clientSelected) {
                const clientNamesOnEvents =
                    clientSelected?.nameOnEvents.map((name) => name.toLowerCase()) || [];

                return events.filter(
                    (event: EventModel) =>
                        event.clients.find((item) =>
                            clientNamesOnEvents.includes(item.toLowerCase())
                        ) !== undefined
                );
            }

            return events;
        },
        []
    );

    const filterEventsByStates = useCallback(
        (events: EventModel[], options: FilteringOptions): EventModel[] => {
            if (
                options.filters.states.length > 0 &&
                !options.filters.states.includes(DEFAULT_OPTION.value)
            ) {
                return events.filter((event: EventModel) =>
                    options.filters.states.includes(event.venue.state)
                );
            }
            return events;
        },
        []
    );

    const filterEventsByVenueTypes = useCallback(
        (events: EventModel[], options: FilteringOptions): EventModel[] => {
            if (
                options.filters.venueTypes.length > 0 &&
                !options.filters.venueTypes.includes(DEFAULT_OPTION.value)
            ) {
                const apiVenueTypes = options.filters.venueTypes.map((item) => {
                    const venueTypeFound = EVENT_TYPES.find(
                        (eventType) => eventType.value === item.toLowerCase()
                    );

                    return venueTypeFound?.apiValue.toLowerCase();
                });

                return events.filter((event: EventModel) =>
                    apiVenueTypes.includes(event.venue.type)
                );
            }

            return events;
        },
        []
    );

    const filterEventsByLanguages = useCallback(
        (events: EventModel[], options: FilteringOptions): EventModel[] => {
            if (
                options.filters.languages.length > 0 &&
                !options.filters.languages.includes(DEFAULT_OPTION.value)
            ) {
                const languagesFromApi = options.filters.languages.map((language) => {
                    const eventLanguageFound = EVENT_LANGUAGES.find(
                        (eventLanguage) =>
                            eventLanguage.value.toLowerCase() === language.toLowerCase()
                    );

                    return eventLanguageFound?.apiValue.toLowerCase();
                });

                return events.filter((event: EventModel) =>
                    languagesFromApi.includes(event.language?.toLowerCase())
                );
            }

            return events;
        },
        []
    );

    const filterEventsByCampaigns = useCallback(
        (events: EventModel[], options: FilteringOptions): EventModel[] => {
            if (options.filters.campaigns.length > 0) {
                const campaignsLowerCased = options.filters.campaigns.map((campaign) =>
                    campaign.toLowerCase()
                );

                return events.filter((event: EventModel) => {
                    if (event.campaigns.length === 0) {
                        return false;
                    }

                    const campaignsMatches = event.campaigns.filter((campaign) =>
                        campaignsLowerCased.includes(campaign.toLowerCase())
                    );

                    return campaignsMatches.length > 0;
                });
            }

            return events;
        },
        []
    );

    const filterEventsBySearchText = useCallback(
        (events: EventModel[], options: FilteringOptions): EventModel[] => {
            if (options.filters.searchText && options.filters.searchText.length > 2) {
                const findText: string = options.filters.searchText as string;

                return events.filter((event: EventModel) => {
                    if (event.name.toLowerCase().includes(findText.toLowerCase())) {
                        return true;
                    }

                    if (
                        event.member &&
                        event.member.firstName &&
                        event.member.firstName !== '' &&
                        `${event.member.firstName} ${event.member.lastName}`
                            .toLowerCase()
                            .includes(findText.toLowerCase())
                    ) {
                        return true;
                    }

                    return `${event.facilitator.firstName} ${event.facilitator.lastName}`
                        .toLowerCase()
                        .includes(findText.toLowerCase());
                });
            }

            return events;
        },
        []
    );

    const filterEventsForMap = useCallback(
        (
            events: EventModel[],
            clients: ClientModel[],
            filters: EventFilters,
            eventId: string | undefined
        ): EventModel[] => {
            const options: FilteringOptions = {
                clients,
                filters,
            };

            let filteredEvents: EventModel[] = filterEventsByClient(events, options);
            filteredEvents = filterEventsByStates(filteredEvents, options);
            filteredEvents = filterEventsByVenueTypes(filteredEvents, options);
            filteredEvents = filterEventsByLanguages(filteredEvents, options);
            filteredEvents = filterEventsByCampaigns(filteredEvents, options);
            filteredEvents = filterEventsBySearchText(filteredEvents, options);

            if (eventId) {
                const foundEvent = events.find((e) => e.id === eventId);
                const alreadyAddedEvent = filteredEvents.find((e) => e.id === eventId);

                if (!alreadyAddedEvent && foundEvent) {
                    filteredEvents.push(foundEvent);
                }
            }

            return filteredEvents;
        },
        [
            filterEventsByCampaigns,
            filterEventsByClient,
            filterEventsByLanguages,
            filterEventsBySearchText,
            filterEventsByStates,
            filterEventsByVenueTypes,
        ]
    );

    const getNumberOfRegisteredEvents = useCallback(
        (events: EventModel[]) =>
            events
                .filter((event) => event.isAuthenticatedUserRegistered)
                .reduce((acc) => acc + 1, 0),
        []
    );

    useEffect(() => {
        const newEvents = filterEventsForMap(
            fetchedEventsForMap,
            fetchedClients,
            filters,
            queryParameters?.eventId
        );
        dispatch(eventsActions.storeFilteredEventsForMap(newEvents));

        const numberOfRegisteredEvents = getNumberOfRegisteredEvents(newEvents);
        dispatch(eventsActions.updateNumberOfRegisteredEvents(numberOfRegisteredEvents));
    }, [
        fetchedEventsForMap,
        filters.states,
        filters.venueTypes,
        filters.languages,
        filters.searchText,
        filters.client,
        filters.campaigns,
        fetchedClients,
        filterEventsForMap,
        queryParameters?.eventId,
    ]);
};
