import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import { RootState } from '@/store';
import { EventModel, EventSubtype } from '@/events/models';
import Styles from './Styles.module.scss';
import { TranslationService } from '@/services/translation';
import { EventsListLargeItem, EventsListSmallItem } from '@/events/components/events-list-items';
import { Indicators } from './indicators';
import { EventFilters } from '@/events/reducer/types';

import './overrides.scss';

type Props = {
    isMapHidden: boolean;
    onViewDetails: (event: EventModel) => void;
    selectedEventIds: string[];
    onShowMapAppModal: (event: EventModel) => void;
};

export const NationwideEventsList: FunctionComponent<Props> = ({
    isMapHidden,
    onViewDetails,
    selectedEventIds,
    onShowMapAppModal,
}) => {
    const [eventIndex, setEventIndex] = useState(0);

    const events = useSelector<RootState, EventModel[]>((state) => state.events.fetchedEvents);

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const nationwideEvents = useMemo(
        () =>
            events.filter((event) =>
                [EventSubtype.TOWN_HALL, EventSubtype.AMBASSADOR].includes(event.subtype)
            ),
        [events]
    );

    const countdown = useMemo(() => {
        if (nationwideEvents.length === 0) {
            return '';
        }

        const suffix =
            nationwideEvents.length > 1
                ? TranslationService.getInstance().getPhrase(
                      'events_list.results.options.show_available_events.countdown_suffix_plural'
                  )
                : TranslationService.getInstance().getPhrase(
                      'events_list.results.options.show_available_events.countdown_suffix_singular'
                  );

        return `${eventIndex + 1}/${nationwideEvents.length} ${suffix}`;
    }, [nationwideEvents, eventIndex]);

    const onSelectEventIndex = useCallback(
        (selectedEventIndex: number) => setEventIndex(selectedEventIndex),
        []
    );

    const onSelectedEvent = useCallback(
        (event: EventModel) => {
            const newIndex = nationwideEvents.findIndex((e) => e.id === event.id);
            setEventIndex(newIndex);
        },
        [nationwideEvents]
    );

    const goToPreviousEvent = useCallback(() => {
        if (nationwideEvents.length === 0 || eventIndex === 0) {
            return;
        }

        setEventIndex(eventIndex - 1);
    }, [nationwideEvents, eventIndex]);

    const goToNextEvent = useCallback(() => {
        if (nationwideEvents.length === 0 || eventIndex === nationwideEvents.length - 1) {
            return;
        }

        setEventIndex(eventIndex + 1);
    }, [nationwideEvents, eventIndex]);

    if (nationwideEvents.length === 0 || filters.onlyRegisteredEvents) {
        return null;
    }

    return (
        <>
            <Row className={'mx-0 pb-3'}>
                <Col className={'px-3 d-flex justify-content-between align-items-center'}>
                    <span className={`${Styles.Title}`}>
                        {TranslationService.getInstance().getPhrase(
                            'events_list.results.options.show_available_events.title'
                        )}
                    </span>

                    <span className={`${Styles.Countdown}`}>{countdown}</span>
                </Col>
            </Row>

            <Row className={`${Styles.NationwideEventsListCarousel} mx-0 pb-4 mb-3`}>
                <Col className={'px-3'}>
                    <Carousel
                        activeIndex={eventIndex}
                        onSelect={onSelectEventIndex}
                        indicators={false}
                        controls={false}
                    >
                        {nationwideEvents.map((event) => (
                            <Carousel.Item key={event.id}>
                                {isMapHidden ? (
                                    <EventsListLargeItem
                                        event={event}
                                        onDisplayDetails={onViewDetails}
                                        isSelected={selectedEventIds.includes(event.id)}
                                        onShowMapAppModal={onShowMapAppModal}
                                    />
                                ) : (
                                    <EventsListSmallItem
                                        event={event}
                                        onDisplayDetails={onViewDetails}
                                        isSelected={selectedEventIds.includes(event.id)}
                                        onShowMapAppModal={onShowMapAppModal}
                                    />
                                )}
                            </Carousel.Item>
                        ))}
                    </Carousel>

                    <Indicators
                        events={nationwideEvents}
                        onSelectedEvent={onSelectedEvent}
                        selectedEvent={nationwideEvents[eventIndex]}
                        onPreviousEvent={goToPreviousEvent}
                        onNextEvent={goToNextEvent}
                    />
                </Col>
            </Row>
        </>
    );
};
