import React, { FunctionComponent, useMemo } from 'react';
import { EventModel } from '@/events/models';
import Row from 'react-bootstrap/Row';
import Styles from './Styles.module.scss';
import Col from 'react-bootstrap/Col';
import { TranslationService } from '@/services/translation';
import userAvatar from '@/assets/images/icons/User-avatar.svg';
import { EventHost } from '@/events/components/event-host';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { RegistrationStatus } from '@/events/reducer/types';
import { PartnerInfo } from '@/events/components/event-details-miscellaneous/partner-info';

type Props = {
    event: EventModel;
};

export const EventDetailsMiscellaneous: FunctionComponent<Props> = ({ event }) => {
    const registrationStatus = useSelector<RootState, RegistrationStatus>(
        (state) => state.events.registrationStatus
    );

    const attendanceMessage = useMemo(
        () =>
            event?.numberOfAttendees < 10
                ? TranslationService.getInstance().getPhrase(
                      'registration.sidebar.attendance.default'
                  )
                : `${event?.numberOfAttendees} ${TranslationService.getInstance().getPhrase(
                      'registration.sidebar.attendance.subtitle'
                  )}`,
        [event?.numberOfAttendees]
    );

    if ([RegistrationStatus.AFTER_CONFIRMATION].includes(registrationStatus)) {
        return (
            <div className={Styles.EventDetailsMiscellaneous}>
                <EventHost event={event} />
            </div>
        );
    }

    return (
        <Row className={`m-0 ${Styles.EventDetailsMiscellaneous}`}>
            <PartnerInfo client={event.clients.length === 1 ? event.clients[0] : ''} />

            <Col
                xs={6}
                lg={4}
                className={`px-1 d-flex flex-column justify-space-between d-md-block`}
            >
                <h5>
                    {TranslationService.getInstance().getPhrase(
                        'registration.sidebar.attendance.title'
                    )}
                </h5>

                <div className={`${Styles.AttendeesCon} d-flex align-items-center`}>
                    <span className={`${Styles.userAvatar} me-2`}>
                        <img src={userAvatar} className="w-100" alt="Event Host" />
                    </span>
                    <span className={`${Styles.Attendees} pt-1`}>{attendanceMessage}</span>
                </div>
            </Col>

            <Col xs={12} lg={4} className={`mt-4 mt-lg-0 py-0 px-1 ${Styles.HostInfoCon}`}>
                <EventHost event={event} />
            </Col>
        </Row>
    );
};
