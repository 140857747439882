import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { EventFilters } from '@/events/reducer/types';
import { useCallback, useEffect } from 'react';
import { EventModel, EventSubtype } from '@/events/models';
import { eventsActions } from '@/events/reducer';
import { Boundaries } from '@/services/geolocation/types';
import { VenueType } from '@/venues/models';
import { getStatesWithinBoundaries } from '@/utilities/boundaries';

export const useEventsForListUpdater = () => {
    const dispatch = useDispatch();

    const filters = useSelector<RootState, EventFilters>((state) => state.events.filters);

    const isLoadingEvents = useSelector<RootState, boolean>(
        (state) => state.events.isLoadingEvents
    );

    const mapBoundaries = useSelector<RootState, Boundaries | undefined>(
        (state) => state.events.mapBoundaries
    );

    const filteredEventsForMap = useSelector<RootState, EventModel[]>(
        (state) => state.events.filteredEventsForMap
    );

    const getEventsBetweenBoundaries = useCallback(
        (events: EventModel[], boundaries: Boundaries) => {
            const northEastCoordinates = boundaries.northeast;
            const southWestCoordinates = boundaries.southwest;

            const maxLat = northEastCoordinates.latitude;
            const minLat = southWestCoordinates.latitude;

            const maxLng = northEastCoordinates.longitude;
            const minLng = southWestCoordinates.longitude;

            if (!maxLat || !minLat || !maxLng || !minLng) {
                return [];
            }

            return events.filter((event) => {
                if (
                    event.subtype === EventSubtype.AMBASSADOR ||
                    event.subtype === EventSubtype.TOWN_HALL
                ) {
                    return true;
                }

                if (!event.venue.longitude || !event.venue.latitude) {
                    return false;
                }

                return (
                    event.venue.latitude >= minLat &&
                    event.venue.latitude <= maxLat &&
                    event.venue.longitude >= minLng &&
                    event.venue.longitude <= maxLng
                );
            });
        },
        []
    );

    const getVirtualEvents = useCallback(
        (events: EventModel[]) => events.filter((event) => event.venue.type === VenueType.VIRTUAL),
        []
    );

    const getInPersonEvents = useCallback(
        (events: EventModel[]) =>
            events.filter((event) => event.venue.type === VenueType.IN_PERSON),
        []
    );

    const isNationwide = useCallback(
        (event: EventModel) =>
            event.subtype === EventSubtype.AMBASSADOR || event.subtype === EventSubtype.TOWN_HALL,
        []
    );

    const handleWhenShowingRegisteredEvents = useCallback((events: EventModel[]) => {
        const eventsRegistered = events.filter((event) => event.isAuthenticatedUserRegistered);

        dispatch(
            eventsActions.updateEventIdsFilter({
                virtualEvents: getVirtualEvents(eventsRegistered),
                inPersonEvents: getInPersonEvents(eventsRegistered),
            })
        );
    }, []);

    useEffect(() => {
        if (isLoadingEvents) {
            return;
        }

        if (filters.onlyRegisteredEvents) {
            handleWhenShowingRegisteredEvents(filteredEventsForMap);
            return;
        }

        let virtualEvents = getVirtualEvents(filteredEventsForMap);

        if (filters.states.find((item) => item === 'ALL') && mapBoundaries) {
            const statesFromBoundaries = getStatesWithinBoundaries(mapBoundaries);

            virtualEvents = virtualEvents.filter(
                (event) =>
                    isNationwide(event) ||
                    (event.venue.state &&
                        statesFromBoundaries.includes(event.venue.state.toLowerCase()))
            );
        } else if (!filters.states.find((item) => item === 'ALL')) {
            virtualEvents = virtualEvents.filter(
                (event) =>
                    isNationwide(event) ||
                    (event.venue.state && filters.states.includes(event.venue.state.toUpperCase()))
            );
        }

        let inPersonEvents = getInPersonEvents(filteredEventsForMap);

        if (mapBoundaries) {
            inPersonEvents = getEventsBetweenBoundaries(inPersonEvents, mapBoundaries);
        }

        dispatch(
            eventsActions.updateEventIdsFilter({
                virtualEvents,
                inPersonEvents,
            })
        );
        dispatch(
            eventsActions.updateNumberOfAvailableEvents(
                virtualEvents.length + inPersonEvents.length
            )
        );
    }, [
        filters.onlyRegisteredEvents,
        filters.states,
        filteredEventsForMap,
        mapBoundaries,
        isLoadingEvents,
    ]);
};
